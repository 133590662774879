// src/BankTransferForm.js
import React from "react";
import { Form, Input, Button, Row, Col } from "antd";

const BankTransfer = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="accountHolder"
            label="Account Holder"
            rules={[
              {
                required: true,
                message: "Please enter the account holder name",
              },
            ]}
          >
            <Input placeholder="Account Holder" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                required: true,
                message: "Please enter the account number",
              },
            ]}
          >
            <Input placeholder="Account Number" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="bankName"
        label="Bank Name"
        rules={[
          {
            required: true,
            message: "Please enter the bank name",
          },
        ]}
      >
        <Input placeholder="Bank Name" />
      </Form.Item>
      <Form.Item
        name="amount"
        label="Transfer Amount"
        rules={[
          {
            required: true,
            message: "Please enter the transfer amount",
          },
        ]}
      >
        <Input type="number" placeholder="Transfer Amount" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BankTransfer;
