import React, { Fragment } from "react";
import PastPriceBanner from "../Components/PastPrices/PastPriceBanner";
import { Helmet } from "react-helmet";

function PastPrices() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Past Fund Prices | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="customer can easily Acces the information for past prices from our website"
        />
        <meta
          name="keywords"
          content="Past prices, NAV,Salaam Life & Savings,fund prices"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <PastPriceBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default PastPrices;
