import React from "react";
import { Fragment } from "react";

import Fade from "react-reveal/Fade";

function DownloadPDF() {
  return (
    <Fragment>
      <div id="coc" className="row whoweare codeofconduct">
        <div className="col-sm-12">
          <Fade right delay={400}>
            <h2>Download</h2>
          </Fade>
        </div>

        <div className="container mypdfss">
          <div className="row">
            <Fade delay={500} left>
              <div className="col-sm-4 pdfs" key="1">
                <a
                  href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/complainForm.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    // src={Anti}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/complainBox.jpg`}
                    alt="pdf"
                  />
                  {/* <h3> Anti-Bribery & Corruption</h3> */}
                </a>{" "}
              </div>
            </Fade>



          </div>
        </div>


      </div>
    </Fragment>
  );
}

export default DownloadPDF;