import React from "react";
import UserSidebar from "../Dashboard/Header/UserSidebar";
import UserCanvas from "../Dashboard/Header/UserCanvas";
import BeneficeryForm from "../Dashboard/Beneficery/BeneficeryForm";

const Beneficery = () => {
  return (
    <div className="container-fluid dashbord">
      <div className="row ">
        <div className="col-sm-2 sideSection">
          <UserSidebar />
        </div>
        <div className="col-sm-1 sideSectionCanvas">
          <>
            {["start"].map((placement, idx) => (
              <UserCanvas key={idx} placement={placement} name={"Menu"} />
            ))}
          </>
        </div>

        <div className="col-sm-10 container dashboardSection">
          <BeneficeryForm />
        </div>
      </div>
    </div>
  );
};

export default Beneficery;
