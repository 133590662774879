import React, { Fragment } from "react";

const EditHeader = () => {
  return (
    <Fragment>
      <div className="row profile claim">
        <div className="col-sm-6 ">
          <h2>Edit Information</h2>
        </div>
        <div className="col-sm-6 verifyBtn">
          <button className="activeBtn">Active</button>
        </div>
      </div>
    </Fragment>
  );
};

export default EditHeader;
