import React from "react";

const MoneyLoundering = () => {
  return (
    <div className="modalForm container">
      <div className="col-sm-12">
        <h4>Anti-Money-Laundering (AML) </h4>
      </div>
      <div className="col-sm-12">
        <p>
          It is hereby confirmed that I have not been subjected to any money
          laundering or terrorist financing-related proceedings, investigations,
          sanctions, punitive actions indictment, fines, conviction, or civil
          enforcement action imposed on me by a law enforcement body during the
          last five years.
        </p>
      </div>
    </div>
  );
};

export default MoneyLoundering;
