import React, { Fragment } from "react";
import ProductBanner from "../Components/ProductsSavings/ProductBanner";
import { Helmet } from "react-helmet";

function ProductsSavings() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Salaam Individual Life Takaful Plan | Salaam Life and Savings |
          Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="Elevate your financial future with our cutting-edge Individual Life Takaful Plan, offering unparalleled protection and peace of mind. Our Shari'ah-compliant solution provides exceptional coverage,"
        />
        <meta
          name="keywords"
          content="Individual life product,Salaam Life & Savings,Individual Takaful"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <ProductBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default ProductsSavings;
