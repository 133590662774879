import React from "react";
import { Link } from "react-router-dom";


const ClaimTab = () => {
  return (
    <div className="row container dashboardTabs">
      <div className="col-sm-4">
        <div className="box">
          <Link to="/dashboard/basic-info">
            {" "}
            <p>Living Benefits</p>
          </Link>
        </div>
      </div>
      <div className="col-sm-4">
        <Link to="/dashboard/claim-tabs">
          <div className="box">
            <p>Death Benefits</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ClaimTab;
