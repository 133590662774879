import React from "react";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { Flip } from "react-reveal";
import NomineeHeader from "./NomineeInfoHeader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { occupationOptions } from "../../Data/Data";

const NomineInfoForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Given Information Verified Successfully"
  );

  const { Option } = Select;
  const handleFinish = (values) => {
    console.log(values);
    // navigate("/");
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div className="container">
      <NomineeHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
          <Flip top delay={300}>
            <div className="col-sm-12">
              <Form.Item>
                <Input disabled defaultValue="Membership #" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="col-sm-12">
              <Form.Item>
                <Input disabled defaultValue="Name of Participant" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item>
                <Input disabled defaultValue="CNIC #" maxLength={15} />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item>
                <Input disabled defaultValue={"Gender"} />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1100}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item>
                <Input disabled defaultValue="Date of Birth" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item>
                <Input defaultValue="Sum Covered" disabled />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1500}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: " Current Amount is required" },
                ]}
                name="Current-Amount"
              >
                <Input placeholder="Current Amount" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1700}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                name="occupation"
                rules={[
                  { required: true, message: "Please select an occupation" },
                ]}
              >
                <Select showSearch defaultValue="Occupation">
                  {occupationOptions.map((occupation) => (
                    <Option key={occupation} value={occupation}>
                      {occupation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1900}>
            <div
              className="col-sm-12
        "
            >
              <Form.Item
                rules={[{ required: true, message: "Employer is required" }]}
                name="Employer"
              >
                <Input placeholder="Employer" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2100}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item name="last-Date">
                <DatePicker placeholder="Last Date Attended the WorkPlace" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2300}>
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Employer Address is required" },
                ]}
                name="employ"
              >
                <Input placeholder="Emplyer Address" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2500}>
            <div className="col-sm-12 text">
              {" "}
              <p>
                Name(s) of the other legal heirs of the deceased with
                relationship and age
              </p>
            </div>
          </Flip>

          <Flip top delay={2700}>
            <div className="col-sm-3">
              <Form.Item name="Name">
                <Input placeholder="Name" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2900}>
            <div className="col-sm-2">
              <Form.Item name="Age">
                <Input placeholder="Age" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={3100}>
            <div className="col-sm-3">
              <Form.Item name="cnic">
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2500}>
            <div className="col-sm-4">
              <Form.Item name="relation">
                <Input placeholder="Relation" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={600}>
            <div className="col-sm-12 addBtn">
              <button onClick={showModal} className="dashboardBn dashboardBn1">
                Add Another
              </button>
            </div>
          </Flip>

          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button onClick={showModal} className="dashboardBn">
                Next
              </button>
            </div>
          </Flip>
        </div>
      </Form>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default NomineInfoForm;
