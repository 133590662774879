import React, { useState } from "react";
import { Fragment } from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import Fade from "react-reveal/Fade";

function Contactform() {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  /* eslint-disable no-template-curly-in-string */
  const Contact = `https://asset.salaamtakaful.com/GeneralAssets/Assets/img/contactimg.png`;

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values) => {
    setSubmitting(true);
    setTimeout(() => {
      console.log(values); // You can handle the form submission here

      // Display success message
      message.success("Form submitted successfully.");

      // Clear form values
      form.resetFields();

      // Show the success message at the bottom
      setIsSuccessMessageVisible(true);

      setSubmitting(false);
    }, 1000); // Simulating an asynchronous operation with setTimeout
  };

  return (
    <Fragment>
      <div className="sub complainforms container">
        <div className="row">
          <div className="col-sm-6">
            <Fade up delay={300}>
              {" "}
              {/* <img src={Contact} alt="phone" /> */}
            </Fade>
          </div>

          <div className="col-sm-6 formstyle contactFormstyle ">
            <h3>Let’s Talk</h3>
            <Form
              form={form}
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Fade down delay={500}>
                {" "}
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              <Fade down delay={900}>
                <Form.Item name="phone" rules={[{ type: "number", min: 0 }]}>
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item name="complain">
                  <Input.TextArea
                    placeholder="Complaint"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>

              <Fade down delay={1300}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit
                  </Button>
                </Form.Item>
              </Fade>
            </Form>

            {/* Display success message at the bottom */}
            {isSuccessMessageVisible && (
              <Fade down delay={300}>
                {" "}
                <div style={{ marginTop: "10px" }}>
                  <p className="message">Form submitted successfully.</p>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Contactform;
