import React, { Fragment } from "react";
import PolicyPage from "../Components/Policy/PolicyContent";
import PolicyBanner from "../Components/Policy/PolicyBanner";
import PolicyAccordion from "../Components/Policy/PolicyAccordion";
import { useEffect } from "react";

function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <PolicyBanner />
      <div className="container policyPage">
        <div className="row">
          <PolicyPage />
          <PolicyAccordion />
        </div>
      </div>
    </Fragment>
  );
}

export default Policy;
