import React, { useState } from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";

function ContactMap() {
  const Map = `${process.env.REACT_APP_URL_ASSETS}Assets/img/map.png`

  return (
    <Fragment>
      <div className="sub complainforms">
        <div className="row">
          <div className="col-sm-12">
            <Fade up delay={300}>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7057642.057073738!2d63.7079444670803!3d30.262324755098565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38db52d2f8fd751f%3A0x46b7a1f7e614925c!2sPakistan!5e0!3m2!1sen!2s!4v1692633023279!5m2!1sen!2s"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
              <img src="https://asset.salaamlifetakaful.com/SavingAssets/Assets/img/Map.jpg" alt="Map Image" />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactMap;
