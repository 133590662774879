import React from "react";
import { Fade } from "react-reveal";

const StrategyTable = () => {
  const Array = [1, 2, 3];
  return (
    <div className="TableSection TableSection1 unitPrice">
      <Fade top delay={300}>
        <h2>New Strategy</h2>
      </Fade>
      <table>
        <thead>
          <tr>
            <th className="fst">Type of Funds</th>

            <th>%</th>

            <th>No.of Units</th>

            <th>Unit Price</th>
            <th className="lst">Current Value</th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <tr>
              <td className="fst">Fund Name A</td>
              <td>50</td>
              <td>50</td>
              <td>1024</td>

              <td className="lst">Lapsed</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StrategyTable;
