import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { groupprods } from "../../Data/Data";


function UniqueProducts() {
  return (
    <Fragment>
      <div className="sub productetxt uniqueprods">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-12 writeus">
              <h2>STANDOUT FEATURES</h2>
            </div>
          </Fade>
        </div>

        <div className="row prodbox grooups">
          {groupprods.map((item) => {
            return (
              <div
                className="col-sm-4"
                key={item.key}
                data-aos={item.anim}
                data-aos-duration="4000"
              >
                <h3>{item.mainheading}</h3>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Fade up delay={500}>
        <div className="row claimprocedure">
          <div className="col-sm-12">
            <Fade left delay={700}>
              <h2 className="addistionslas"> Additional Features</h2>
            </Fade>

            <div className="row mynewboxes">
              <div className="col-sm-6">
                {/* <h3>Secure Your Workforce</h3> */}
                <h4>AN OVERVIEW OF OUR GROUP FAMILY TAKAFUL PLAN</h4>
                <p>In the event of an individual's death by any cause, Takaful Death Benefit will
                  be provided. Complimentary terminal illness coverage is also a great feature
                  of this product.</p>
              </div>
              <div className="col-sm-6">
                {/* <h3>Secure Your Workforce</h3> */}
                <h4>BOOST YOUR EMPLOYEE BENEFITS</h4>
                <p>Supplementary coverage in takaful acts as an additional shield for your takaful plan, offering extra protection for unexpected events such as accidental death or injury, or any natural disability, ensuring that you and your loved ones remain financially secure in any circumstance.</p>
              </div>
            </div>
            <div className="row addsa myadongroup">

              <div className="col-sm-3">
                <h4>Takaful Accidental Death</h4>
                <p>Additional coverage for accidental death.</p>
              </div>

              <div className="col-sm-3">
                <h4>Takaful Accidental Disability</h4>
                <p>Coverage for accidental disability </p>
              </div>

              <div className="col-sm-3">
                <h4>Takaful Natural Disability</h4>
                <p>Coverage for natural disability</p>
              </div>

              <div className="col-sm-3">
                <h4>Takaful Accidental Medical Expenses</h4>
                <p>Coverage for medical expenses due to accidents.</p>
              </div>

              <div className="col-sm-3">
                <h4>Takaful Critical Illness</h4>
                <p>Coverage for specified critical illnesses</p>
              </div>
            </div>



          </div>
        </div>
      </Fade>
    </Fragment>
  );
}

export default UniqueProducts;
