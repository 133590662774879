import React, { Fragment } from 'react'
import CalculateForm from '../Components/Calculatee/CalculateForm';

function Calculate() {
    return (
        <Fragment>
            <div className="container-fluid faderight">
                <div className="row">
                    <CalculateForm />
                </div>
            </div>
        </Fragment>
    );
}

export default Calculate