import React from "react";
import Profile from "../Main/Profile";

import MemberTable from "./MemberTable";

const Membership = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl">
        <div className="col-sm-12 ">
          <MemberTable />
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Funds Performance Report</button>
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Unit Report</button>
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Fund Switch</button>
        </div>
      </div>
    </div>
  );
};

export default Membership;
