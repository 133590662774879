import React, { Fragment, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Form, Input, Button } from "antd";
import Flip from "react-reveal/Flip";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmitClick = () => {
    setIsSubmitted(true);
    setIsLoading(true);

    setIsLoading(false);
    setIsSubmitted(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="">{/* <Header /> */}</div>
      <Fragment>
        <Fade left>
          <div className="container tabss motor buynow">
            <div className="row">
              <Fade left>
                <div className="col-sm-6">
                  <img
                    // src={Buynowimage}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/buynow.png`}
                    alt="buynowimage"
                  />
                </div>
              </Fade>
              <div className="col-sm-6 homepagesec buynowpage">
                <div className=" Buynowform loginForm">
                  <Fade right>
                    <div className="">
                      <h4 className="oswald">Login</h4>
                    </div>
                    <div className="innerbuyNow next">
                      <Form
                        layout="vertical"
                        onFinish={handleSubmitClick}
                        form={form}
                      >
                        <div className="form">
                          <Flip top delay={500}>
                            <Form.Item name="Email">
                              <Input placeholder="Email" type="email" />
                            </Form.Item>
                          </Flip>

                          <Flip top delay={1000}>
                            <Input.Password placeholder="input password" />
                          </Flip>
                        </div>

                        <h6>
                          Don`t Have An Account?{" "}
                          <Link to="/buynow"> Click here </Link>
                        </h6>
                        <h6>
                          <Link to="/forgetpassword">Forget Password? </Link>
                        </h6>
                        <div className="footerbottom">
                          <Flip top delay={2500}>
                            <div className="formSubmit loginButton">
                              <Button
                                htmlType="submit"
                                className="buttons"
                                loading={isLoading}
                              >
                                Login
                              </Button>
                            </div>
                          </Flip>
                        </div>
                      </Form>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        {isSubmitted && (
          <div
            className="submit-message"
            style={{ position: "fixed", bottom: 20, left: 20 }}
          >
            <p>Form submitted successfully!</p>
          </div>
        )}
      </Fragment>
    </>
  );
};

export default Login;
