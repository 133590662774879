import React, { Fragment, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";

export default function UserNav({ change, setChange }) {
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1,
    );
    const element = document.getElementById(href);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);

  return (
    <Fragment>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <button className="blueBtn"> +92-21-111-875-111</button>
              <button className="greenBtn"> Username</button>
              <h6>URDU</h6>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
}
