import React, { Fragment } from "react";
import Govbanner from "../Components/Governance/Govbanner";

function Governance() {
  return (
    <Fragment>
      <div className="container-fluid faderight ">
        <div className="row">
          <Govbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Governance;
