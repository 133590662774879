import { React, Fragment } from "react";
import Callbackform from "../Components/Callback/Callbackform";

function CallBackForm() {
  return (
    <Fragment>
      <div className="container-fluid faderight">
        <div className="row">
          <Callbackform />
        </div>
      </div>
    </Fragment>
  );
}

export default CallBackForm;
