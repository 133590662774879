import React, { Fragment } from "react";

const BankDetailsHeader = () => {
  return (
    <Fragment>
      <div className="row profile claim">
        <div className="col-sm-6 ">
          <h2>Bank Details</h2>
          {/* <h6>Claim Intimation for Death Benefit</h6> */}
        </div>
      </div>
    </Fragment>
  );
};

export default BankDetailsHeader;
