import React, { useState, Fragment } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import Flip from "react-reveal";

function Step8() {
  const [furthCnic, setFuterCnic] = useState();

  const cityOptions = [
    "Karachi",
    "Lahore",
    "Islamabad",
    "Rawalpindi",
    "Faisalabad",
    "Multan",

    // Add more city options as needed
  ];

  const { Option } = Select;
  const handleCNICChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setFuterCnic(inputValue);
  };
  const tomorrow = new Date();
  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Further Information</h4>
        </div>
      </Flip>
      <Flip top delay={700}>
        <div className="col-sm-12">
          <Form.Item
            label="CNIC"
            name="CNIC"
            rules={[
              { required: true, message: "Please enter a valid CNIC number" },
            ]}
            valuePropName="formattedCnic"
            initialValue={furthCnic}
          >
            <Input
              placeholder="12345-1234567-1"
              value={furthCnic}
              onChange={handleCNICChange}
              maxLength={15} // 13 digits + 2 hyphens
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={900}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            name="date-issued"
            label="CNIC issue date"
            rules={[
              { required: true, message: "Please enter CNIC issued Date" },
            ]}
          >
            <DatePicker
              placeholder="01-01-2001"
              format="MM-DD-YYYY"
              disabledDate={(current) => current && current > tomorrow}
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1100}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            name="date-Expiry"
            label="CNIC Expiry Date"
            rules={[
              { required: true, message: "Please enter CNIC Expiry Date" },
            ]}
          >
            <DatePicker placeholder="01-01-2001" format="MM-DD-YYYY" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1300}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="Father/Husband Name"
            name="Father-Husband-Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Father/Husband Name" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1500}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="Mother Name"
            name="Mother-name"
            rules={[
              {
                required: true,
                message: "Mother Name is required",
              },
            ]}
          >
            <Input placeholder="Mother Name" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1700}>
        <div className="col-sm-6 forrPadd selectorrr">
          <Form.Item
            label="Marital Status"
            name="maritalStatus"
            rules={[
              {
                required: true,
                message: "Please select your marital status",
              },
            ]}
          >
            <Select placeholder="Select marital status">
              <Option value="single">Single</Option>
              <Option value="married">Married</Option>
              <Option value="divorced">Divorced</Option>
              <Option value="widowed">Widowed</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1900}>
        <div className="col-sm-6 forrPadd">
          <Form.Item label="Residency" name="residency">
            <Input disabled placeholder="Pakistani" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={2100}>
        <div className="col-sm-6 forrPadd">
          <Form.Item label="Nationality" name="nationality">
            <Input disabled placeholder="Pakistani" />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={2300}>
        <div className="col-sm-6 forrPadd selectorrr">
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please select a city ",
              },
            ]}
          >
            <Select placeholder="Select city">
              {cityOptions.map((city) => (
                <Option key={city} value={city}>
                  {city}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1500}>
        <div className="col-sm-12">
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: "Address is required",
              },
            ]}
          >
            <Input placeholder="Address" />
          </Form.Item>
        </div>
      </Flip>
    </Fragment>
  );
}

export default Step8;
