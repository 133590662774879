import React, { Fragment } from "react";
import Takafulvideos from "../Components/Videos/Takafulvideos";
import { Helmet } from "react-helmet";

function Videos() {
  return (
    <Fragment>
      <Helmet>
        <title>Media | Islamic Life Insurance | Salaam Family Takaful</title>
        <meta name="description" content="Salaam Life and Savings videos" />
        <meta
          name="keywords"
          content="product videos,shariah videos,salaam Life & Savings Video"
        />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row">
          <Takafulvideos />
        </div>
      </div>
    </Fragment>
  );
}

export default Videos;
