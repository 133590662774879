import React, { useState } from "react";
import { Fragment } from "react";
import { DatePicker } from "antd";
import { Button, Form, Select, message } from "antd";
import Fade from "react-reveal/Fade";
import PastPriceTable from "./PastPriceTable";
import axios from "axios";

const OPTIONS = ["Money Market Fund"];

function PastPriceForm() {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [fundPrices, setFundPrices] = useState([]);
  const filteredOptions = OPTIONS;

  const validateMessages = {
    required: "${label} is required!",
  };

  const onFinish = async (values) => {
    setSubmitting(true);
    setIsSuccessMessageVisible(false);

    const { name, startDate, endDate } = values;

    if (!name || !startDate || !endDate) {
      message.error("Please select both fund name and date range.");
      setSubmitting(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://ilfunds.salaamlifetakaful.com/api/CashValue/FundsPrice`,
        {
          params: {
            DateFrom: startDate.format("DD/MM/YYYY"),
            DateTo: endDate.format("DD/MM/YYYY"),
            FundNames: name.join(","), // Assuming API supports multiple fund names as a comma-separated string
          },
        }
      );

      setFundPrices(response.data);
      setIsSuccessMessageVisible(true);
      form.resetFields();
    } catch (error) {
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="sub complainforms whistleforms pastPriceForm">
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className="row">
            <div className="col-sm-12 pastPriceForm">
              <Fade down delay={500}>
                <Form.Item
                  name="name"
                  rules={[{ required: true }]}
                  className="mbbbbb"
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Fund"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{
                      width: "100%",
                    }}
                    options={filteredOptions.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </Fade>
              <Fade down delay={1000}>
                <Form.Item
                  name="startDate"
                  rules={[{ required: true, message: "Please select a start date." }]}
                >
                  <DatePicker placeholder="Start Date" />
                </Form.Item>
              </Fade>

              <Fade down delay={1000}>
                <Form.Item
                  name="endDate"
                  rules={[{ required: true, message: "Please select an end date." }]}
                >
                  <DatePicker placeholder="End Date" />
                </Form.Item>
              </Fade>
            </div>
          </div>

          <div className="col-sm-12 genResBtn">
            <Fade down delay={1300}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Generate Results
                </Button>
              </Form.Item>
            </Fade>
          </div>
        </Form>

        {fundPrices?.length > 0 && (
          <div className="col-sm-12">
            <PastPriceTable fundPrices={fundPrices} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default PastPriceForm;
