import React from "react";
import trash from "../../Assets/Trash.png";
import edit from "../../Assets/Edit.png";
const BeneficeryGuardianTable = () => {
  const Array = [1, 2, 3];
  return (
    <div className="TableSection BeneficiaryTable">
      <table>
        <thead>
          <tr>
            <th className="fst">Beneficiary Name</th>

            <th>CNIC</th>

            <th>Age</th>

            <th>Relation</th>
            <th>Share</th>
            <th>Guardian Name</th>

            <th>Guardian CNIC</th>
            <th>Age</th>

            <th className="lst"></th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <tr>
              <td className="fst">John Doe</td>
              <td>43431-412421410-1</td>
              <td>Father</td>
              <td>59</td>
              <td>John Doe</td>
              <td>43431-412421410-1</td>
              <td>40</td>
              <td className="lst">
                <img src={trash} alt="delete" />
                <img src={edit} alt="edit" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BeneficeryGuardianTable;
