import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";

import PastPriceForm from "./PastPriceForm";

function PastPriceBanner() {
  return (
    <Fragment>
      <div className="col-sm-12 heads govbanner ">
        <Fade up delay={500}>
          <h2 className="oswald">Past Price</h2>
        </Fade>
      </div>

      <div className="container tabss motor pastprices">
        <div className="row">
          <div className="col-sm-12 heads text mymaxheight complainbox">
            <PastPriceForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PastPriceBanner;
