import React, { Fragment } from "react";
import { Fade } from "react-reveal";

const CompanyBanner = () => {
  return (
    <Fragment>
      <Fade up delay={500}>
        <div className="col-sm-12 heads govbanner">
          {" "}
          <h2 className="oswald">COMPANY DETAILS</h2>
        </div>
      </Fade>
    </Fragment>
  );
};

export default CompanyBanner;
