import { Checkbox } from "antd";
import React from "react";

const CashValueTable = () => {
  const Array = [1, 2, 3];
  return (
    <div className="TableSection">
      <table>
        <thead>
          <tr>
            <th className="fst">Select Type of Funds</th>

            <th>No. of Units</th>

            <th>No. of Units</th>

            <th>Unit Price</th>
            <th>Current Value</th>
            <th className="lst"></th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <tr>
              <td className="fst">Fund Name A</td>
              <td>50</td>
              <td>50</td>
              <td>50</td>
              <td>1024</td>
              <td className="lst">
                <Checkbox></Checkbox>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CashValueTable;
