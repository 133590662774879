import React, { Fragment, useEffect } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";
// import shariahImage from "../../Assets/homeshariah.jpeg";
// import whoImage from "../../Assets/whoweare.jpeg";
// import governance from "../../Assets/homegovernance.png";

function Homemenu({ change, setChange }) {
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );

    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);

  return (
    <Fragment>
      <div className="container homemenu">
        <div className="row">
          <div className="col-sm-12 explore">
            {/* <h2>
              <Fade delay={500}>About</Fade>{" "}
              <Flip top delay={800}> Us </Flip>
            </h2> */}
          </div>

          <div className="col-sm-4 side">
            <h3>
              <Flip top delay={500}>
                {" "}
                Making Your Journey Safe with the Perks of Savings in Life
              </Flip>
              <Flip bottom delay={800}></Flip>
              <Flip bottom delay={1000}></Flip>
            </h3>

            <ul>
              <Flip top delay={1200}>
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  High Savings Potential
                </li>
              </Flip>
              <Flip top delay={1400}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  Financial Protection
                </li>
              </Flip>
              <Flip top delay={1600}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  Exclusive Coverage
                </li>
              </Flip>
              <Flip top delay={1400}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  End to End Digital Journey
                </li>
              </Flip>
              <Flip top delay={1800}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  Shariah Compliant
                </li>
              </Flip>
              <Flip top delay={2000}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  100% Allocation of Funds
                </li>
              </Flip>
              <Flip top delay={2200}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt="asd"
                  />
                  Free Withdrawal Facility
                </li>
              </Flip>
            </ul>

            <Fade left delay={1800}>
              <Link
                to={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/CORPORATEPROFILE.pdf`}
                target="_blank"
                className="buy"
              >
                Download Corporate Profile
              </Link>
            </Fade>
          </div>

          <div className="col-sm-8 menus">
            <Fade right delay={500}>
              <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                breakpoints={{
                  "320": { slidesPerView: 1, spaceBetween: 0 },
                  "990": { slidesPerView: 2, spaceBetween: 10 },
                  "1100": { slidesPerView: 3, spaceBetween: 30 },
                  "@1.50": { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Link
                    to="/whoweare"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    <div className="menusildes VIDEEEOS">
                      <img
                        // width="100%" height="100%"
                        src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/aboutus.gif`}
                        alt="asd"
                      />

                      {/* <video width="100%" height="100%" controls autoPlay>
                        <source
                          src={`${process.env.REACT_APP_ASSETS_URL}/Assets/video/aboutus.mp4`}
                          type="video/mp4"
                        />
                      </video> */}
                      <h3>Who We Are</h3>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/governance"
                    // onClick={() => {
                    //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    // }}
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    <div className="menusildes VIDEEEOS">
                      <img
                        src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/GovernanceLifeAndSaving.gif`}
                        // src={governance}
                        alt="asd"
                      />
                      {/* <video width="100%" height="100%" controls autoPlay>
                        <source
                          src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/GovernanceLifeAndSaving.mp4`}
                          type="video/mp4"
                        />
                      </video> */}
                      <h3>Governance</h3>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link
                    to="/shariah"
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                      document.body.scrollTop = 0;
                    }}
                  >
                    <div className="menusildes VIDEEEOS">
                      <img
                        src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/shariah.gif`}
                        // src={shariahImage}
                        alt="asd"
                      />
                      {/* <video width="100%" height="100%" controls autoPlay>
                        <source
                          src={`${process.env.REACT_APP_ASSETS_URL}/Assets/video/shariah.mp4`}
                          type="video/mp4"
                        />
                      </video> */}
                      <h3>Shariah</h3>
                    </div>
                  </Link>
                </SwiperSlide>
                {/* 
                <SwiperSlide>
                  <Link to="/individuals" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" });}} >
                    <div className="menusildes">
                      <img src={About} alt="asd" />
                      <h3>Products</h3>
                    </div>
                  </Link>
                </SwiperSlide> */}
              </Swiper>
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Homemenu;