import React, { Fragment, useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import Fade from "react-reveal/Fade";
import { SalaamDirectors } from "../../Data/Data";
import AOS from "aos";
import "aos/dist/aos.css";
import Salman from "../../Assets/salman.jpg";
import Saba from "../../Assets/saba.jpeg";

function Directorbox() {
  const [isFlipped, setIsFlipped] = useState(
    Array(SalaamDirectors.length).fill(false)
  );

  const handleClick = (index) => {
    setIsFlipped((prevState) => {
      const newFlipped = [...prevState];
      newFlipped[index] = !newFlipped[index];
      return newFlipped;
    });
  };

  useEffect(() => {
    AOS.refresh();
  }, [isFlipped]);

  return (
    <Fragment>
      <div className="container">
        <div className="row governanceImages">
          {SalaamDirectors.map((data, index) => (
            <div className="col-sm-6" key={index}>
              <Fade down delay={500}>
                <ReactCardFlip
                  isFlipped={isFlipped[index]}
                  flipDirection={data.animation}
                  containerStyle={{ height: "100%" }}
                >
                  <div>
                    <button onClick={() => handleClick(index)}>
                      {data.key === "5" ? (
                        <>
                          <img src={Saba} alt="" />
                        </>
                      ) : data?.image ? (
                        <img src={data.image} alt="directors" />
                      ) : (
                        <img src={Salman} alt="directors" />
                      )}

                      <h3 className="dname">{data.name}</h3>
                      <p className="designation">{data.designation}</p>
                    </button>
                  </div>

                  <div>
                    <button
                      onClick={() => handleClick(index)}
                      className="backbox"
                    >
                      <p>{data.detail}</p>
                    </button>
                  </div>
                </ReactCardFlip>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Directorbox;
