import React, { useState, useRef, Fragment } from "react";
import { Form, Input, Tooltip } from "antd";
import Flip from "react-reveal/Flip";
import { QuestionCircleOutlined } from "@ant-design/icons";

function Step7() {
  const length = 6;
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(Array(length).fill(""));

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return; // Allow only numeric input
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Move focus to the next input field
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move focus to the previous input field on backspace
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>
            Enter OTP
            <span>
              <Tooltip
                color="#0889e3"
                title="please enter the OTP sent on your registered Mobile number and email address"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </h4>
        </div>
      </Flip>

      <Flip top delay={900}>
        <div className="col-sm-12 otpModal">
          <Form>
            {otp.map((digit, index) => (
              <Form.Item
                key={index}
                rules={[
                  { required: true, message: "Please enter an OTP digit" },
                ]}
              >
                <Input
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              </Form.Item>
            ))}
          </Form>
        </div>
      </Flip>
    </Fragment>
  );
}

export default Step7;
