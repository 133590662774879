import React from "react";
import Profile from "../Main/Profile";
import CashValueTable from "./CashValueTable";

const CashValue = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row ">
        <div className="col-sm-12 ">
          <CashValueTable />
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Funds Performance Report</button>
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Unit Report</button>
        </div>
        <div className="col-sm-4 ">
          <button className="dashboardBn">Fund Switch</button>
        </div>
      </div>
    </div>
  );
};

export default CashValue;
