import React from "react";

const LifeAtSalaamText = () => {

  return (
    <>
      <h6>Welcome to Salaam Family Takaful, where our commitment to excellence extends within the bounds of Shariah compliance and embraces the forefront of insurtech innovation.</h6>
      <p>Rooted in Islamic principles, we create a workplace that aligns with ethical standards while embracing cutting-edge technology. From our state-of-the-art amenities to engaging events, targeted training
        programs, and community initiatives, Salaam Family Takaful thrives at the intersection of tradition and innovation. Join us on this unique journey where collaboration, growth, and well-being flourish in a 
        Shariah-compliant and insurtech-driven environment, defining the essence of Life At Salaam Family Takaful.</p>
  
    </>
  );
};

export default LifeAtSalaamText;