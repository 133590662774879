import React from "react";

import Flip from "react-reveal/Flip";
import FundTable from "./FundTable";
import UnitPrice from "./UnitPrice";
import StrategyTable from "./StrategyTable";

const FundSwitch = () => {
  return (
    <div className="container">
      <UnitPrice />
      <div className="row withDrawl">
        <Flip top delay={400}>
          <div className="col-sm-12">
            <FundTable />
          </div>
          <div className="col-sm-12">
            <StrategyTable />
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default FundSwitch;
