import React, { Fragment } from "react";
import Homebanner from "../Components/Home/Homebanner";
import Homemenu from "../Components/Home/Homemenu";
import Productmenus from "../Components/Home/Productmenus";
import Newsmenu from "../Components/Home/Newsmenu";
import Appsdownload from "../Components/Home/Appsdownload";
import { Helmet } from "react-helmet";

function Home({ change, setChange }) {
  return (
    <Fragment>
      <Helmet>
        <title>
          Salaam Life and Savings | Islamic Life Insurance | Salaam Family
          Takaful
        </title>
        <meta
          name="description"
          content="This is the home page of Your Website. We offer the best services for your needs."
        />
        <meta name="keywords" content="home, services, Your Website" />
      </Helmet>
      <div className="fadeInLeft">
        <Homebanner />
        <Homemenu change={change} setChange={setChange} />
        <Productmenus />
        <Newsmenu />
        <Appsdownload />
      </div>
    </Fragment>
  );
}

export default Home;
