import React from "react";
import { Fragment } from "react";

function Complaintext() {
  return (
    <Fragment>
      <div className="sub shariahcom moreePadd">
        <p style={{ color: "#000" }} className="newAliggn">
          Salaam Family Takaful Limited is fully committed to providing a high class experience to its participants. However, we need your support to further improve our services. 
          If you come across any problem or query, have a complaint or suggestion; please inform us through any of the following contact channels
        </p>
      </div>
    </Fragment>
  );
}

export default Complaintext;
