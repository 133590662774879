import React from "react";

const Political = () => {
  return (
    <div className="modalForm container">
      <div className="col-sm-12">
        <h4>Politically-Exposed-Persons' (PEPs)</h4>
      </div>
      <div className="col-sm-12">
        <p>
          Also, I confirm that me or any of my associate (immediate family
          member) is not affiliated with any Political Party, or works for the
          Judiciary, Armed Forces, Law Enforcement Agency, or Bureaucracy in any
          possible way. Furthermore, I declare that no criminal record or any
          criminal proceedings pending against me before any adjudicating
          forum(s) OR associated/linked with any Proscribed
          Organization/Individual referred in Schedule IV of the Anti-Terrorism
          Act 1997 or as per any other prevailing law of the land.
        </p>
      </div>
    </div>
  );
};

export default Political;
