import React, { Fragment, useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import Capitals from "./Capitals";
import Directorbox from "./Directorbox";
import { Helmet } from "react-helmet";

function Govbanner() {
  const [currentSection, setCurrentSection] = useState("");

  const sectionsRefs = useRef({});

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5, // Trigger when 50% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          console.log("Section in view:", sectionId); // Debugging line
          setCurrentSection(sectionId);
        }
      });
    }, options);

    const sections = document.querySelectorAll(".scroll-section");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const getMetaData = (section) => {
    switch (section) {
      case "directors":
        return {
          title:
            "Board of Directors | Salaam Life and Savings | Islamic Life Insurance",
          description:
            "Salaam Family Takaful Limited of Board of Directors,Rizwan Hussain",
          keywords:
            "Governance,Board of Directors,Management,Salaam Family Takaful Limited",
        };
      case "pattern":
        return {
          title:
            "Pattern of Shareholding | Salaam Life and Savings | Islamic Life Insurance",
          description:
            "The details of the company shareholders in terms of percentage",
          keywords:
            "Salaam Life Takaful Shareholders,Salaam life and Savings shareholder",
        };

      default:
        return {
          title:
            "Board of Directors | Salaam Life and Savings | Islamic Life Insurance",
          description:
            "Salaam Family Takaful Limited of Board of Directors,Rizwan Hussain",
          keywords:
            "Governance,Board of Directors,Management,Salaam Family Takaful Limited",
        };
    }
  };

  const metaData = getMetaData(currentSection);

  const backgroundImageUrl = `${process.env.REACT_APP_ASSETS_URL}/Assets/img/gvbanner.jfif`;

  const bannerStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };
  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
      </Helmet>
      <div className="container governancemain motor">
        <div className="row">
          <div
            className="col-sm-12 heads govbanner scroll-section"
            style={bannerStyle}
          >
            <Fade up delay={500}>
              <h2 className="oswald">Governance</h2>
            </Fade>
          </div>

          <div
            id="directors"
            className=" col-sm-12 heads text govdirectors scroll-section"
            ref={(el) => (sectionsRefs.current["directors"] = el)}
          >
            <Fade up delay={500}>
              <h2>Board of Directors </h2>
            </Fade>
            <Directorbox />
          </div>

          {/* <div className="col-sm-12 heads text govdirectors" id="managment">
            <h2>Management Team </h2>
            <Comiitemanagment />
          </div> */}

          <div
            className="col-sm-12 heads text shareholder scroll-section"
            id="pattern"
            ref={(el) => (sectionsRefs.current["pattern"] = el)}
          >
            <Capitals />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Govbanner;
