import React, { Fragment, useState } from 'react'
import calculateImage from "../../Assets/CalculateImage.avif"
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';
import Flip from "react-reveal/Flip";

const CalculateForm = () => {
    const [value, setValue] = useState(1);

    const bannerStyle = {
        backgroundImage: `url(${calculateImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
    };
    
    const [form] = Form.useForm();

    const onFinish = (values) => {
        form.resetFields();
    };

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    return (
        <Fragment>
            <div className="container-fluid stepsform calculateForrrm">
                <div className="row">
                    <div className="col-sm-4 steping" style={bannerStyle}>
                    </div>

                    <div className="col-sm-8 stepforms step steps">
                        <Form 
                            autoComplete='off'
                            form={form} 
                            onFinish={onFinish} 
                            layout="vertical"
                        >
                            <div className="row">
                                <Flip top delay={400}>
                                    <div className="col-sm-12 formHeader">
                                        <h4>Enter Information</h4>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Name"
                                            name="Name"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Name is required!" 
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Name (Same as CNIC)" />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Phone Number"
                                            name="Number"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Phone Number is required!" 
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Phone Number" />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Date Of Birth"
                                            name="DOB"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Date Of Birth is required!" 
                                                }
                                            ]}
                                        >
                                            <DatePicker placeholder="Date Of Birth" />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Email"
                                            name="Email"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Email is required!" 
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please enter valid email!'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Investment Input"
                                            name='investment'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Investment type is required!"
                                                }
                                            ]}
                                        >
                                            <Radio.Group onChange={onChange} value={value}>
                                                <Radio value='Deposit'> Deposit Amount</Radio>
                                                <Radio value='Maturity'> Maturity Amount</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Enter Contribution Amount"
                                            name="ContributionAmount"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Contribution Amount is required!" 
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Contribution Amount" />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Plan Terms (Years)"
                                            name="PlanTerms"
                                            // rules={[
                                            //     { 
                                            //         required: true, 
                                            //         message: "Plan Terms is required!" 
                                            //     }
                                            // ]}
                                        >
                                            <Input placeholder="Plan Terms" type='number' min={1} />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12 contriFreq">
                                        <Form.Item
                                            label="Contribution Frequency"
                                            name="ContributionFrequency"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Contribution Frequency is required!" 
                                                }
                                            ]}
                                        >
                                            <Select placeholder='Contribution Frequency' value='Annual'>
                                                <Select.Option value='Annual'>Annual</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Cover Multiple"
                                            name="CoverMultiple"
                                            // rules={[
                                            //     { 
                                            //         required: true, 
                                            //         message: "Cover Multiple is required!" 
                                            //     }
                                            // ]}
                                        >
                                            <Input placeholder="Cover Multiple" type='number' min={1} />
                                        </Form.Item>
                                    </div>
                                </Flip>

                                <Flip top delay={500}>
                                    <div className="col-sm-12 mynutonsstep calculateeeForm">
                                    <Button type="primary" htmlType='submit' >
                                        {" "}
                                        Process{" "}
                                    </Button>
                                    </div>
                                </Flip>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CalculateForm
