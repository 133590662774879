import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";

function Downloads({ show, setshow, change, setChange }) {
  const navigate = useNavigate();

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);
  return (
    <div className="subs">
      <div>
        <div className="container submenus">
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Individual Life</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    {" "}
                    <Link
                      to={`${process.env.REACT_APP_ASSETS_URL}/Assets/pdf/Individual.pdf`}
                      target="_blank"
                    >
                      {" "}
                      Shariah Certificates of Products{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/IndividualBrochure.pdf`}
                      target="_blank"
                    >
                      Product Brochures
                    </Link>
                  </li>
                  {/* 
                    <li> Forms </li>
                    <li> PMD </li>
                    <li> Medical network </li>
                    <li> How to buy online </li> 
                  */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Group Life</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    {" "}
                    <Link
                      href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Term.pdf`}
                      target="_blank"
                    >
                      Shariah Certificates of Products (Corporate){" "}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/GroupBrochure.pdf`}
                      target="_blank"
                    >
                      Product Brochures/Flyers
                    </Link>
                  </li>

                  {/* 
                    <li> Forms </li>
                    <li> PMD </li>
                    <li> Medical network </li>
                    <li> Discounted OPD centers</li>
                    <li> How to buy online</li>
                    <li> Broacher </li> 
                  */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>Corporate (PDF)</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li> Forms </li>
                  <li> PMD </li>
                  <li>
                    {" "}
                    <Link
                      to={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Term.pdf`}
                      target="_blank"
                    >
                      Shariah Certificates of Products (Corporate)
                    </Link>
                  </li>
                  <li> Product Brochures/Flyers</li>
                  <li> Medical network </li>
                  <li> Discounted OPD centers </li>
                  <li> How to buy online </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* <Accordion.Item eventKey="3">
              <Accordion.Header>Waqf Deed</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link
                      to={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Waqf.pdf`}
                      target="_blank"
                    >
                      Waqf Deed
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* <Accordion.Item eventKey="4">
              <Accordion.Header>Waqf Rules</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="#" target="_blank">
                      Waqf Rules
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>Takaful Booklet</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Takaful Booklet</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="6">
              <Accordion.Header>Policies</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="/whoweare#coc" onClick={() => setChange(!change)}>
                      {" "}
                      Policies
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to="/whistle-blowing"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Whistle Blowing Form
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="5">
              <Accordion.Header>Takaful Rules</Accordion.Header>
              <Accordion.Body>
                <ul></ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>Unclaimed Benefits</Accordion.Header>
              <Accordion.Body>
                <ul></ul>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Downloads;
