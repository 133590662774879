import React, { Fragment } from "react";
import { DatePicker, Form, Input, InputNumber, Radio, Tooltip } from "antd";
import Flip from "react-reveal/Flip";
import { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";

function Step4() {
  const [existingLifePlan, setExistingLifePlan] = useState("no");
  const [totalIncome, setTotalIncome] = useState();
  const [annualIncome, setAnnualIncome] = useState();
  const [otherIncome, setOtherIncome] = useState();
  const handleExistingLifeChange = (e) => {
    setExistingLifePlan(e.target.value);
  };

  useEffect(() => {
    setTotalIncome(annualIncome + otherIncome);
  }, [annualIncome, otherIncome]);

  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Need Analysis</h4>
        </div>
      </Flip>

      <Flip top delay={500}>
        <div className="col-sm-12 radios">
          <Form.Item name="existing-life" label="Existing Life Assurance Plan">
            <Radio.Group
              onChange={handleExistingLifeChange}
              value={existingLifePlan}
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>

      {existingLifePlan === "no" ? (
        <>
          {" "}
          <Flip top delay={700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                name="Annual-income "
                label={
                  <span>
                    Annual Income (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="Your total earnings in a single year before taxes and deductions"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select an Annual income",
                  },
                ]}
              >
                <InputNumber
                  onChange={(e) => setAnnualIncome(e)}
                  value={annualIncome}
                  placeholder="Annual Income"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label={
                  <span>
                    Other Income (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="Any additional earnings or financial resources not included in your primary annual income"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="Other-income"
                rules={[
                  {
                    required: true,
                    message: "Please select an Other income",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Other Income"
                  onChange={(e) => setOtherIncome(e)}
                  value={otherIncome}
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1100}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                name="Expenses-last-year"
                label={
                  <span>
                    Expenses Last Year (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The total amount of money you spent on various costs and expenditures during the previous year"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Expenses last year is required",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Expenses Last Year"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label={
                  <span>
                    Expenses Current Year (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The total amount of money you have spent on various costs and expenditures during the current year."
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="expenses-current-year"
                rules={[
                  {
                    required: true,
                    message: "Expenses current year required",
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                  placeholder="Expenses current year"
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1500}>
            <div className="col-sm-6 forrPadd">
              <Form.Item label={"Total Income"}>
                <InputNumber
                  placeholder="Expenses current year"
                  disabled
                  value={totalIncome}
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                // label={"Net Savings"}
                label={
                  <span>
                    Net Savings&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The amount you've saved after deducting all expenses from your income.
                        "
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <InputNumber placeholder="Net Savings" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1900}>
            <div className="col-sm-12">
              <Form.Item
                label={
                  "If You  Financially Dependent on Anybody? if Yes,details please"
                }
              >
                <InputNumber placeholder="" />
              </Form.Item>
            </div>
          </Flip>
          :<> </>{" "}
        </>
      ) : (
        <>
          <Flip top delay={700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Policy Number"
                name="Policy-Number"
                rules={[
                  { required: true, message: "Please select a Policy Number" },
                ]}
              >
                <Input placeholder="Policy Number" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Sum Assured"
                name="Sum-Assured"
                rules={[
                  { required: true, message: "Please select a Sum Assured" },
                ]}
              >
                <Input placeholder="Sum Assured" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1100}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Contribution"
                name="Contribution"
                rules={[
                  {
                    required: true,
                    message: "Contribution is required",
                  },
                ]}
              >
                <Input placeholder="Contribution" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Start Date"
                name="Start-Date"
                rules={[{ required: true, message: "Start Date is required" }]}
              >
                <DatePicker placeholder="Start Date" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1500}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Maturity Date"
                name="Maturity-Date"
                rules={[
                  { required: true, message: "Maturity Date is required" },
                ]}
              >
                <DatePicker placeholder="Maturity Date" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1900}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Purpose"
                name="Purpose"
                rules={[{ required: true, message: "Purpose is required" }]}
              >
                <Input placeholder="Purpose" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Company"
                name="Company"
                rules={[{ required: true, message: "Please select a Company" }]}
              >
                <Input placeholder="Company" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label="Condition of Acceptance"
                name="Condition-of-Acceptance"
                rules={[
                  {
                    required: true,
                    message: "Please select Condition of Acceptance",
                  },
                ]}
              >
                <Input placeholder="Condition Of Acceptance" />
              </Form.Item>
            </div>
          </Flip>
          <div className="col-sm-12 formHeader">
            <h4>Financial Details</h4>
          </div>
          <Flip top delay={700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                name="Annual-income "
                label={
                  <span>
                    Annual Income (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="Your total earnings in a single year before taxes and deductions"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select an Annual income",
                  },
                ]}
              >
                <InputNumber
                  onChange={(e) => setAnnualIncome(e)}
                  value={annualIncome}
                  placeholder="Annual Income"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label={
                  <span>
                    Other Income (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="Any additional earnings or financial resources not included in your primary annual income"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="Other-income"
                rules={[
                  {
                    required: true,
                    message: "Please select an Other income",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Other Income"
                  onChange={(e) => setOtherIncome(e)}
                  value={otherIncome}
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1100}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                name="Expenses-last-year"
                label={
                  <span>
                    Expenses Last Year (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The total amount of money you spent on various costs and expenditures during the previous year"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Expenses last year is required",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Expenses Last Year"
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                label={
                  <span>
                    Expenses Current Year (PKR)&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The total amount of money you have spent on various costs and expenditures during the current year."
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="expenses-current-year"
                rules={[
                  {
                    required: true,
                    message: "Expenses current year required",
                  },
                ]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(/\$\s?|(,*)/g), "")
                  }
                  placeholder="Expenses current year"
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1500}>
            <div className="col-sm-6 forrPadd">
              <Form.Item label={"Total Income"}>
                <InputNumber
                  placeholder="Expenses current year"
                  disabled
                  value={totalIncome}
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1700}>
            <div className="col-sm-6 forrPadd">
              <Form.Item
                // label={"Net Savings"}
                label={
                  <span>
                    Net Savings&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The amount you've saved after deducting all expenses from your income.
                        "
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <InputNumber placeholder="Net Savings" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1900}>
            <div className="col-sm-12">
              <Form.Item
                label={
                  "If You  Financially Dependent on Anybody? if Yes,details please"
                }
              >
                <InputNumber placeholder="" />
              </Form.Item>
            </div>
          </Flip>
          :<></>
        </>
      )}
    </Fragment>
  );
}

export default Step4;
