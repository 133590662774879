import { Link } from "react-router-dom";

function UserSidebar() {
  return (
    <div className="userSidebar">
      <div className="sideBarSec">
        <Link>
          <p>Service</p>
        </Link>
        <Link>
          <p>Product</p>
        </Link>
        <Link>
          <p>Media</p>
        </Link>
        <Link>
          <p>Investor Information</p>
        </Link>
        <Link>
          <p>Surplus</p>
        </Link>
        <Link>
          <p>Downloads</p>
        </Link>
        <Link>
          <p>eServices</p>
        </Link>
        <Link>
          <p>Contact</p>
        </Link>
      </div>
      <div className="f">
        <a href="/" target="">
          <img
            // src={Face}
            src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Facebook.png`}
            alt="facbook"
          />
        </a>
        <a href="/" target="">
          <img
            // src={Insta}
            src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Instagram.png`}
            alt="Instagram"
          />
        </a>
        <a href="/" target="">
          <img
            // src={tweet}
            src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Twitter Squared.png`}
            alt="Tweeter"
          />
        </a>
        <a href="/" target="">
          <img
            // src={Linked}
            src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/LinkedIn.png`}
            alt="LinkedIn"
          />
        </a>
        <a href="/" target="">
          <img
            // src={You}
            src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/YouTube.png`}
            alt="YouTube"
          />
        </a>
      </div>
    </div>
  );
}

export default UserSidebar;
