import { Checkbox } from "antd";
import React from "react";

const PayementTable = () => {
  const Array = [1];
  return (
    <div className="TableSection">
      <table>
        <thead>
          <tr>
            <th className="fst">Select Type of Funds</th>

            <th>No. of Units</th>

            <th>No. of Units</th>

            <th>Unit Price</th>
            <th>Current Value</th>
            <th className="lst"></th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <>
              <tr>
                <td className="fst">
                  {" "}
                  <img
                    // src={Payment1}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Payment2.png`}
                    alt=""
                  />
                  Paypro
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="lst">
                  <Checkbox></Checkbox>
                </td>
              </tr>
              <tr>
                <td className="fst">
                  <img
                    // src={Payment2}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Payemnent1.png`}
                    alt=""
                  />
                  Debit / Credit Card
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="lst">
                  <Checkbox></Checkbox>
                </td>
              </tr>
              <tr>
                <td className="fst">
                  {" "}
                  <img
                    // src={Payment3}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Payemnent1.png`}
                    alt=""
                  />
                  EasyPaisa
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="lst">
                  <Checkbox></Checkbox>
                </td>
              </tr>
              <tr>
                {" "}
                <td className="fst">
                  {" "}
                  <img
                    //  src={Payment4}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Payment3.png`}
                    alt=""
                  />
                  Jazz Cash
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="lst">
                  <Checkbox></Checkbox>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayementTable;
