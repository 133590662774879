import React, { Fragment } from "react";

const AgentProfile = () => {
  return (
    <Fragment>
      <div className="row profile status">
        <div className="col-sm-12">
          <h6>Type of Claim</h6>
          <h2>Mr. Jameel Ahmed Siddiqui</h2>
        </div>
        <div className="col-sm-12">
          <h6>Code</h6>
          <h3>233242</h3>
        </div>
        <div className="col-sm-12">
          <h6>Designation</h6>
          <h3>Sales Manager</h3>
        </div>
        <div className="col-sm-12">
          <h6>Contact #</h6>
          <h3>03001234567</h3>
        </div>
      </div>
    </Fragment>
  );
};

export default AgentProfile;
