import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
// import Careertabs from "./Careertabs";
import Careerjobs from "./Careerjobs";
import LifeAtSalaamText from "../LifeAtSalaam/LifeAtSalaamText";
import { useNavigate } from "react-router-dom";

function CareerBanner() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/life-at-salaam");
  };
  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row">
          <div className="col-sm-12 heads govbanner">
            <Fade up delay={500}>
              <h2 className="oswald">Career's</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text mymaxheight interntabs ">
            <Fade up delay={500}>
              <h2>Current Vacancies</h2>
            </Fade>
            <Careerjobs />
          </div>
          <div className="col-sm-12 heads govbanner1"></div>

          <div className="col-sm-12 careerText">
            <LifeAtSalaamText />
            <button onClick={handleNavigate}>Learn More</button>
          </div>

          {/* <div className='col-sm-12 heads text mymaxheight interntabs '>
                        <Fade up delay={500}><h2>Life at Salaam Takaful Limited</h2></Fade>
                        <Careertabs />
                    </div> */}
        </div>
      </div>
    </Fragment>
  );
}

export default CareerBanner;
