import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { Table } from "antd";

function FundPricesTable({ fundPrices }) {
  const columns = [
    {
      title: "Fund Name",
      dataIndex: "name",
    },
    {
      title: "Current Nav",
      dataIndex: "headtonav",
    },
  ];

  if (!Array.isArray(fundPrices) || fundPrices.length === 0) {
    return <div style={{textAlign: 'center'}}>No data available</div>;
  }

  const data = fundPrices.map((fund, index) => ({
    key: index.toString(), 
    name: fund.FUND_NAME,
    headtonav: fund.BID_PRICE2,
  }));

  return (
    <Fragment>
      <div className="sub complainforms whistleforms fundPrices">
        <div className="row">
          <div className="col-sm-12 formstyle rightform">
            <Fade down delay={500}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
              />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FundPricesTable;
