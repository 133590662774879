import React from "react";

const ContriTable = () => {
  const Array = [1, 2, 3];
  return (
    <div className="TableSection TableSection1 unitPrice">
      <table>
        <thead>
          <tr>
            <th className="fst">Contribution Due Date</th>

            <th>Amount</th>

            <th className="lst">Current Value</th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <tr>
              <td className="fst">01 Oct,2023</td>

              <td>10,000</td>

              <td className="lst">Paid</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContriTable;
