import React from "react";
import { Link } from "react-router-dom";
const ClaimOptionsTab = () => {
  return (
    <div className="row container dashboardTabs">
      <div className="col-sm-4">
        <div className="box">
          <Link to="/dashboard/nominee-status">
            {" "}
            <p>Check Claim Status</p>
          </Link>
        </div>
      </div>
      <div className="col-sm-4">
        <Link to="/dashboard/nominee">
          <div className="box">
            <p>Nominee</p>
          </div>
        </Link>
      </div>

      <div className="col-sm-4">
        <Link to="/dashboard/nominee-form">
          <div className="box">
            <p>Others</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ClaimOptionsTab;
