import React, { useState } from "react";
// import { Form, Input, DatePicker, Select, Slider, Tooltip } from "antd";
import Flip from "react-reveal/Flip";
import { Fragment } from "react";
import { useEffect } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { occupationOptions } from "../../Data/Data.js";
import { useMemo } from "react";

import {
  Form,
  Input,
  Radio,
  Select,
  Modal,
  Tooltip,
  Slider,
  DatePicker,
} from "antd";

import RiderModal from "./FormModals/DiseaseModal/RiderModal";
import { EditOutlined, DeleteFilled } from "@ant-design/icons";

function Step1({ takafulContribution, setTakafulContribution }) {
  const { Option } = Select;
  const [weight, setWeight] = useState(70); // Default weight in kg
  const [height, setHeight] = useState(170); // Default height in cm
  const [bmi, setBMI] = useState(calculateBMI(weight, height));
  const [formattedCnic, setFormattedCnic] = useState("");
  const [value, setValue] = useState();

  const [yearValues, setYearValues] = useState([]);
  const [amountValues, setAmountValues] = useState([]);
  const [updatedyearValues, setupdatedYearValues] = useState([]);
  const [updatedamountValues, setupdatedAmountValues] = useState([]);
  const [plan, setPlan] = useState();
  const [contribution, setContribution] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const contributionOptions = ["Yearly", "Half-Yearly", "Quarterly", "Monthly"];

  const [selectedValues, setSelectedValues] = useState([]);
  const [updateselectedValues, setupdateSelectedValues] = useState([]);

  const handleRadioChange = (e) => {
    if (e.target.value === "yes") {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
      setSelectedValues([]);
    }
  };
  const handleDelete = (value) => {
    setupdateSelectedValues(
      updateselectedValues.filter((item) => item !== value)
    );
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
    setupdatedYearValues(yearValues);
    setupdatedAmountValues(amountValues);
    setupdateSelectedValues(selectedValues);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleContributionChange = (value) => {
    let formattedValue = value;

    // Remove existing commas from the input value
    formattedValue = formattedValue?.toString().replace(/,/g, "");

    // Format the value with commas
    formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setContribution(formattedValue);

    setTakafulContribution(value.target.value);
  };

  const calculateValues = useMemo(() => {
    let sumValue = (parseInt(takafulContribution) || 0) * (parseInt(plan) || 0);

    let formattedValue = sumValue;

    // Remove existing commas from the input value
    formattedValue = formattedValue?.toString().replace(/,/g, "");

    // Format the value with commas
    formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedValue;
  }, [takafulContribution, plan]);
  // const occupationOptions = [
  //   "Doctor",
  //   "Engineer",
  //   "Teacher",
  //   "Artist",
  //   "Student",
  //   // Add more occupation options as needed
  // ];
  function calculateBMI(weight, height) {
    // Convert height to meters
    const heightInMeters = height / 100;

    // Calculate BMI
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  }

  const handleCNICChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setFormattedCnic(inputValue);
  };

  const marks = {
    36: "3 ft",
    48: "4 ft",
    60: "5 ft",
    72: "6 ft",
    84: "7 ft",
    96: "8 ft",
  };

  useEffect(() => {
    setBMI(calculateBMI(weight, height));
  }, [weight, height]);
  return (
    <Fragment>
      <Flip top delay={400}>
        <div className="col-sm-12 formHeader">
          <h4>Enter Basic Information</h4>
        </div>
      </Flip>
      <Flip top delay={500}>
        <div className="col-sm-4">
          <Form.Item
            label="First Name"
            name="First Name"
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input placeholder="First Name  (Same as CNIC)" />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={500}>
        <div className="col-sm-4">
          <Form.Item label="Middle Name" name="Middle Name">
            <Input placeholder="Middle Name" />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={600}>
        <div className="col-sm-4">
          <Form.Item
            label="Last Name"
            name="Last Name"
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input placeholder="Last Name (Same as CNIC) " />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={700}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label={
              <span>
                CNIC&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="A unique identification card issued by the government of Pakistan, containing essential personal information."
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="CNIC"
            rules={[{ required: true, message: "CNIC number is required" }]}
            valuePropName="formattedCnic"
            initialValue={formattedCnic}
          >
            <Input
              placeholder="12345-1234567-1"
              value={formattedCnic}
              onChange={handleCNICChange}
              maxLength={15}
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={900}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select a gender" }]}
          >
            <Select
              defaultValue="Select Gender"
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" },
              ]}
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1100}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label={
              <span>
                Occupation&nbsp;
                <Tooltip color="#0889e3" title="Your primary job or profession">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="occupation"
            rules={[{ required: true, message: "Please select an occupation" }]}
          >
            <Select showSearch defaultValue="Select Occupation">
              {occupationOptions.map((occupation) => (
                <Option key={occupation} value={occupation}>
                  {occupation}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1300}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            defaultValue={100}
            rules={[{ required: true, message: "Phone number is required" }]}
          >
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              onChange={setValue}
              error={
                value
                  ? isValidPhoneNumber(value)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1500}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { type: "email", required: true, message: "Email is required" },
            ]}
          >
            <Input placeholder="E-mail" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1700}>
        <div className="col-sm-6 forrPadd">
          <Form.Item name="date-picker" label="Date Of Birth">
            <DatePicker format="MM-DD-YYYY" placeholder="01-01-2001" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={1900}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="Height"
            name="height"
            rules={[
              { required: true, message: "Height is required" },
              { type: "number", message: "Please select a valid height" },
            ]}
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          >
            <Slider
              marks={marks}
              min={36}
              max={96}
              step={1}
              tipFormatter={(value) =>
                `${Math.floor(value / 12)} ft ${value % 12} in`
              }
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={2100}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label="Weight in Kgs"
            name="weight"
            rules={[
              { required: true, message: "Weight is required" },
              { max: 3, message: "Weight should be maximum 3 characters" },
            ]}
          >
            <Input
              placeholder="Weight"
              type="text"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={2300}>
        <div className="col-sm-12">
          <Form.Item label="BMI">
            <Input value={Math.round(bmi)} disabled />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={2500}>
        <div className="col-sm-12 formHeader">
          <h4>Choose your Contribution Mode and Plan Terms</h4>
        </div>
      </Flip>
      <Flip top delay={2700}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            name="contribution"
            label={
              <span>
                Contribution Mode&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="The timeframe  when you would like to pay for a particular policy"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              { required: true, message: "Please select a Contribution" },
            ]}
          >
            <Select placeholder="Contribution Mode">
              {contributionOptions.map((contribution) => (
                <Option key={contribution} value={contribution}>
                  {contribution}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={2900}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label={
              <span>
                Plan Term&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="The duration or length of your selected plan, policy, or subscription in years"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="planterm"
            rules={[{ required: true, message: "Plan Term is required" }]}
          >
            <Input placeholder="Plan Term" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={3100}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label={
              <span>
                Takaful Contribution&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="The amount you contribute to your Takaful insurance policy, in accordance with Islamic principles of mutual assistance"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="takaful-contribution"
            value={contribution}
            rules={[
              { required: true, message: "Takaful Contribution is required" },
            ]}
          >
            <Input
              type="number"
              placeholder="Takaful Contribution"
              formatter={(value) =>
                `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
              }
              parser={(value) => value.replace(new RegExp(/\$\s?|(,*)/g), "")}
              onChange={(value) => handleContributionChange(value)}
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={3300}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            // label={"Cover Multiple"}

            label={
              <span>
                Cover Multiple&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="Cover multiple provides for the actual some covered based on the annual contribution made. 
                  As per regulation, the minimum cover multiple is 5 however you can increase it as per your requirement to increase the sum covered.
                  "
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="takaful-multiple"
            onChange={(e) => setPlan(e.target.value)}
            rules={[{ required: true, message: "Cover Multiple is required" }]}
          >
            <Input
              type="number"
              placeholder="Cover Multiple"
              formatter={(value) =>
                `${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
              }
              parser={(value) => value.replace(new RegExp(/\$\s?|(,*)/g), "")}
            />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={3500}>
        <div className="col-sm-6 forrPadd">
          <Form.Item
            label={
              <span>
                Sum Covered&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="It refers to the maximum amount of takaful coverage or compensation that is provided under your policy"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name="sum-covered"
          >
            <Input placeholder={calculateValues} disabled />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={3700}>
        <div className="col-sm-6 forrPadd radios">
          <Form.Item
            name="extra-benifits"
            label={
              <span>
                Extra Benefits (Riders)&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="Additional options or enhancements that can be added to your policy or subscription for extra coverage or benefits"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Radio.Group onChange={handleRadioChange}>
              <Radio value="yes" onClick={() => setIsModalVisible(true)}>
                Yes
              </Radio>
              <Radio value="no" onClick={() => setSelectedValues([])}>
                No
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={2000}>
        <div className="col-sm-6 forrPadd"></div>
        <div className="col-sm-12 ">
          <div className="contributionTable">
            {updateselectedValues.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Rider</th>
                    <th>Term (Years)</th>
                    <th>Sum Assured</th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {updateselectedValues.map((option, index) => (
                    <tr key={option}>
                      <td>{option}</td>
                      <td>{updatedyearValues[index]}</td>
                      <td>{updatedamountValues[index]} </td>
                      <td>
                        <EditOutlined onClick={() => setIsModalVisible(true)} />
                        <DeleteFilled
                          onClick={() => handleDelete(option)}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Flip>
      <Modal
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={"50%"}
      >
        <RiderModal
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setYearValues={setYearValues}
          yearValues={yearValues}
          amountValues={amountValues}
          setAmountValues={setAmountValues}
          plan={plan}
          contribution={contribution}
        />
      </Modal>
    </Fragment>
  );
}

export default Step1;
