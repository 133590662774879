import React from "react";

const PolicyTable = () => {
  const Array = [1, 2, 3];
  return (
    <div className="TableSection TableSection1">
      <table>
        <thead>
          <tr>
            <th className="fst">Policy Number</th>

            <th></th>

            <th></th>

            <th></th>
            <th></th>
            <th className="lst">Status</th>
          </tr>
        </thead>
        <tbody>
          {Array.map(() => (
            <tr>
              <td className="fst">UL23430434934</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="lst">Lapsed</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PolicyTable;
