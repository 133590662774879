import React from "react";
import { DatePicker, Form, Input, Modal, Select } from "antd";

import NomineeHeader from "./NomineeHeader";
import { useState } from "react";

const NomineeForm = () => {
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Given Information Verified Successfully"
  );

  const [formattedCnic, setFormattedCnic] = useState("");
  const { Option } = Select;
  const handleFinish = async (values) => {
    console.log(values);

    await showModal();
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      // navigate("/dashboard/nominee-info");
    }, 2000);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const handleCNICChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setFormattedCnic(inputValue);
  };
  return (
    <div className="container">
      <NomineeHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
         
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  { required: true, message: "Nominee Name is Required " },
                ]}
                name="Nominee Name"
              >
                <Input placeholder="Nominee Name" />
              </Form.Item>
            </div>
        
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  { required: true, message: "Nominee Relation is Required " },
                ]}
                name="Nominee Relation"
              >
                <Input placeholder="Nominee Relation" />
              </Form.Item>
            </div>
     
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                name="CNIC"
                rules={[
                  {
                    required: true,
                    message: "Nominee CNIC number is required",
                  },
                ]}
                valuePropName="formattedCnic"
                initialValue={formattedCnic}
              >
                <Input
                  placeholder="CNIC #"
                  value={formattedCnic}
                  onChange={handleCNICChange}
                  maxLength={15}
                />
              </Form.Item>
            </div>
      
            <div
              className="col-sm-2
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Nominee Gender is required" },
                ]}
                name="Gender"
              >
                <Select placeholder="Gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Other">Others</Option>
                </Select>
              </Form.Item>
            </div>
    
            <div
              className="col-sm-4
        "
            >
              <Form.Item
                rules={[{ required: true, message: "Policy is required" }]}
                name="policy"
              >
                <Input placeholder="Policy #" />
              </Form.Item>
            </div>
    
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Cause Of Death is required" },
                ]}
                name="Cod"
              >
                <Input placeholder="Cause Of Death" />
              </Form.Item>
            </div>
     
            <div
              className="col-sm-2
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: " Date of Event is required" },
                ]}
              >
                <DatePicker placeholder="Date Of Event" />
              </Form.Item>
            </div>
     
            <div
              className="col-sm-4
        "
            >
              <Form.Item
                name="shares"
                rules={[{ required: true, message: " Share % is required" }]}
              >
                <Input placeholder="Nominee Sharing %" />
              </Form.Item>
            </div>

            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Contact Number is required" },
                ]}
                name="number"
              >
                <Input placeholder="Contact Number" />
              </Form.Item>
            </div>

            <div
              className="col-sm-6
        "
            >
              <Form.Item name="altnumber">
                <Input placeholder="Alternate Contact" />
              </Form.Item>
            </div>
   
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[{ required: true, message: "Email is required" }]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
 
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[{ required: true, message: "Address is required" }]}
                name="address"
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>
     
            <div className="col-sm-12 verifyBtn">
              <button type="submit" className="dashboardBn">
                Verify
              </button>
            </div>
      
        </div>
      </Form>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default NomineeForm;
