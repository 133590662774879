import { Button, Checkbox, Form, Input, InputNumber, Tooltip } from "antd";
import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { Flip } from "react-reveal";

const Step5 = () => {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  return (
    <>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Prioritize Your Financial Properties and Objectives</h4>
        </div>
      </Flip>
      <Flip top delay={700}>
        <div className="col-sm-12">
          <h5>
            Priorities & Objectives
            <span>
              <Tooltip
                color="#0889e3"
                title="Define your most important goals and targets"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </h5>

          <p>please rate 01 - 07 according to your priorities</p>
        </div>
      </Flip>
      <Flip top delay={900}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Financial Security for the family in the event of death</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={7} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={1100}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>
                Financial Security for the family in the event of critical
                illness
              </p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={6} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={1300}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Providing Retirement income</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={5} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={1500}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Planning for your children’s education</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={3} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={1700}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Planning for your children’s wedding</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={2} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={1900}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Building capital through capital savings</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={4} />
            </div>
          </div>
        </div>
      </Flip>

      <Flip top delay={2100}>
        <div className="col-sm-12">
          <h5>
            Identified Takaful Needs
            <span>
              <Tooltip
                color="#0889e3"
                title="Specific areas or aspects where Takaful insurance coverage is required or recommended based on your financial and risk assessment"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          </h5>
          <p>please rate 01 - 07 according to your priorities</p>
        </div>
      </Flip>
      <Flip top delay={2300}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Desireable sum assured</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={6} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={2500}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Health Insurance Takaful</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={7} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={2700}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Desireable limit of coverage per annum</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={1} />
            </div>
          </div>
        </div>
      </Flip>

      <Flip top delay={2900}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Saving and investment planning</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={2} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={3100}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Desireable return per annum</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={3} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={3300}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Pension Planning</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={4} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={3500}>
        <div className="col-sm-6 forrPadd">
          <div className="row">
            <div className="col-sm-9">
              <p>Desireable pension per annum</p>
            </div>
            <div className="col-sm-3">
              <InputNumber defaultValue={5} />
            </div>
          </div>
        </div>
      </Flip>
      <Flip top delay={3700}>
        <div className="col-sm-12">
          <h5>Recommendations</h5>
          <p>Life Stage</p>
          <p>Protection Needs</p>
        </div>
      </Flip>
      <Flip top delay={3000}>
        <div className="col-sm-6 forrPadd">
        <div className="row recommendations buttonss less">
            <div className="col-sm-3">
              <Button>Life & Health</Button>
            </div>

            <div className="col-sm-5">
              <Button>Savings & Investment</Button>
            </div>

            <div className="col-sm-3">
              <Button>Pension</Button>
            </div>
          </div>
          <div className="row recommendations buttonss more">
            <div className="col-sm-3 NOLEFT">
              <Button>Life & Health</Button>
            </div>

            <div className="col-sm-3 NOrIGHTT">
              <Button>Pension</Button>
            </div>
            <div className="col-sm-5">
              <Button>Savings & Investment</Button>
            </div>

          </div>
        </div>
      </Flip>
      <Flip top delay={3200}>
        <div className="col-sm-6 forrPadd">
          <div className="row recommendations recoms">
            <div className="col-sm-3">
              <Button
                className={selectedButton === 0 ? "selected-button" : ""}
                onClick={() => handleButtonClick(0)}
              >
                Low
              </Button>
            </div>

            <div className="col-sm-4">
              <Button
                className={selectedButton === 1 ? "selected-button" : ""}
                onClick={() => handleButtonClick(1)}
              >
                Medium
              </Button>
            </div>

            <div className="col-sm-3">
              <Button
                className={selectedButton === 2 ? "selected-button" : ""}
                onClick={() => handleButtonClick(2)}
              >
                High
              </Button>
            </div>
          </div>
        </div>
      </Flip>

      <Flip top delay={3200}>
        <div className="col-sm-6 forrPadd">
          <p>
            Policy Recommendation, Including name of insurance / Takaful Plan
          </p>
          <Input placeholder="Salaam Life & Savings" />
        </div>
      </Flip>
      <Flip top delay={3400}>
        <div className="col-sm-6 forrPadd">
          <p>Commitment for the Current / Future Plan</p>
          <Input placeholder="type here..." />
        </div>
      </Flip>
      <Flip top delay={3600}>
        <div className="col-sm-12 termsSection">
          <p>
            ** The above recommendation is based on the information provided to
            me. I have been explained the features of the product and believe it
            would be suitable for me based on my insurance/Takaful needs and
            financial objectives. I also fully understand and acknowledge that:
            a) I am buying a Takaful product that is linked to the performance
            of underlying assets under unit-linked fund(s) and is not a pure
            investment or saving product such as collective investment schemes
            or mutual funds or bank contributions; b) This is a Regular takaful
            contribution product under which I will be required to pay the contribution
            (Takaful contribution) every month/ quarter, or year for the premium
            payment term selected by me. (only in the case of regular products)
            c) The investment risk under the policy shall be borne by me and the
            cash value of the policy may go up and down according to the
            performance of the underlying fund(s) in which I have opted to
            invest. The actual maturity or surrender values may be lower or
            higher than the projected figures. d) The policy is subject to
            certain fees and charges (which are deducted to cover Takaful risk
            under the policy) e) I have seen a brief audio-visual clip for the
            key features of the product.
          </p>
        </div>
        <Form.Item
          name="termsAndConditions"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("You must accept the terms and conditions"),
            },
          ]}
        >
          <Checkbox>
            <span style={{ color: "red" }}>*</span> I accept these terms &
            conditions
          </Checkbox>
        </Form.Item>
      </Flip>
    </>
  );
};

export default Step5;
