import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Form, Input, message } from "antd";
import Fade from "react-reveal/Fade";

function Complainform() {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };
  const onFinish = (values) => {
    setSubmitting(true);

    window.Email.send({
      Secure: true,
      Port: 2525,
      SecureToken: "D91ED147E48901B8772326CC2A1CE08DDA9E",
      Host: "smtp.elasticemail.com",
      To: "webcomplaints@salaamlifetakaful.com",
      Username: "webcomplaints@salaamlifetakaful.com",
      Password: "D91ED147E48901B8772326CC2A1CE08DDA9E",
      From: "webcomplaints@salaamlifetakaful.com",
      Subject: "New Complaint Submission",
      Body: `
        <h2>New Complaint Submitted</h2>
        <p><strong>Name:</strong> ${values.name}</p>
        <p><strong>Email:</strong> ${values.email}</p>
        <p><strong>Phone:</strong> ${values.phone}</p>
        <p><strong>Complaint:</strong> ${values.complain}</p>
      `,
    }).then(
      (messages) => {
        console.log("Mail sent successfully:", messages);
        message.success("Form submitted successfully.");
        form.resetFields();
        setIsSuccessMessageVisible(true);
        setSubmitting(false);
      },
      (error) => {
        console.error("Failed to send email:", error);
        message.error("Failed to submit the form. Please try again.");
        setSubmitting(false);
      }
    );
  };
  useEffect(() => {
    if (!window.Email) {
      console.error("SMTP.js is not loaded.");
      return;
    }
    console.log("SMTP.js loaded successfully");
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (isSuccessMessageVisible) {
        setIsSuccessMessageVisible(false);
      }
    }, 5000);
  }, [isSuccessMessageVisible]);
  return (
    <Fragment>
      <div className="sub complainforms">
        <div className="row">
          <div className="col-sm-6">
            <Fade up delay={300}>
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/phones.png`}
                alt="phone"
              />
            </Fade>
          </div>

          <div className="col-sm-6 formstyle">
            <Fade down delay={300}>
              <h2>COMPLAINT BOX</h2>
            </Fade>

            <Form
              form={form}
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Fade down delay={500}>
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              <Fade down delay={900}>
                <Form.Item name="phone">
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item name="complain">
                  <Input.TextArea
                    placeholder="Complaint"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>

              <Fade down delay={1300}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit
                  </Button>
                </Form.Item>
              </Fade>
            </Form>

            {isSuccessMessageVisible && (
              <Fade down delay={300}>
                <div style={{ marginTop: "10px" }}>
                  <p className="message">Form submitted successfully.</p>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Complainform;
