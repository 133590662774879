import React, { Fragment } from "react";
import Company from "../Components/CompanyDetails/CompanyContent";
import ComapnyBanner from "../Components/CompanyDetails/CompanyBanner";
// import CompanyAccordion from "../Components/CompanyDetails/CompanyAccordion";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function CompanyDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          Company Detail | Salaam Life and Savings | Salaam Family Takaful
        </title>
        <meta
          name="description"
          content="company incorporation date and number,status of the company"
        />
        <meta
          name="keywords"
          content="Salaam life company details,information"
        />
      </Helmet>
      <ComapnyBanner />
      <div className="container policyPage companyProfile">
        <div className="row compDetaaailsssssssss">
          <Company />
          {/* <CompanyAccordion /> */}
        </div>
      </div>
    </Fragment>
  );
}

export default CompanyDetails;
