import React, { useState } from "react";
import { Alert, Button, InputNumber, Select } from "antd";

import { DeleteFilled } from "@ant-design/icons";

function Fifth() {
  const [selectedValues, setSelectedValues] = useState([1]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [array, setArray] = useState([1]);
  const { Option } = Select;
  const handleAddRelation = () => {
    if (array.length < 7) {
      setArray([...array, array.length + 1]);
      setAlertVisible(false);
    } else {
      setAlertVisible(true);
    }
  };

  const relation = [
    "Father",
    "Mother",
    "Son",
    "Daughter",
    "Grandfather",
    "Grandmother",
    "Grandson",
    "Granddaughter",
    "Uncle",
    "Aunt",
    "Nephew",
    "Niece",
    "Brother",
    "Sister",
    "Cousin",
    "Stepfather",
    "Stepmother",
    "Stepson",
    "Stepdaughter",
    "In-laws",
  ];

  const handleDelete = (value) => {
    setArray(array.filter((item) => item !== value));
    setAlertVisible(false);
  };

  return (
    <div className="modalForm container relationModal">
      <div className="col-sm-12">
        <h4>Please add your family history</h4>
      </div>
      <div className="col-sm-12 modalbtn">
        <Button onClick={handleAddRelation}>Add More Retaltions</Button>
      </div>
      <div className="row">
        {selectedValues.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Relationship</th>
                <th> Age</th>
                <th>Status of health</th>

                <th>Year of death (If died) </th>
                <th>Age at death </th>
                <th>Cause of death</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {array.map((option, index) => (
                <tr key={option}>
                  <td>
                    <Select placeholder="Relation Ship">
                      {relation.map((option) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <InputNumber placeholder="age" />
                  </td>

                  <td>
                    <Select placeholder="Status">
                      <Option value="good">Good</Option>
                      <Option value="Fair">Fair</Option>
                      <Option value="poor">Poor</Option>
                    </Select>
                  </td>
                  <td>
                    <InputNumber />
                  </td>

                  <td>
                    <InputNumber placeholder="Age" />
                  </td>
                  <td>
                    <Select placeholder="Cause ">
                      <Option value="Natural">Natural</Option>
                      <Option value="Accidental">Accidental</Option>
                      <Option value="Disease">Disease</Option>
                    </Select>
                  </td>
                  <td>
                    <DeleteFilled
                      onClick={() => handleDelete(option)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {alertVisible && (
          <Alert
            message="Yoy Have Reached The Maximum limit of 7 Rows"
            type="warning"
            showIcon
            closable
          />
        )}
      </div>
    </div>
  );
}

export default Fifth;
