import React, { Fragment } from "react";
import ProductBanner from "../Components/Groupprods/ProductBanner";
import { Helmet } from "react-helmet";

function GroupProduct() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Salaam Group Life Takaful Plan | Salaam Life and Savings | Islamic
          Life Insurance
        </title>
        <meta
          name="description"
          content="Your employees are your prized assets. For any company to secure high returns, it is paramount that it takes care of them – because they give their best day in and day out. Let their dreams be important for you because this will reap rich dividends for your organization. Give them the plan that takes care of all their family takaful needs, hopes and aspirations"
        />
        <meta
          name="keywords"
          content="Group Life Takaful,Salaam Life group plans,Salaam Family Takaful Group plans,Corporate Plans"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <ProductBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default GroupProduct;
