import React, { Fragment, useEffect, useState, useRef } from "react";
import Fade from "react-reveal/Fade";
import Whotext from "./Whotext";
import Vision from "./Vision";
import CeoMessages from "./CeoMessages";
import Muftisajjad from "./Muftisajjad";
import CodeofConduct from "./CodeofConduct";
import { Helmet } from "react-helmet";

function Whobanner() {
  const [currentSection, setCurrentSection] = useState("");

  const sectionsRefs = useRef({});

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the container
      threshold: 0.5, // Trigger when 50% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          setCurrentSection(sectionId);
        }
      });
    }, options);

    const sections = document.querySelectorAll(".scroll-section");
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      // Cleanup the observer when the component unmounts
      observer.disconnect();
    };
  }, []);

  const getMetaData = (section) => {
    switch (section) {
      case "mission-vision":
        return {
          title:
            "Vision & Mission | Salaam Life and Savings | Islamic Life Insurance",
          description:
            "To be the ultimate provider of halal financial benefits and life takaful for every segment of society, anytime, anywhere.",
          keywords:
            "salaam life takaful, Salaam Life & Savings, Who we are, Vision Mission",
        };
      case "ceo":
        return {
          title:
            "CEO's Message | Salaam Life and Savings | Salaam Family Takaful",
          description:
            "I welcome you all to Salaam Life and Savings. Experience the first-ever digital Islamic insurance provider across the globe.",
          keywords: "Salaam Life and Savings CEO, Message, Salaam Life Takaful",
        };
      case "coc":
        return {
          title:
            "Code Of Conduct | Salaam Life and Savings | Islamic Life Insurance",
          description: "Salaam Life & Savings Policies, Code of conduct",
          keywords:
            "rules, regulations, salaam Life and savings Code of conduct, Salaam Family Takaful Limited",
        };
      default:
        return {
          title:
            "Salaam Life and Savings | Islamic Life Insurance | Salaam Family Takaful",
          description:
            "Learn more about who we are and our commitment to providing ethical and Sharia-compliant insurance solutions.",
          keywords:
            "salaam life takaful, Islamic life insurance, Sharia-compliant insurance",
        };
    }
  };

  const metaData = getMetaData(currentSection);

  return (
    <Fragment>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
      </Helmet>
      <div className="container governancemain motor">
        <div className="row">
          <Fade up delay={500}>
            <div
              className="col-sm-12 heads govbanner scroll-section"
              id="mission-vision"
              ref={(el) => (sectionsRefs.current["mission-vision"] = el)}
            >
              <h2 className="oswald">About Us</h2>
            </div>
          </Fade>
          <div className="col-sm-12 heads adds scroll-section">
            <Vision />
          </div>

          <div
            className="col-sm-12 heads text shareholder scroll-section"
            id="ceo"
            ref={(el) => (sectionsRefs.current["ceo"] = el)}
          >
            <CeoMessages />
          </div>

          <div className="col-sm-12 heads adds firstpart scroll-section">
            <Whotext />
          </div>

          <div
            className="col-sm-12 heads text shareholder scroll-section"
            id="muftisajjad"
            ref={(el) => (sectionsRefs.current["muftisajjad"] = el)}
          >
            <Muftisajjad />
          </div>

          <div
            className="col-sm-12 heads text retaakaful scroll-section"
            id="coc"
            ref={(el) => (sectionsRefs.current["coc"] = el)}
          >
            <CodeofConduct />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Whobanner;
