import React from "react";
import { Form, Input } from "antd";
import { Flip } from "react-reveal";
import Profile from "../Main/Profile";

const NomineeStatus = () => {
  const handleSubmit = () => {};

  return (
    <div className="container">
      <Profile />
      <Form onFinish={handleSubmit}>
        <div className="row withDrawl claimForm NomineeForm">
          <div className="col-sm-12">
            <Form.Item>
              <Input disabled defaultValue="Policy #" />
            </Form.Item>
          </div>

          <div className="col-sm-12">
            <Form.Item>
              <Input placeholder="Money Market Fund" />
            </Form.Item>
          </div>

          <div
            className="col-sm-12
        "
          >
            <Form.Item>
              <Input placeholder="ABC Funds" maxLength={15} />
            </Form.Item>
          </div>

          <div
            className="col-sm-12
        "
          >
            <Form.Item>
              <Input placeholder={"XYZ Funds"} />
            </Form.Item>
          </div>

          <div
            className="col-sm-12
        "
          >
            <Form.Item>
              <Input disabled defaultValue="Total Amount" />
            </Form.Item>
          </div>
        </div>
        <div className="col-sm-12 btnSection1 btnSection">
          <Flip top delay={800}>
            <button>Proceed to Payment</button>
          </Flip>
        </div>
      </Form>
    </div>
  );
};

export default NomineeStatus;
