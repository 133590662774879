import React from "react";

const UnitPrice = () => {
  return (
    <div className="row unitPrice">
      <h2> Current Unit Price </h2>
      <div className="col-sm-3">
        <div>
          <p>Aggressive</p>
        </div>
        <div>
          <p>1050.00</p>
        </div>
      </div>
      <div className="col-sm-3">
        <div>
          <p>Balanced</p>
        </div>
        <div>
          <p>1350.00</p>
        </div>
      </div>
      <div className="col-sm-3">
        <div>
          <p>Conservative</p>
        </div>
        <div>
          <p>1050.00</p>
        </div>
      </div>
      <div className="col-sm-3">
        <div>
          <p>Secure Wealth Funds</p>
        </div>
        <div>
          <p>2150.00</p>
        </div>
      </div>
    </div>
  );
};

export default UnitPrice;
