import React from "react";
import { Checkbox, Form, Input } from "antd";
import { Flip } from "react-reveal";
import BankHeader from "./BankDetailsHeader";
import { useNavigate } from "react-router-dom";

const BanksForm = () => {
  const navigate = useNavigate();
  const handleFinish = (values) => {
    console.log(values);
    navigate("/dashboard/bank-uploads");
  };

  return (
    <div className="container">
      <BankHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
          <Flip top delay={300}>
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Account Holder Name is Required ",
                  },
                ]}
                name="Account Holder Name"
              >
                <Input placeholder="Account Holder Name" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Bank Name is Required ",
                  },
                ]}
                name="Bank Name"
              >
                <Input placeholder="Bank Name" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-3">
                  {" "}
                  <p>Account Type</p>
                </div>
                <div className="col-sm-3">
                  <Checkbox>
                    <h6> Current </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-3">
                  {" "}
                  <Checkbox>
                    <h6>Saving </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-3">
                  {" "}
                  <Checkbox>
                    <h6>Default </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={900}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Account No. / IBFT is Required ",
                },
              ]}
              name="Account No. / IBFT"
            >
              <Input placeholder="Account No. / IBFT" />
            </Form.Item>
          </Flip>

          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button type="submit" className="dashboardBn">
                Next
              </button>
            </div>
          </Flip>
        </div>
      </Form>
    </div>
  );
};

export default BanksForm;
