import React, { Fragment } from "react";

const ClaimStatusHeader = () => {
  return (
    <Fragment>
      <div className="row profile status">
        <div className="col-sm-12">
          <h6>Type of Claim</h6>
          <h2>Mr. Jameel Ahmed Siddiqui</h2>
        </div>
        <div className="col-sm-12">
          <h6>Date of Event</h6>
          <h3>23 Oct,2023</h3>
        </div>
        <div className="col-sm-12">
          <h6>Cause of Event</h6>
          <h3>Accident</h3>
        </div>
        <div className="col-sm-12">
          <h6>Remarks</h6>
          <h3>Process</h3>
        </div>
        <div className="col-sm-12">
          <h6>Remarks</h6>
          <h4>Submitted</h4>
        </div>
      </div>
    </Fragment>
  );
};

export default ClaimStatusHeader;
