import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FMRTable from "./FMRTable";

function FMRBanner() {
  return (
    <Fragment>
      <div className="container tabss motor whistle">
        <div className="row">
          <div className="col-sm-12 heads govbanner">
            <Fade up delay={500}>
              <h2 className="oswald">Fund Manager Reports</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text mymaxheight complainbox whistle fundtable">
            <FMRTable />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FMRBanner;
