import React, { useState, Fragment, useEffect } from "react";
import Flip from "react-reveal/Flip";
import SubStep10 from "./SubSteps/SubStep10";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import EditStep10 from "./SubSteps/EditStep10";

function Step10() {
  const [beneficeryLength, setBeneficeryLength] = useState(1);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [shareCount, setShareCount] = useState();
  const [currentBeneficiaryIndex, setCurrentBeneficiaryIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [editData, setEditData] = useState(false);
  const [disableAdd, setDisableAdd] = useState(true);
  const handleAddBeneficiary = (values) => {
    const updatedIndex = beneficiaries.findIndex(
      (beneficiary) => beneficiary.index === values.index
    );

    if (updatedIndex !== -1) {
      beneficiaries[updatedIndex] = values;
      setBeneficiaries([...beneficiaries]);
    } else {
      setBeneficiaries([...beneficiaries, values]);
    }
    setCurrentBeneficiaryIndex(currentBeneficiaryIndex + 1);
  };

  const showModal = (elem) => {
    setIsModalOpen(true);
    setEditData(elem);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let sumOfShare = 0;

    for (const item of beneficiaries) {
      if (item.share && !isNaN(item.share)) {
        sumOfShare += parseFloat(item.share);
        setShareCount(sumOfShare);
      }
    }
  }, [beneficiaries]);
  const handleDelete = (data) => {
    setBeneficiaries(beneficiaries.filter((elem) => elem !== data));
  };
  const [editingIndex, setEditingIndex] = useState(null);

  const handleEdit = (index) => {
    showModal();
    // Set the index of the beneficiary being edited
    setEditingIndex(index);
  };

  const handleUpdate = (index, updatedData) => {
    // Update the beneficiary data in the state
    setBeneficiaries((prev) =>
      prev.map((beneficiary, i) =>
        i === index ? { ...beneficiary, ...updatedData } : beneficiary
      )
    );

    // Clear the editing index
    setEditingIndex(null);
  };
  const handleAddBeneficery = () => {
    // If no errors, proceed with form submission

    setBeneficeryLength(beneficeryLength + 1);
    setDisableAdd(true);
  };

  return (
    <Fragment>
      {currentBeneficiaryIndex < beneficeryLength && (
        <SubStep10
          key={currentBeneficiaryIndex}
          index={currentBeneficiaryIndex}
          onSubmit={handleAddBeneficiary}
          shareCount={shareCount}
          setDisableBtn={setDisableBtn}
          setBeneficiaries={setBeneficiaries}
          beneficeryLength={beneficeryLength}
          setBeneficeryLength={setBeneficeryLength}
          beneficiaries={beneficiaries}
          setDisableAdd={setDisableAdd}
          disableAdd={disableAdd}
        />
      )}
      <div
        className="col-sm-6 step6btn"
        style={{ textAlign: "end", margin: "20px 0px" }}
      >
        <Button onClick={handleAddBeneficery} disabled={disableAdd}>
          Add Another Beneficery
        </Button>
      </div>

      <Flip top delay={600}>
        {beneficiaries.length > 0 ? (
          <div className="col-sm-12 contributionTable">
            <table>
              <thead>
                <tr>
                  <th>Beneficery Name</th>
                  <th>Beneficery CNIC</th>
                  <th>Beneficery Age</th>
                  <th>Relation</th>
                  <th>Guardian</th>
                  <th>Share</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {beneficiaries.map((elem, index) => (
                  <tr key={index}>
                    <td>{elem.beneficeryName}</td>
                    <td>{elem.beneficerCNIC}</td>
                    <td>{elem.age}</td>
                    <td>{elem.relation} </td>
                    <td>{elem.guardianName}</td>
                    <td>{elem.share}</td>
                    <td>
                      <EditOutlined onClick={() => handleEdit(index)} />
                      <DeleteOutlined onClick={() => handleDelete(elem)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </Flip>

      <Modal
        title="Basic Modal"
        width={1000}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {beneficiaries.map((beneficiary, index) => (
          <div key={beneficiary.id} className="editModal">
            {index === editingIndex ? (
              <EditStep10
                index={index}
                data={beneficiary}
                onUpdate={(updatedData) => handleUpdate(index, updatedData)}
                handleCancel={handleCancel}
              />
            ) : null}
          </div>
        ))}
      </Modal>
    </Fragment>
  );
}

export default Step10;
