import React from 'react'
import { Fragment } from 'react'

function Esgtext() {
    return (
        <Fragment>
            <div className='sub shariahcom esg'>

                <h2 >Sustainability / ESG </h2>
                <p >Salaam Family Takaful Limited, being the largest dedicated life takaful operator and first Islamic insurtech in Pakistan, is committed to serve not only its policyholders but also the world we operate in. That’s the reason we have been one of the most vocal supporters of ESG and its true practitioners in Pakistan. Our main goal under our ESG Policy is “Financial Inclusion”, which is mentioned in 7 of the UN SDGs. All our efforts and products are aligned to these 7 goals.
                </p>


            </div>
        </Fragment>
    )
}

export default Esgtext