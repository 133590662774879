import React from "react";
import Profile from "../Main/Profile";

import PolicyTable from "./PolicyTable";
import Flip from "react-reveal/Flip";

const Policy = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl">
        <Flip top delay={400}>
          <div className="col-sm-12 ">
            <PolicyTable />
          </div>
        </Flip>
        <Flip top delay={200}>
          <div className="col-sm-4 ">
            <button className="dashboardBn">Funds Performance Report</button>
          </div>
        </Flip>
        <Flip top delay={400}>
          <div className="col-sm-4 ">
            <button className="dashboardBn">Unit Report</button>
          </div>
        </Flip>
        <Flip top delay={600}>
          <div className="col-sm-4 ">
            <button className="dashboardBn">Fund Switch</button>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default Policy;
