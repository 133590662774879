import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { News } from "../../Data/Data";
import Fade from "react-reveal/Fade";
import Recentnews from "./Recentnews";

function SingleNews() {
  const { key } = useParams();
  console.log(key);

  // const newsItem = News.find((data) => convertToSlug(data.heading) === heading);
  const newsItem = News.find((data) => data.key.toString() === key);


  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row">
          <div className="col-sm-12 heads govbanner">
            <Fade up delay={500}>
              <h2 className="oswald">News & Events</h2>
            </Fade>
          </div>
        </div>

        <div className="row singleblog">
          <div className="col-sm-9 singlenews">
            {newsItem && (
              <div className="container-fluid news" key={newsItem.heading}>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 heads">
                      <Fade left delay={500}>
                        <img src={newsItem.feature} alt="img" className="BigImage" />
                      </Fade>
                      <Fade left delay={500}>
                        <h2 className="oswald">{newsItem.heading}</h2>
                      </Fade>
                      {/* <p className="date"><b>Date</b>: {newsItem.Date}</p> */}
                    </div>
                    <div className="col-sm-12 heads text newsheight">
                      {newsItem?.content ? 
                        <Fade left delay={1000}>
                          <p>{newsItem?.content}</p> 
                        </Fade>
                      : <></>}
                      {newsItem?.content1 ? 
                        <Fade left delay={1500}>
                          <p>{newsItem?.content1}</p> 
                        </Fade>
                      : <></>}
                      {newsItem?.content2 ? 
                        <Fade left delay={2000}>
                          <p>{newsItem?.content2}</p> 
                        </Fade>
                      : <></>}
                    </div>
                    {newsItem?.links ? (
                      <ul>
                        {newsItem?.links?.map((item) => (
                          <li className="liissttt">
                            <Fade left delay={2500}>
                              <img src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`} />
                              <a href={item} target="_blank">{item}{" "}</a>
                            </Fade>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-3">
            <Recentnews />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleNews;
