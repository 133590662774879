import React from "react";
import { Form, Input } from "antd";
import ClaimHeader from "./ClaimHeader";
import { Flip } from "react-reveal";

const ClaimForm = () => {
  return (
    <div className="container">
      <ClaimHeader />
      <div className="row withDrawl claimForm NomineeForm">
        <Form>
          {" "}
          <div className="col-sm-12 ">
            <Form.Item
              name="CNIC"
              rules={[{ required: true, message: "Cnic is Required" }]}
            >
              <Input placeholder="CNIC" />
            </Form.Item>
          </div>
          <div className="col-sm-12 ">
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Name is Required" }]}
            >
              <Input placeholder="Intimation Name" />
            </Form.Item>
          </div>
          <Flip top delay={200}>
            <div className="col-sm-12 verifyBtn">
              <button className="dashboardBn">Proceed</button>
            </div>
          </Flip>
        </Form>
      </div>
    </div>
  );
};

export default ClaimForm;
