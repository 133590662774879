import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Esgtext from './Esgtext';
import Esgtabs from './Esgtabs';


function EsgBnanner() {
    return (
        <Fragment>



            <div className='container tabss motor bgwhite' >
                <div className='row'>

                    <div className='col-sm-12 heads text mymaxheight'>
                        <Esgtext />
                    </div>

                    <div className='col-sm-12 heads text mymaxheight bot'>
                        <Esgtabs />
                    </div>


                </div>
            </div>
        </Fragment>
    )
}

export default EsgBnanner