import React from "react";
import Profile from "../Main/Profile";
import { Button, Checkbox, Form, Input } from "antd";
import WithDrawlTable from "./WithDrawlTable";
import Flip from "react-reveal/Flip";

const WithDrawlPermanent = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl ">
        <div className="withdrawlLine">
          <Flip top delay={400}>
            <div className="innerLine">
              <p>Select Withdrawl Type</p>
              <Checkbox>
                <h6> Permanent </h6>
              </Checkbox>
              <Checkbox>
                <h6> Partial </h6>
              </Checkbox>
              <Checkbox>
                <h6> OPD </h6>
              </Checkbox>
            </div>
          </Flip>
        </div>
        <div className="col-sm-6 disableInput">
          <Form.Item>
            <Input disabled defaultValue={"Surrender Charges"} />
          </Form.Item>
        </div>
        <div className="col-sm-6 disableInput">
          <Form.Item>
            <Input disabled defaultValue={"Amount"} />
          </Form.Item>
        </div>

        <Flip top delay={600}>
          <WithDrawlTable />
        </Flip>

        <div className="col-sm-12 btnSection">
          <Flip top delay={800}>
            <button>Proceed</button>
          </Flip>
        </div>
        <div className="col-sm-12 changeMind">
          <div className="row">
            <div className="col-sm-12">
              <p>
                Considering the current investment yields and your future plans
                we suggest not to withdrawl at this stage
              </p>
            </div>
            <div className="col-sm-4">
              <Button>Change My Mind</Button>
            </div>
            <div className="col-sm-4">
              <Button>I Want to proceed</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithDrawlPermanent;
