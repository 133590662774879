import React from "react";
import { Checkbox, InputNumber, Tag } from "antd";

import { DeleteFilled } from "@ant-design/icons";
function DiseaseModal({
  setSelectedValues,
  selectedValues,
  amountValues,
  setAmountValues,
  yearValues,
  setYearValues,
  contribution,
  plan,
}) {
  const checkboxOptions = [
    "Takaful Accidental Death Benefit",
    "Takaful Accidental Death Disability Benefit",
    "Takaful Waiver Contribution Benefit",
    "Permanent Total Disabilty-Accident",
  ];

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleDelete = (value) => {
    setSelectedValues(selectedValues.filter((item) => item !== value));
  };
  const handleInputChange = (index, fieldName, value) => {
    let formattedValue = value;

    // Remove existing commas from the input value
    formattedValue = formattedValue?.toString().replace(/,/g, "");

    // Format the value with commas
    formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (fieldName === "yearValue") {
      const newYearValues = [...yearValues];
      newYearValues[index] = value;
      setYearValues(newYearValues);
    } else if (fieldName === "amountValue") {
      const newAmountValues = [...amountValues];
      newAmountValues[index] = formattedValue;
      setAmountValues(newAmountValues);
    }
  };

  return (
    <div className="modalForm container">
      <div className="col-sm-12">
        <h4>Please select your Supplementary Benefit (Rider)</h4>
      </div>

      <div className="row">
        {checkboxOptions.map((option) => (
          <div
            className={`col-sm-3 benefitBox ${
              selectedValues.includes(option) ? "greenDiv" : ""
            } `}
          >
            <Checkbox
              key={option}
              checked={selectedValues.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            >
              <p> {option}</p>
            </Checkbox>
          </div>
        ))}
      </div>

      {selectedValues.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Rider</th>
              <th>Term (Years)</th>
              <th>Sum Assured</th>
              <th>Delete </th>
            </tr>
          </thead>
          <tbody>
            {selectedValues.map((option, index) => (
              <tr key={option}>
                <td>
                  <Tag color="blue">{option}</Tag>
                </td>
                <td>
                  <InputNumber
                    defaultValue={plan}
                    placeholder="Year 5"
                    value={yearValues[index]}
                    onChange={(value) =>
                      handleInputChange(index, "yearValue", value)
                    }
                  />
                </td>
                <td>
                  <InputNumber
                    placeholder="100,000"
                    defaultValue={contribution}
                    value={amountValues[index]}
                    onChange={(value) =>
                      handleInputChange(index, "amountValue", value)
                    }
                  />
                </td>
                <td>
                  <DeleteFilled
                    onClick={() => handleDelete(option)}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default DiseaseModal;
