import React from "react";
import { Checkbox, DatePicker, Form, Input } from "antd";

import { useNavigate } from "react-router-dom";
import BasicInfoHeader from "./BasicInfoHeader";
import TextArea from "antd/es/input/TextArea";

const BasicInformation = () => {
  const navigate = useNavigate();

  const handleFinish = (values) => {
    console.log(values);
    navigate("/dashboard/bank-details");
  };

  return (
    <div className="container">
      <BasicInfoHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">

            <div className="col-sm-12">
              <Form.Item
                rules={[{ required: true, message: "Membership is Required " }]}
                name="Membership"
              >
                <Input placeholder="Membership #" />
              </Form.Item>
            </div>
        
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Name of Participant is Required ",
                  },
                ]}
                name="parrticipant"
              >
                <Input placeholder="Name of Participant" />
              </Form.Item>
            </div>
        
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-4">
                  {" "}
                  <p>Basic under which claim is being filed</p>
                </div>
                <div className="col-sm-2">
                  <Checkbox>
                    <h6> Critical Illness </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-3">
                  {" "}
                  <Checkbox>
                    <h6>Accidental Disability </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-3">
                  {" "}
                  <Checkbox>
                    <h6>Natural Disability </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
        
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[{ required: true, message: "Sum Covered is required" }]}
                name="covered"
              >
                <Input placeholder="Sum Covered" />
              </Form.Item>
            </div>
       
            <div className=" col-sm-6">
              <div className="statement row">
                <div className="col-sm-4">
                  {" "}
                  <p>Type of Events</p>
                </div>

                <div className="col-sm-4">
                  {" "}
                  <Checkbox>
                    <h6>Natural Sickness </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <Checkbox>
                    <h6>Accident </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
       
            <div
              className="col-sm-3
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Date of Event is required" },
                ]}
                name="doe"
              >
                <DatePicker placeholder="Date of Event" />
              </Form.Item>
            </div>
   
            <div
              className="col-sm-3
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Place of Event is required" },
                ]}
                name="poe"
              >
                <DatePicker placeholder="Place of Event" />
              </Form.Item>
            </div>
     
            <div
              className="col-sm-6
        "
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message:
                      "Date first treated for the accident or illness is required",
                  },
                ]}
                name="doef"
              >
                <DatePicker placeholder="Date first treated for the accident or illness" />
              </Form.Item>
            </div>
     
            <div
              className="col-sm-12
        "
            >
              <Form.Item
                rules={[
                  { required: true, message: "Contact Number is required" },
                ]}
                name="number"
              >
                <TextArea
                  rows={8}
                  placeholder="Please describe how and where the disability or accident occured"
                />
              </Form.Item>
            </div>
      
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-6">
                  {" "}
                  <p>Have you ever had the same or similar condition in past</p>
                </div>

                <div className="col-sm-2">
                  {" "}
                  <Checkbox>
                    <h6>Yes </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-2">
                  {" "}
                  <Checkbox>
                    <h6>No </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
      
            <div
              className="col-sm-12
        "
            >
              <Form.Item>
                <TextArea rows={6} placeholder="If yes, please add details" />
              </Form.Item>
            </div>
      
            <div
              className="col-sm-4
        "
            >
              <div className="statement row">
                <div className="col-sm-4">
                  {" "}
                  <p>Treated by </p>
                </div>

                <div className="col-sm-4">
                  {" "}
                  <Checkbox>
                    <h6>Doctor</h6>
                  </Checkbox>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <Checkbox>
                    <h6>Hospital </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
        
            <div className="col-sm-4">
              <Form.Item
                rules={[{ required: true, message: "Name is required" }]}
                name="Name"
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
   
            <div className="col-sm-3">
              <Form.Item
                rules={[{ required: true, message: "Address is required" }]}
                name="address"
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>
        
            <div className="col-sm-3">
              <Form.Item>
                <DatePicker placeholder="If Hospital, Date of Admission" />
              </Form.Item>
            </div>
       
            <div className="col-sm-2">
              <Form.Item
                rules={[
                  { required: true, message: "Date of discharge is required" },
                ]}
                name="discharge"
              >
                <DatePicker placeholder="Discharge Date" />
              </Form.Item>
            </div>
        
            <div className="col-sm-3">
              <Form.Item
                rules={[
                  { required: true, message: "Date Last attended is required" },
                ]}
                name="attended"
              >
                <DatePicker placeholder="Date Last attended workplace" />
              </Form.Item>
            </div>

            <div className="col-sm-3">
              <Form.Item
                rules={[
                  { required: true, message: "Date of rejoining is required" },
                ]}
                name="rejoining"
              >
                <DatePicker placeholder="Date of rejoining workplace" />
              </Form.Item>
            </div>
       
            <div className="col-sm-12 verifyBtn">
              <button type="submit" className="dashboardBn">
                Next
              </button>
            </div>
          
        </div>
      </Form>
    </div>
  );
};

export default BasicInformation;
