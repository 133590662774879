import React, { Fragment } from "react";
import FMRBanner from "../Components/FMR/FMRBanner";
import { Helmet } from "react-helmet";

function FMR() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Fund Manager Report | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="The fund aims to deliver high liquidity and competitive returns for investors while safeguarding their capital. It achieves this by focusing on low-risk,
Shariah-compliant fixed-income instruments with shorter durations."
        />
        <meta
          name="keywords"
          content="Fund Manager Report , Investor Relations,Salaam Life ,Salaam Family Takaful"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <FMRBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default FMR;
