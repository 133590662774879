import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";
import { videos } from "../../Data/Data";

function Takafulvideos() {
    return (
        <Fragment>
            <div className="container governancemain motor nnewwBAnnner">
                <div className="row">
                    <Fade up delay={500}>
                        <div className="col-sm-12 heads govbanner" id="mission&vision">
                            <h2 className="oswald">Media Center</h2>
                        </div>
                    </Fade>

                    <div className="col-sm-12 mediaCenterHeading" id="#takaful-videos">
                        <h2>Takaful Videos</h2>
                    </div>

                    {videos.map((elem, ind) => (
                        <div className='col-sm-4 vidostak' key={elem.key}>
                            <video width="100%" height="100%" controls>
                                <source src={elem.link} type="video/mp4" />
                            </video>
                            <h4>{elem.title}</h4>
                        </div>
                    ))}

                </div>
            </div>
        </Fragment>
    )
}

export default Takafulvideos