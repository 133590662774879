import React from "react";
import { Checkbox, Form, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import NomineeStatusHeader from "./NomineeStatusHeader";

const NomineeStatus = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isSettled, setisSetteled] = useState(false);
  const [modalText, setModalText] = useState(
    "YOU WILL RECEIVE CLAIM AMOUNT THROUGH IBFT/CHEQUE WITHIN SEVEN WORKING DAYS THANKS "
  );

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      navigate("/dashboard");
    }, 2000);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const handleSubmit = () => {};

  return (
    <div className="container">
      <NomineeStatusHeader />
      <Form onFinish={handleSubmit}>
        <div className="row withDrawl claimForm NomineeForm">
          <div className="col-sm-12">
            <Form.Item>
              <Input disabled defaultValue="Claim No." />
            </Form.Item>
          </div>

          <div className="col-sm-12">
            <Form.Item>
              <Input disabled defaultValue="Policy #" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue="Life Assured Name" maxLength={15} />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue={"Nominee Name"} />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue="Claim Type" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input defaultValue="Date of Event" disabled />
            </Form.Item>
          </div>

          <div className="col-sm-3 verifyBtn">
            <button
              onClick={() => setisSetteled(true)}
              className="dashboardBn"
              style={{
                backgroundColor: "transparent",
                color: "rgba(168, 168, 168, 1)",
                border: "1px solid rgba(168, 168, 168, 1)",
              }}
            >
              Processed
            </button>
          </div>

          <div className="col-sm-3 verifyBtn">
            <button className="dashboardBn">Settled</button>
          </div>

          <div className="col-sm-3 verifyBtn">
            <button
              className="dashboardBn"
              style={{
                backgroundColor: "transparent",
                color: "rgba(168, 168, 168, 1)",
                border: "1px solid rgba(168, 168, 168, 1)",
              }}
            >
              Requirment
            </button>
          </div>

          <div className="col-sm-3 verifyBtn">
            <button
              className="dashboardBn"
              onClick={() => setisSetteled(false)}
              style={{
                backgroundColor: "transparent",
                color: "rgba(168, 168, 168, 1)",
                border: "1px solid rgba(168, 168, 168, 1)",
              }}
            >
              Declined
            </button>
          </div>

          {isSettled ? (
            <>
              <div className="col-sm-12">
                <Form.Item
                  name="bankname"
                  rules={[{ required: true, message: "Bank Name is Required" }]}
                >
                  <Input placeholder="Bank Name" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="banktitle"
                  rules={[
                    { required: true, message: "Account Title is Required" },
                  ]}
                >
                  <Input placeholder="Account Title" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item
                  name="acc"
                  rules={[
                    { required: true, message: "Account Number is Required" },
                  ]}
                >
                  <Input placeholder="Account Number" />
                </Form.Item>
              </div>

              <div
                className="col-sm-12
        "
              >
                <Form.Item
                  name="Genders"
                  rules={[
                    { required: true, message: "This Field is Required" },
                  ]}
                >
                  <Checkbox>
                    <span>
                      Declaration: I hereby confirm that the above stated
                      information is acceptable for claim settlement.
                    </span>
                  </Checkbox>
                </Form.Item>
              </div>

              <div className="col-sm-12 verifyBtn">
                <button
                  onClick={showModal}
                  type="submit"
                  className="dashboardBn"
                >
                  Agree
                </button>
              </div>
            </>
          ) : (
            <> </>
          )}
        </div>
      </Form>

      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <h6>{modalText}</h6>
      </Modal>
    </div>
  );
};

export default NomineeStatus;
