import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

function InvestorRelation({ show, setshow }) {
  return (
    <div className="subs">
      <div>
        <div className="container submenus">
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Funds</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link
                      to="/fund-prices"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {" "}
                      Funds Prices{" "}
                    </Link>
                  </li>
                  {/* <li> PMD </li> */}
                  <li>
                    {" "}
                    <Link
                      to="/past-prices"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {" "}
                      Past Prices{" "}
                    </Link>
                  </li>
                  {/* <li>
                    {" "}
                    <Link
                      to="/fund-prices"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {" "}
                      Funds Performance{" "}
                    </Link>
                  </li> */}
                  <li>
                    {" "}
                    <Link
                      to="/fmr-report"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {" "}
                      Fund Manager Reports{" "}
                    </Link>{" "}
                  </li>
                  {/* <li> How to buy online </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Financial Statements</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {/* <li> Financial Reports (PDF)</li> */}
                  <li>Coming Soon</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default InvestorRelation;
