import React from "react";
import { Checkbox, DatePicker, Form, Input, Modal } from "antd";
import { Flip } from "react-reveal";
import NomineeHeader from "./StatementHeader";
import { useState } from "react";

const StatementForm = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Given Information Verified Successfully"
  );

  const handleFinish = (values) => {
    console.log(values);
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div className="container">
      <NomineeHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm ">
          <Flip top delay={400}>
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-6">
                  {" "}
                  <p>
                    Was the deceased covered for Takaful/Insurance from any
                    other company
                  </p>
                </div>
                <div className="col-sm-3">
                  <Checkbox>
                    <h6> Yes </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-2">
                  {" "}
                  <Checkbox>
                    <h6>No </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
          </Flip>

          <Flip top delay={300}>
            <div className="col-sm-4">
              <Form.Item name="Name">
                <Input placeholder="Membership #" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="col-sm-2">
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Issue Date is Required" }]}
              >
                <DatePicker placeholder="Date of issue" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className="col-sm-6">
              <Form.Item name="cnic">
                <Input placeholder="Company Name & Address" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={900}>
            <div className="col-sm-12 addBtn">
              <button className="dashboardBn dashboardBn1">Add Another</button>
            </div>
          </Flip>

          <Flip top delay={1100}>
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-3">
                  {" "}
                  <p>Type of event</p>
                </div>
                <div className="col-sm-3">
                  <Checkbox>
                    <h6> Natural </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-3">
                  {" "}
                  <Checkbox>
                    <h6>Accident </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div className="col-sm-4">
              <Form.Item name="event">
                {" "}
                <DatePicker placeholder="Date of Event" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={1500}>
            <div className="col-sm-4">
              <Form.Item
                name="place"
                rules={[
                  { required: true, message: "Place Of Event is required" },
                ]}
              >
                {" "}
                <Input placeholder="Place of Event" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1700}>
            <div className="col-sm-4">
              <Form.Item
                name="Death"
                rules={[
                  { required: true, message: "Date Of Event is required" },
                ]}
              >
                {" "}
                <DatePicker placeholder="Date of Death" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={1900}>
            <div className="col-sm-2 formtext">
              <p> Duration of Illness</p>
            </div>
          </Flip>

          <Flip top delay={2100}>
            <div className="col-sm-5">
              <Form.Item
                name="from"
                rules={[{ required: true, message: "From is required" }]}
              >
                {" "}
                <Input placeholder="From" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={2300}>
            <div className="col-sm-5">
              <Form.Item
                name="to"
                rules={[{ required: true, message: "To is required" }]}
              >
                {" "}
                <Input placeholder="To" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={2500}>
            <div className="col-sm-6">
              <Form.Item
                name="tos"
                rules={[
                  {
                    required: true,
                    message:
                      "Date first trested for this accident or illness is required",
                  },
                ]}
              >
                {" "}
                <Input placeholder="Date first trested for this accident or illness" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2700}>
            <div className="col-sm-6">
              <div className="statement row">
                <div className="col-sm-6">
                  {" "}
                  <p>Treated by</p>
                </div>
                <div className="col-sm-3">
                  <Checkbox>
                    <h6> Doctor </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-2">
                  {" "}
                  <Checkbox>
                    <h6> Hospital </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={2900}>
            <div className="col-sm-6">
              <Form.Item
                name="Namess"
                rules={[{ required: true, message: "Name is Required" }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={3100}>
            <div className="col-sm-6">
              <Form.Item
                name="Addresss"
                rules={[{ required: true, message: "Address is Required" }]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={3300}>
            <div className="col-sm-2 formtext">
              <p> If Hospital</p>
            </div>
          </Flip>

          <Flip top delay={3300}>
            <div className="col-sm-5 ">
              <Form.Item
                name="admisiossion"
                rules={[
                  { required: true, message: "Date Of Admission is required" },
                ]}
              >
                <DatePicker placeholder="Date of Admission " />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={3500}>
            <div className="col-sm-5 ">
              <Form.Item
                name="discharge"
                rules={[
                  { required: true, message: "Date Of Discharge is required" },
                ]}
              >
                <DatePicker placeholder="Date of Discharge" />
              </Form.Item>
            </div>
          </Flip>

          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button onClick={showModal} className="dashboardBn">
                Next
              </button>
            </div>
          </Flip>
        </div>
      </Form>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default StatementForm;
