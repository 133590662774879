import React, { useState, useEffect, Fragment } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./Style/laptop.css";
import "./Style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style/responsive.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./Components/Loader/Loader";
import Header from "./Components/Header/Header";
import Home from "./Layout/Home";
import Footer from "./Components/Footer/Footer";
import Whoweare from "./Layout/Whoweare";
import Governance from "./Layout/Governance";
import BuyNow from "./Layout/BuyNow";
import SideBar from "./Layout/SideBar";
import SingleNewsPage from "./Layout/SingleNewsPage";
import Newsevent from "../src/Layout/Newsnevents";
import ProductsSavings from "./Layout/ProductsSavings";
import ComplainBox from "./Layout/ComplainBox";
import WhistleBlowing from "./Layout/WhistleBlowing";
import FundPrices from "./Layout/FundPrices";
import PastPrices from "./Layout/PastPrices";
import Login from "./Layout/Login";
import ForgetPassword from "./Layout/ForgetPassword";
import UserHeader from "./Dashboard/Header/UserHeader";
import Dashboard from "./Layout/Dashboard";
import Service from "./Layout/Service";
import WithDrawlPartial from "./Layout/WithDrawlPartial";
import CashValue from "./Layout/CashValue";
import MemberShip from "./Layout/MemberShip";
import PolicyDetail from "./Layout/PolicyDetail";
import LiveChat from "./Layout/liveChat";
import Payment from "./Layout/Payment";
import Claim from "./Layout/Claim";
import Policy from "./Layout/Policy";
import CallBackForm from "./Layout/CallBackForm";
import Contact from "./Layout/Contact";
import ClaimStatus from "./Layout/ClaimStatus";
import FundSwitch from "./Layout/FundSwitch";
import Contribution from "./Layout/Contribution";
import Agent from "./Layout/Agent";
import Nominee from "./Layout/Nominee";

import Videos from "./Layout/Videos";
import GroupProduct from "./Layout/GroupProduct";
import Career from "./Layout/Career";
import SustainabilityESG from "./Layout/SustainabilityESG";

import NomineeInfo from "./Layout/NomineeInfo";
import Statement from "./Layout/Statement";
import NomineeLast from "./Layout/NomineeLast";
import NomineeStatement from "./Layout/NomineeStatement";
import NomineeStatus from "./Layout/NomineeStatus";
import ClaimTabs from "./Layout/ClaimTabs";
import ClaimOptionTabs from "./Layout/ClaimOptionTabs";
import BasicInformation from "./Layout/BasicInformation";
import BankDetails from "./Layout/BankDetails";
import BankInfo from "./Layout/BankInfo";
import WithDrawlPermanent from "./Layout/WithDrawlPermanent";
import FreeLook from "./Layout/FreeLook";
import Proposal from "./Layout/Proposal";
import Renewal from "./Layout/Renewal";
import TopUp from "./Layout/TopUp";
import EditInformation from "./Layout/EditInformation";
import Beneficery from "./Layout/Beneficery";
import BeneficeryGuardian from "./Layout/BeneficeryGuardian";
import CompanyDetails from "./Layout/CompanyDetails";
import Calculate from "./Layout/Calculate";
import ShariahAdvisor from "./Layout/ShariahAdvisor";
import FMR from "./Layout/FMR";
import LifeAtSalaam from "./Layout/LifeAtSalaam";

function App() {
  const { pathname } = useLocation();
  const [change, setChange] = useState(true);
  const [userIsMobile, setUserIsMobile] = useState(true);
  const [isDashboard, setIsDashboard] = useState(false);
  useEffect(() => {
    window.innerWidth < 520 ? setUserIsMobile(true) : setUserIsMobile(false);
  }, [userIsMobile]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  useEffect(() => {
    if (pathname.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [isDashboard, pathname]);

  return (
    <React.Fragment>
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <>
            {isDashboard ? (
              <UserHeader />
            ) : (
              <Header change={change} setChange={setChange} />
            )}

            <Routes>
              <Route
                path="/"
                exact
                element={<Home change={change} setChange={setChange} />}
              />
              <Route
                path="/whoweare"
                exact
                element={<Whoweare change={change} setChange={setChange} />}
              />
              <Route path="/calculate" exact element={<Calculate />} />
              <Route path="/governance" exact element={<Governance />} />
              <Route path="/request" exact element={<CallBackForm />} />
              <Route path="/buynow" exact element={<BuyNow />} />
              <Route path="/complain-box" exact element={<ComplainBox />} />
              <Route path="/fund-prices" exact element={<FundPrices />} />
              <Route path="/fmr-report" exact element={<FMR />} />
              <Route path="/past-prices" exact element={<PastPrices />} />
              <Route path="/contact-us" exact element={<Contact />} />
              <Route
                path="/whistle-blowing"
                exact
                element={<WhistleBlowing />}
              />
              <Route
                path="/sidebar"
                exact
                element={<SideBar change={change} setChange={setChange} />}
              />
              <Route path="/life-at-salaam" exact element={<LifeAtSalaam />} />
              <Route path="/shariah" exact element={<ShariahAdvisor />} />
              <Route path="/newsnevents" exact element={<Newsevent />} />
              <Route path="/policy" exact element={<Policy />} />
              <Route path="/news/:key" exact element={<SingleNewsPage />} />
              <Route path="/individuals" exact element={<ProductsSavings />} />
              <Route path="/takaful-videos" exact element={<Videos />} />
              <Route path="/group" exact element={<GroupProduct />} />
              <Route path="/careers" exact element={<Career />} />
              <Route path="/company-detail" exact element={<CompanyDetails />} />
              <Route path="/esg" exact element={<SustainabilityESG />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/forgetpassword" exact element={<ForgetPassword />} />
              {/* Dashboard */}
              <Route path="/dashboard" exact element={<Dashboard />} />
              <Route path="/dashboard/service" exact element={<Service />} />
              <Route path="/dashboard/edit-info" exact element={<EditInformation />} />
              <Route path="/dashboard/withdrawl-partial" exact element={<WithDrawlPartial />} />
              <Route path="/dash oard/withdrawl-permanent" exact element={<WithDrawlPermanent />} />
              <Route path="/dashboard/free-look" exact element={<FreeLook />} />
              <Route path="/dashboard/beneficery" exact element={<Beneficery />} />
              <Route path="/dashboard/beneficery-guardian" exact element={<BeneficeryGuardian />} />
              <Route path="/dashboard/cash-value" exact element={<CashValue />} />
              <Route path="/dashboard/membership" exact element={<MemberShip />} />
              <Route path="/dashboard/policy" exact element={<PolicyDetail />} />
              <Route path="/dashboard/livechat" exact element={<LiveChat />} />
              <Route path="/dashboard/payment" exact element={<Payment />} />
              <Route path="/dashboard/proposal" exact element={<Proposal />} />
              <Route path="/dashboard/renewal" exact element={<Renewal />} />
              <Route path="/dashboard/claim" exact element={<Claim />} />
              <Route path="/dashboard/nominee" exact element={<Nominee />} />
              <Route path="/dashboard/fund-switch" exact element={<FundSwitch />} />
              <Route path="/dashboard/agent" exact element={<Agent />} />
              <Route path="/dashboard/claim-status" exact element={<ClaimStatus />} />
              <Route path="/dashboard/contribution" exact element={<Contribution />} />
              <Route path="/dashboard/contribution" exact element={<Claim />} />
              <Route path="/dashboard/nominee-info" exact element={<NomineeInfo />} />
              <Route path="/dashboard/top-up" exact element={<TopUp />} />
              <Route path="/dashboard/statement" exact element={<Statement />} />
              <Route path="/dashboard/nominee-form" exact element={<NomineeLast />} />
              <Route path="/dashboard/nominee-statement" exact element={<NomineeStatement />} />
              <Route path="/dashboard/nominee-status" exact element={<NomineeStatus />} />
              <Route path="/dashboard/claim-tab" exact element={<ClaimTabs />} />
              <Route path="/dashboard/claim-tabs" exact element={<ClaimOptionTabs />} />
              <Route path="/dashboard/basic-info" exact element={<BasicInformation />} />
              <Route path="/dashboard/bank-details" exact element={<BankDetails />} />
              <Route path="/dashboard/bank-uploads" exact element={<BankInfo />} />
            </Routes>
            {window.location.pathname.startsWith("/dashboard") ? (
              <></>
            ) : (
              <Footer />
            )}
          </>
        )}
      </Fragment>
    </React.Fragment>
  );
}

export default App;
