import React from "react";
import Profile from "../Main/Profile";
import { Button, Form, Input } from "antd";
import FreeLookTable from "./FreeLookTable";
import Flip from "react-reveal/Flip";

const FreeLook = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl ">
        <div className="col-sm-6 disableInput">
          <Form.Item>
            <Input disabled defaultValue={"Policy #"} />
          </Form.Item>
        </div>
        <div className="col-sm-6 disableInput">
          <Form.Item>
            <Input disabled defaultValue={"Amount"} />
          </Form.Item>
        </div>

        <Flip top delay={600}>
          <FreeLookTable />
        </Flip>

        <div className="col-sm-12 btnSection">
          <Flip top delay={800}>
            <button>Proceed</button>
          </Flip>
        </div>
        <div className="col-sm-12 changeMind">
          <div className="row">
            <div className="col-sm-12">
              <p>
                Considering the current investment yields and your future plans
                we suggest not to withdrawl at this stage
              </p>
            </div>
            <div className="col-sm-4">
              <Button>Change My Mind</Button>
            </div>
            <div className="col-sm-4">
              <Button>I Want to proceed</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeLook;
