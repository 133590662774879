import React from "react";

const Terms = () => {
  return (
    <div className="modalForm container">
      <div className="col-sm-12">
        <h4>Terms & Conditions</h4>
      </div>
      <div className="col-sm-12">
        <p>
          I, "customer name" that this proposal form, along with any additional
          documents or information submitted in connection with this application
          shall form the basis of the takaful contract between me and Salaam
          Family Takaful Limited. I hereby declare and confirm, under the
          principles of utmost good faith, that all the information provided in
          this proposal form and the accompanying documents is true, accurate,
          and complete to the best of my knowledge and belief. I understand that
          any false statement or misrepresentation, whether intentional or
          unintentional, may result in the rejection of my takaful application,
          cancellation of my policy, or denial of any future claims. By signing
          this declaration, I also authorize Salaam Family Takaful to obtain
          relevant information from any necessary sources, including Government
          Authorities like NADRA, CDC etc. to verify the accuracy and
          completeness of the information provided in this proposal form. For
          the underwriting and claim process, I give my permission to any
          physician or other medical practitioner, hospital, clinic, other
          medical or medically related facility, takaful/insurance company, or
          employer to give Salaam Family Takaful Limited or its authorized
          representative "ALL INFORMATION" on my behalf including copies of
          records with reference to any sickness, accidental disability,
          treatment, examination medical investigation, advice or
          hospitalization underwent. I hereby apply for the Family Takaful
          coverage under the terms and conditions of the Participant Membership
          Document (PMD). Disclaimer: The Participant can cancel the Membership
          within fourteen (14) days of the receipt of the Participant's
          Membership Document (PMD) by the Participant. In case of cancellation
          during this free look period (subject to Terms and Conditions), the
          paid contribution is refundable.
        </p>
      </div>
    </div>
  );
};

export default Terms;
