import React from "react";
import { DatePicker, Form, Input, Modal } from "antd";
import NomineeHeader from "./NomineeLastHeader";
import { useState } from "react";
const NomineLastForm = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Thank you for intimating the claim under membership <no.>.As per our record the nominee of the membership is <…….>.Please convey our heartiest condolence to the bereaved family. Our customer service team will be contacting soon the nominee on the available contact details. However, we will appreciate your support for connecting the nominee with us by contacting our help line 021-111-875-111. Or lodge a claim at www.salaam.com"
  );

  const handleFinish = (values) => {
    console.log(values);
    // navigate("/");
    showModal();
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <div className="container">
      <NomineeHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
          <div className="col-sm-6">
            <Form.Item>
              <Input disabled defaultValue="CNIC #" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue="Intimator Name" maxLength={15} />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue={"Date of Birth"} />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item>
              <Input disabled defaultValue="Sum Covered" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Initimator Relation is required",
                },
              ]}
              name="Initimator"
            >
              <Input placeholder="Initimator Relation" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item name="last-Dates" rules={[{ required: true }]}>
              <Input placeholder="Contact No" />
            </Form.Item>
          </div>

          <div
            className="col-sm-12
        "
          >
            <Form.Item
              rules={[{ required: true, message: "Policy Number is required" }]}
              name="policy"
            >
              <Input placeholder="Policy #" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item
              rules={[
                { required: true, message: " Date of Death is required" },
              ]}
              name="death"
            >
              <DatePicker placeholder="Date of Death" />
            </Form.Item>
          </div>

          <div
            className="col-sm-6
        "
          >
            <Form.Item
              rules={[
                { required: true, message: " Cause of Death is required" },
              ]}
              name="deaths"
            >
              <DatePicker placeholder="Cause of Death" />
            </Form.Item>
          </div>

          <div className="col-sm-12 verifyBtn">
            <button className="dashboardBn">Submit</button>
          </div>
        </div>
      </Form>
      <Modal open={open} confirmLoading={confirmLoading}>
        <h6>{modalText}</h6>
      </Modal>
    </div>
  );
};

export default NomineLastForm;
