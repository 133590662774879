import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { eligibilityData } from "../../Data/Data";

function Eligibilaty() {
  return (
    <Fragment>
      <div className="sub productetxt uniqueprods ">
        <div className="row">
          <Fade right delay={500}>
            <div className="col-sm-12 supliments">
              <h2>ELIGIBILITY </h2>
            </div>
          </Fade>

          {eligibilityData.map((elem, ind) => (
            <Fade up delay={600} >
              <div className="col-sm-3 suplimentsicon" key={ind}>
                <div className="supliment">
                  <div className="row mySuppliment">
                    <div className="col-sm-12 newnoPad">
                      <p className="elemTxt">{elem.text}</p>
                    </div>
                    <div className="col-sm-12 eligNumber">
                      <div>
                        <h1>{elem.number}</h1>
                      </div>
                    </div>
                    <div className="col-sm-12 newnoPad">
                      <h3 style={{marginBottom: 0, paddingBottom: 0}}>{elem.heading}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Eligibilaty;
