import React from "react";
import { Fragment } from "react";
import NewsBanner from "../Components/News/NewsBanner";
import { Helmet } from "react-helmet";

function Newsnevents() {
  return (
    <Fragment>
      <Helmet>
        <title>
          PR and News | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="All relevant information about the companies activities and Events"
        />
        <meta
          name="keywords"
          content="Events,PR ,Company News,Salaam Life and Savings"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <NewsBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default Newsnevents;
