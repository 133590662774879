import React, { Fragment, useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import MissionImage from "../../Assets/mission.jpg";

function Vision() {
  const [canAutoplay, setCanAutoplay] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const checkAutoplay = () => {
      if (video) {
        video.muted = true; // Ensure video is muted for autoplay to work
        video.play().then(() => {
          setCanAutoplay(true); // Autoplay is allowed
          video.pause();
        });
      }
    };

    // Check for autoplay after a user interaction (click or touch) or when the component mounts
    document.addEventListener("click", checkAutoplay);
    document.addEventListener("touchstart", checkAutoplay);

    return () => {
      document.removeEventListener("click", checkAutoplay);
      document.removeEventListener("touchstart", checkAutoplay);
    };
  }, [canAutoplay]);
  return (
    <Fragment>
      <div id="ntn" className="row whoweare mission left">
        <div className="col-sm-5 allvisions">
          <div className="vision">
            <Fade right delay={500}>
              <h2 className="uniq">Vision</h2>
            </Fade>
            <Fade right delay={500}>
              <p>
                To be the ultimate provider of halal financial benefits and life
                takaful for every segment of society, anytime, anywhere
              </p>
            </Fade>
          </div>

          <div className="vision">
            <Fade right delay={500}>
              <h2 className="uniq">Mission</h2>
            </Fade>
            <Fade right delay={500}>
              <p>
                We are on a mission to create and scale trustworthy digital
                ecosystem that caters to the unique needs of people seeking
                Islamic saving and loss protection solutions. By combining
                Shariah compliance, innovation, convenience, and personalized
                service, we endeavor to build strong relationships with all our
                stakeholders, ensuring a seamless journey towards financial
                growth, and prosperity.
              </p>
            </Fade>
          </div>
        </div>

        <div className="col-sm-7 visionVideo">
          {/* <video ref={videoRef} autoPlay loop muted playsInline>
            <source
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/mission.mp4`}
              type="video/mp4"
            />
          </video> */}

          <img src={MissionImage} alt="as" />
        </div>
      </div>
    </Fragment>
  );
}

export default Vision;
