import React, { Fragment } from "react";
import LifeAtSalaamBanner from "../Components/LifeAtSalaam/LifeAtSalaamBanner";

function LifeAtSalaam() {
  return (
    <Fragment>
      <div className="container-fluid faderight ">
        <div className="row">
          <LifeAtSalaamBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default LifeAtSalaam;