import React from "react";

import { Form, Input, Select } from "antd";
import Flip from "react-reveal/Flip";
import BeneficeryTable from "./BeneficeryTable";
import BeneficeryHeader from "./BeneficeryHeader";

const BeneficeryForm = () => {
  return (
    <div className="container">
      <BeneficeryHeader />
      <div className="row withDrawl claimForm NomineeForm ">
        <div className="col-sm-12 ">
          <Form.Item>
            <Input placeholder="Beneficery Name" />
          </Form.Item>
        </div>
        <div className="col-sm-6 ">
          <Form.Item>
            <Input placeholder="CNIC #" />
          </Form.Item>
        </div>
        <div className="col-sm-6 ">
          <Form.Item>
            <Input placeholder="CNIC #" />
          </Form.Item>
        </div>
        <div className="col-sm-6 ">
          <Form.Item>
            <Select placeholder="Relation" />
          </Form.Item>
        </div>
        <div className="col-sm-6 ">
          <Form.Item>
            <Input placeholder="Share (Ex. 50%)" />
          </Form.Item>
        </div>
        <div className="col-sm-12 btnSection btnSection1">
          <Flip top delay={800}>
            <button>Add Beneficery</button>
          </Flip>
        </div>
        <Flip top delay={600}>
          <BeneficeryTable />
        </Flip>
      </div>
    </div>
  );
};

export default BeneficeryForm;
