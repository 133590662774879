import React, { useRef } from "react";
import { Form, Input, Modal } from "antd";
import { Flip } from "react-reveal";
import EditInfoHeader from "./EditHeader";
import { useState } from "react";
import DeleteIcon from "../../Assets/Trash.png";

const EditInfoForm = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Given Information Verified Successfully"
  );

  const handleFinish = (values) => {
    console.log(values);
    // navigate("/");
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const length = 6;
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(Array(length).fill(""));

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return; // Allow only numeric input
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    // Move focus to the next input field
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move focus to the previous input field on backspace
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div className="container">
      <EditInfoHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
          <Flip top delay={300}>
            <div className="col-sm-12">
              <Form.Item name="Name">
                <Input placeholder="Full Name" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="col-sm-12">
              <Form.Item
                name="CNIC"
                rules={[{ required: true, message: "CNIC is Required" }]}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className="col-sm-8">
              <Form.Item name="Contact-Number">
                <Input placeholder="Contact Number" />
              </Form.Item>
              <img src={DeleteIcon} alt="" />
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-3">
              <button className="dashboardBn greyBtn">
                Add Another Number
              </button>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="row lineSec">
              <div className="col-sm-6">
                <p>
                  Enter the verification code sent to your registered mobile no.
                  & email address
                </p>
              </div>
              <div className="col-sm-6">
                <div className="otpModal1">
                  {otp.map((digit, index) => (
                    <Form.Item
                      key={index}
                      rules={[
                        {
                          required: true,
                          message: "Please enter an OTP digit",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    </Form.Item>
                  ))}
                </div>
              </div>
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-8">
              <Form.Item name="Contact-Number">
                <Input placeholder="Email Address" />
              </Form.Item>
              <img src={DeleteIcon} alt="" />
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-3">
              <button className="dashboardBn greyBtn">Add Another Email</button>
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-8">
              <Form.Item name="Contact-Number">
                <Input placeholder="Address" />
              </Form.Item>
              <img src={DeleteIcon} alt="" />
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-3">
              <button className="dashboardBn greyBtn">Change Address</button>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className="col-sm-8">
              <Form.Item name="Contact-Number">
                <Input placeholder="City" />
              </Form.Item>
              <img src={DeleteIcon} alt="" />
            </div>
          </Flip>

          <Flip top delay={700}>
            <div className="col-sm-3">
              <button className="dashboardBn greyBtn">Change City</button>
            </div>
          </Flip>
          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button onClick={showModal} className="dashboardBn">
                Verify
              </button>
            </div>
          </Flip>
        </div>
      </Form>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default EditInfoForm;
