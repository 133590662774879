import React, { Fragment } from "react";
import FundPricesBanner from "../Components/FundPrices/FundPricesBanner";
import { Helmet } from "react-helmet";

function FundPrices() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Fund Prices | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="Information about the daily fund prices to keep the participants updated"
        />
        <meta
          name="keywords"
          content="Funds Prices,NAV , Individual Product, Salaam Life and Savings"
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <FundPricesBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default FundPrices;
