import React, { useState } from "react";
import { Upload, Button, Image, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Fragment } from "react";
const Step9 = () => {
  const [idCardFront, setIdCardFront] = useState(null);
  const [idCardBack, setIdCardBack] = useState(null);
  const [yourPicture, setYourPicture] = useState(null);
  const [proofPicture, setproofPicture] = useState(null);

  const handleIdCardFrontChange = (file) => {
    setIdCardFront(file);
  };

  const handleIdCardBackChange = (file) => {
    setIdCardBack(file);
  };

  const handleYourPictureChange = (file) => {
    setYourPicture(file);
  };
  const handleProofPictureChange = (file) => {
    setproofPicture(file);
  };

  return (
    <Fragment>
      <div className="col-sm-12 formHeader uploadsbtns">
        <h4>Upload CNIC Picture</h4>
      </div>
      <div className="col-sm-3 uploadsbtns fullWidth">
        <Form.Item
          name="idfront"
          rules={[
            {
              required: true,
              message: "Please Upload ID Card Front Image!",
            },
          ]}
        >
          <Upload
            action="/upload.do"
            accept="image/jpeg, image/png"
            showUploadList={false}
            beforeUpload={(file) => {
              handleIdCardFrontChange(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Upload ID Card Front</Button>
          </Upload>
        </Form.Item>
        {idCardFront && (
          <Image src={URL.createObjectURL(idCardFront)} alt="ID Card Front" />
        )}
      </div>
      <div className="col-sm-3 uploadsbtns fullWidth">
        <Form.Item
          name="idback"
          rules={[
            {
              required: true,
              message: "Please Upload ID Card Back Image!",
            },
          ]}
        >
          <Upload
            action="/upload.do"
            accept="image/jpeg, image/png"
            showUploadList={false}
            beforeUpload={(file) => {
              handleIdCardBackChange(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Upload ID Card Back</Button>
          </Upload>
          {idCardBack && (
            <Image src={URL.createObjectURL(idCardBack)} alt="ID Card Back" />
          )}
        </Form.Item>
      </div>
      <div className="col-sm-3 uploadsbtns fullWidth">
        <Form.Item
          name="mypic"
          rules={[
            {
              required: true,
              message: "Please Upload Your Image!",
            },
          ]}
        >
          <Upload
            action="/upload.do"
            accept="image/jpeg, image/png"
            showUploadList={false}
            beforeUpload={(file) => {
              handleYourPictureChange(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Your Picture</Button>
          </Upload>
          {yourPicture && (
            <Image src={URL.createObjectURL(yourPicture)} alt="Your Picture" />
          )}
        </Form.Item>
      </div>

      <div className="col-sm-3 uploadsbtns fullWidth">
        <Form.Item
          name="proof"
          rules={[
            {
              required: true,
              message: "Please Upload Your Proof of Income!",
            },
          ]}
        >
          <Upload
            action="/upload.do"
            accept="image/jpeg, image/png"
            showUploadList={false}
            beforeUpload={(file) => {
              handleProofPictureChange(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>
              Upload Your Proof of Income
            </Button>
          </Upload>
          {proofPicture && (
            <Image src={URL.createObjectURL(proofPicture)} alt="Your Picture" />
          )}
        </Form.Item>
      </div>
    </Fragment>
  );
};

export default Step9;
