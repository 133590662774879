import React from "react";
import Profile from "../Main/Profile";
import PayementTable from "./PayementTable";
import Flip from "react-reveal/Flip";
const Payement = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl">
        <div className="col-sm-12 ">
          <Flip top delay={500}>
            <PayementTable />
          </Flip>
        </div>

        <div className="col-sm-12 btnSection">
          <Flip top delay={800}>
            <button>Pay Now</button>
          </Flip>
        </div>
      </div>
    </div>
  );
};

export default Payement;
