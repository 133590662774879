import React, { Fragment } from "react";
import { Fade } from "react-reveal";
import { companyDetailsData } from "../../Data/Data";

const CompanyContent = () => {
  return (
    <Fragment>
      {/* <div className=" col-sm-12">
        <Fade left delay={800}>
          <h4>Corporate Information</h4>
        </Fade>
      </div> */}

      {companyDetailsData.map((item, i) => (
        <div className="col-sm-6" key={i}>
          <h2>{item?.heading}</h2>
          {/* {item?.webLink ? 
            <a href={item?.webLink} target="_blank">{item?.text}</a>  
            :
            <p>{item?.text}</p>

        } */}
        <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
        </div>
      ))}
      {/* <div className="col-sm-12">
        <Fade left delay={1200}>
          <h4>STATEMENT OF ETHICS & BUSINESS PRACTICES</h4>
        </Fade>
      </div>
      <div className="col-sm-12">
        <p>
          The Board of Directors has prepared this statement of ethics and
          business practices to establish a standard of conduct for employees
          and directors of Salaam Takaful Limited ‘the Company’, to be employed
          for conduct of its business and the business of the Participants’
          Takaful Fund under its management. Each director and employee signs
          the statement in acknowledgement of his/her understanding and
          acceptance of the standard of conduct.
        </p>
      </div> */}
    </Fragment>
  );
};

export default CompanyContent;
