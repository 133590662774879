import React, { Fragment } from 'react'
import EsgBnanner from '../Components/ESG/EsgBnanner'
import EsgVideo from '../Components/ESG/EsgVideo'
import { Helmet } from "react-helmet";

function SustainabilityESG() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Sustainibility ESG | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="Salaam Family Takaful Limited, being the largest dedicated life takaful operator and first Islamic insurtech in Pakistan, is committed to serve not only its policyholders but also the world we operate in. "
        />
        <meta
          name="keywords"
          content="ESG,sustainibility,Salaam Life Takaful "
        />
      </Helmet>
      <div className="container-fluid faderight ">
        <div className="row">
          <EsgVideo />
          <EsgBnanner />
        </div>
      </div>
    </Fragment>
  );
}

export default SustainabilityESG