import React from "react";
import { Checkbox, Form, Upload } from "antd";
import { Flip } from "react-reveal";
import { UploadOutlined } from "@ant-design/icons";
import BankHeader from "../BankDetails/BankDetailsHeader";

const BanksUpload = () => {
  const handleFinish = (values) => {
    console.log(values);
    // navigate("/");
  };

  return (
    <div className="container">
      <BankHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm">
          <Flip top delay={300}>
            <div className="col-sm-12">
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="Hospital Records"
                    rules={[
                      {
                        required: true,
                        message: "Hospital Records  is Required!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>Hospital Records</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className="col-sm-12">
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="Hospital Records"
                    rules={[
                      {
                        required: true,
                        message: "Hospital Records  is Required!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>Attending Physician Certificate</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div className="col-sm-12">
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="FIR Copy"
                    rules={[
                      {
                        required: true,
                        message: "FIR Copy   is Required!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>FIR Copy</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={1200}>
            <div
              className="col-sm-12
        "
            >
              <Form.Item name="Genders">
                <Checkbox>
                  <span>
                    Declation, I hereby confirm that the above stated
                    information is true and correct
                  </span>
                </Checkbox>
              </Form.Item>
            </div>{" "}
          </Flip>
          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button type="submit" className="dashboardBn">
                Submit
              </button>
            </div>
          </Flip>
        </div>
      </Form>
    </div>
  );
};

export default BanksUpload;
