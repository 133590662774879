import React, { Fragment } from "react";
import { Form, Checkbox, Button, Tooltip } from "antd";
import Flip from "react-reveal/Flip";
import jsPDF from "jspdf";
import { QuestionCircleOutlined } from "@ant-design/icons";

import illustration from "../../Assets/illustration1.png";
import illustration1 from "../../Assets/illustration2.png";

import illustration2 from "../../Assets/illustration3.png";

import illustration3 from "../../Assets/illustration4.png";

function Step6() {
  const Array = [1, 2, 3, 4, 5, 6];
  const downloadPDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add the entire page content to the PDF
    doc.html(document.body, {
      callback: () => {
        // Save the PDF with a specific file name
        doc.save("document.pdf");
      },
    });
  };
  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Illustration Summary</h4>
        </div>
      </Flip>

      <div className="col-sm-6 illustResponse">
        <div className="illustrator">
          <div className="row illustRow">
            <div className="col-sm-4 illustrationImage">
              <img src={illustration} alt="" />
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-12">
                  <h4>Personal Information</h4>
                </div>
                <div className="col-sm-6">
                  <h6>Name </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Muhammad Faiz Raza </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Date Of Birth </h6>
                </div>
                <div className="col-sm-6">
                  <h6>20/sept/2023 </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Gender </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Male</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Document No </h6>
                </div>
                <div className="col-sm-6">
                  <h6>#3893829</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 illustResponse">
        <div className="illustrator">
          <div className="row illustRow">
            <div className="col-sm-4 illustrationImage">
              <img src={illustration1} alt="" />
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-12">
                  <h4>Plan Details</h4>
                </div>
                <div className="col-sm-6">
                  <h6>Plan Name </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Salaam Life & Savings </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Plan Term </h6>
                </div>
                <div className="col-sm-6">
                  <h6> 10 Years </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Extra Mertabity </h6>
                </div>
                <div className="col-sm-6">
                  <h6>N</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Cover Multiple </h6>
                </div>
                <div className="col-sm-6">
                  <h6>6</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 illustResponse">
        <div className="illustrator">
          <div className="row illustRow">
            <div className="col-sm-4 illustrationImage">
              <img src={illustration2} alt="" />
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-6">
                  <h6>Expected Commencement Date </h6>
                </div>
                <div className="col-sm-6">
                  <h6>17 Sep 2024 </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Reference No.</h6>
                </div>
                <div className="col-sm-6">
                  <h6>DOC 2323</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Valid Date </h6>
                </div>
                <div className="col-sm-6">
                  <h6>17 Sep 2025</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Income Tax</h6>
                </div>
                <div className="col-sm-6">
                  <h6>N/A </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 illustResponse">
        <div className="illustrator">
          <div className="row illustRow">
            <div className="col-sm-4 illustrationImage">
              <img src={illustration3} alt="" />
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-6">
                  <h6>Takaful Contribution </h6>
                </div>
                <div className="col-sm-6">
                  <h6>PKR 50,000 </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Basic Contribution Indexation </h6>
                </div>
                <div className="col-sm-6">
                  <h6>PKR 0.00 </h6>
                </div>
                <div className="col-sm-6">
                  <h6>Supplementary Benefits </h6>
                </div>
                <div className="col-sm-6">
                  <h6>N/A</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Sum Assured </h6>
                </div>
                <div className="col-sm-6">
                  <h6>PKR 250,000</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12 step6Tab">
        <table>
          <thead>
            <tr>
              <th>Membersip Year</th>
              <th>Age</th>
              <th>Takaful Contribution</th>
              <th>Cumm. Paid Contribution </th>
              <th>6% Unit Growth Rate</th>
              <th>9% Unit Growth Rate</th>

              <th>11% Unit Growth Rate </th>
            </tr>
          </thead>
          <tbody>
            {Array.map(() => (
              <tr>
                <td>1</td>
                <td>30</td>
                <td>PKR 150,000</td>
                <td>PKR 150,000</td>
                <td>PKR 150,000</td>
                <td>PKR 150,000</td>

                <td>PKR 150,000</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Flip top delay={900}>
        <div className="col-sm-4 illustrationCheckkkkk">
          <Form.Item
            name="illustration"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Save Illustration")),
              },
            ]}
          >
            <Checkbox>
              Save Illustration
              <span>
                <Tooltip
                  color="#0889e3"
                  title="It represents the key elements of your policy. It provides a simplified overview of your entire coverage"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </Checkbox>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1100}>
        <div className="col-sm-4 illustrationCheckkkkk">
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Please Accept Terms & Conditions")
                      ),
              },
            ]}
          >
            <Checkbox>Yes I Agreed the Terms And Conditions</Checkbox>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1200}>
        <div className="col-sm-12 step6btn">
          <Button onClick={downloadPDF}>Download File</Button>
        </div>
      </Flip>
    </Fragment>
  );
}

export default Step6;
