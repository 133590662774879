import React from "react";
import { Checkbox, Form, Input, Modal, Upload } from "antd";
import { Flip } from "react-reveal";
import { useState } from "react";
import NomineeStatementHeader from "./NomineeStatementHeader";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const NomineeStatementForm = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [modalText, setModalText] = useState(
    "Given Information Verified Successfully"
  );

  const handleFinish = (values) => {
    console.log(values);
  };

  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div className="container">
      <NomineeStatementHeader />
      <Form onFinish={handleFinish}>
        <div className="row withDrawl claimForm NomineeForm ">
          <Flip top delay={300}>
            <div className="col-sm-12">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Details of all treatment is Required ",
                  },
                ]}
                name="treatment"
              >
                <TextArea
                  rows={8}
                  placeholder="Details of all treatments taken in previous three years"
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={500}>
            <div className=" col-sm-12">
              <div className="statement row">
                <div className="col-sm-6">
                  {" "}
                  <p>Was the deceased taking regular or routine medication?</p>
                </div>
                <div className="col-sm-3">
                  <Checkbox>
                    <h6> Yes </h6>
                  </Checkbox>
                </div>
                <div className="col-sm-2">
                  {" "}
                  <Checkbox>
                    <h6>No </h6>
                  </Checkbox>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={700}>
            <div
              className="col-sm-12
        "
            >
              <Form.Item name="CNIsC">
                <Input placeholder="If yes, please share detail" />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={900}>
            <div
              className="col-sm-12
        "
            >
              <Form.Item name="Genders">
                <Checkbox>
                  <span>
                    Declaration: I hereby confirm that the above stated
                    information is acceptable for claim settlement.
                  </span>
                </Checkbox>
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={1100}>
            <div
              className="col-sm-12
        "
            >
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="proof"
                    rules={[
                      {
                        required: true,
                        message: "Please Upload Your Proof of Income!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>Beneficiary / Claimant’s CNIC</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={1300}>
            <div
              className="col-sm-12
        "
            >
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="proof"
                    rules={[
                      {
                        required: true,
                        message: "Please Upload Your Proof of Income!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>Attending Physician</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>
          <Flip top delay={1500}>
            <div
              className="col-sm-12
        "
            >
              <div className="row statement uploader">
                <div className="col-sm-12">
                  <Form.Item
                    name="proof"
                    rules={[
                      {
                        required: true,
                        message: "Please Upload Your Proof of Income!",
                      },
                    ]}
                  >
                    <Upload
                      action="/upload.do"
                      accept="image/jpeg, image/png"
                      showUploadList={false}
                    >
                      {" "}
                      <div className="d-flex ">
                        <p>Hospital Record</p>
                        <UploadOutlined
                          style={{ position: "absolute", right: 0 }}
                        />
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Flip>

          <Flip top delay={600}>
            <div className="col-sm-12 verifyBtn">
              <button onClick={showModal} className="dashboardBn">
                Submit
              </button>
            </div>
          </Flip>
        </div>
      </Form>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
};

export default NomineeStatementForm;
