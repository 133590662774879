import React, { Fragment } from "react";
import ComplainBanner from "../Components/Complainbox/ComplainBanner";

function ComplainBox() {
  return (
    <Fragment>
      <div className="container-fluid faderight ">
        <div className="row">
          <ComplainBanner />
        </div>
      </div>
    </Fragment>
  );
}

export default ComplainBox;