import React, { Fragment } from 'react'
import ContactBanner from '../Components/Contact/ContactBanner';

function Contact() {
  return (

    <Fragment>
      <div className='container-fluid faderight '>
        <div className='row'>
          <ContactBanner />
        </div>
      </div>

    </Fragment>

  )
}

export default Contact