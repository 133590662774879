import React from "react";
import Profile from "../Main/Profile";
import { Checkbox, Form, Input } from "antd";

import Flip from "react-reveal/Flip";

const Proposal = () => {
  return (
    <div className="container">
      <Profile />
      <div className="row withDrawl proposalForm">
        <div className="col-sm-12 disableInput">
          <Form.Item>
            <Input disabled defaultValue={"Policy #"} />
          </Form.Item>
        </div>
        <div className="col-sm-6 ">
          <div className="withdrawlLine">
            <Checkbox>Non Index Contribution</Checkbox>
            <h5>PKR 10,0000</h5>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="withdrawlLine">
            <Checkbox>Non Index Contribution</Checkbox>
            <h5>PKR 10,0000</h5>
          </div>
        </div>
        <div className="col-sm-12 btnSection">
          <Flip top delay={800}>
            <button>Proceed to Payment</button>
          </Flip>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
