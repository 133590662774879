import React from "react";
import { Link } from "react-router-dom";
import Flip from "react-reveal/Flip";

const ServiceTab = () => {
  return (
    <div className="row container dashboardTabs">
      <div className="col-sm-4">
        <Flip top delay={400}>
          <div className="box">
            <Link to="/dashboard/withdrawl">
              {" "}
              <img
                // src={Tab2}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Tab2.png`}
                alt="tab1"
              />{" "}
              <p>Withdrawl Request</p>
            </Link>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={600}>
          <div className="box">
            <img
              // src={Tab1}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab1.png`}
              alt="tab1"
            />{" "}
            <p>Update Contact Details</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={800}>
          <div className="box">
            <img
              // src={Tab3}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab3.png`}
              alt="tab1"
            />{" "}
            <p>Change in Contribution</p>
          </div>
        </Flip>
      </div>

      <div className="col-sm-4">
        <Flip top delay={1000}>
          <div className="box">
            <img
              // src={Tab4}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab7.png`}
              alt="tab1"
            />{" "}
            <p>Change in Beneficiary</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={1200}>
          <div className="box">
            <img
              // src={complainfeedacks}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/complainfeedacks.png`}
              alt="tab1"
            />{" "}
            <p>Tax Certificate</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={1400}>
          <div className="box">
            <img
              //  src={Tab5}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab6.png`}
              alt="tab1"
            />{" "}
            <p>Others</p>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default ServiceTab;
