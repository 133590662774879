import React from "react";
import UserSidebar from "../Dashboard/Header/UserSidebar";
import UserCanvas from "../Dashboard/Header/UserCanvas";
import ClaimOptionsTab from "../Dashboard/ClaimOptions/ClaimOptionsTab";

const ClaimOptionTabs = () => {
  return (
    <div className="container-fluid dashbord">
      <div className="row">
        <div className="col-sm-2 sideSection">
          <UserSidebar />
        </div>
        <div className="col-sm-1 sideSectionCanvas">
          <>
            {["start"].map((placement, idx) => (
              <UserCanvas key={idx} placement={placement} name={"Menu"} />
            ))}
          </>
        </div>
        <div className="col-sm-10 dashboardSection">
          <ClaimOptionsTab />
        </div>
      </div>
    </div>
  );
};

export default ClaimOptionTabs;
