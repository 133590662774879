import React from "react";
import UserDashboard from "../Dashboard/Main/Dashboard";
const Dashboard = () => {
  return (
    <>
      {" "}
      <UserDashboard />{" "}
    </>
  );
};

export default Dashboard;
