import React, { useState, Fragment, useEffect } from "react";
import { Form, Input, DatePicker, Select, Button } from "antd";

import { toast } from "react-toastify";

const EditStep10 = ({
  index,
  shareCount,
  setDisableBtn,
  setBeneficeryLength,
  beneficeryLength,
  data,
  onUpdate,
  handleCancel,
}) => {
  const [beneficeryName, setBeneficeryName] = useState("");
  const [relation, setRelation] = useState("");
  const [share, setShare] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianCNIC, setGuardianCNIC] = useState("");
  const [beneficerCNIC, setBeneficerCNIC] = useState();
  const [errors, setErrors] = useState({});
  const [dob, setDob] = useState();

  const [age, setAge] = useState(null);
  const [Gage, setGAge] = useState(null);

  const relationshipOptions = [
    "Parent",
    "Sibling",
    "Spouse",
    "Child",
    "Friend",
  ];
  const { Option } = Select;
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    return current && current > new Date("2005-01-01");
  };

  const handleDateChange1 = (date) => {
    if (date) {
      // Calculate age based on the selected date
      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMilliseconds = currentDate - birthDate;

      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

      const age = Math.floor(ageInYears);

      // Set the calculated age in your state
      setGAge(age);
    } else {
      setGAge(null);
    }
  };
  const handleCNICChange1 = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setGuardianCNIC(inputValue);
  };

  const handleCNICChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setBeneficerCNIC(inputValue);
  };

  const handleDateChange = (date) => {
    if (date) {
      // Calculate age based on the selected date
      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMilliseconds = currentDate - birthDate;

      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

      const age = Math.floor(ageInYears);

      setAge(age);
    } else {
      setAge(null);
    }
  };

  useEffect(() => {
    if (data) {
      setBeneficeryName(data.beneficeryName || "");
      setBeneficerCNIC(data.beneficerCNIC || "");
      setRelation(data.relation || "");
      setShare(data.share || "");
      setGuardianName(data.guardianName || "");
      setGuardianCNIC(data.guardianCNIC || "");
      setAge(data.age || null);
      setGAge(data.Gage || null);
    }
  }, [data]);

  const handleUpdateBeneficery = () => {
    const errors = {};

    if (!beneficeryName) {
      return toast.error("Beneficiary Name is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (!beneficerCNIC) {
      return toast.error("Beneficiary CNIC is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (age === null) {
      return toast.error("Beneficiary Age is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!relation) {
      return toast.error("Relation is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!share) {
      return toast.error("Share is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const shareValue = parseFloat(share);
      if (
        isNaN(shareValue) ||
        shareValue < 0 ||
        shareValue + shareCount > 100
      ) {
        return toast.error("Share value limit exceed", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    if (age !== null && age <= 18) {
      if (!guardianName) {
        return toast.error("Guardian Name is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (!guardianCNIC) {
        return toast.error("Guardian CNIC is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (Gage === null) {
        return toast.error("Guardian Age is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    // If there are errors, set them and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log(errors);
      return;
    }

    // If no errors, proceed with form submission
    const updatedData = {
      beneficeryName,
      beneficerCNIC,
      relation,
      share,
      guardianName,
      guardianCNIC,
      age,
      Gage,
    };
    handleCancel(false);
    onUpdate(updatedData);
  };
  return (
    <>
      <Form initialValues={data}>
        <div className="row editBeneficery">
          <div className="col-sm-12 formHeader">
            <h4>Enter beneficiary details</h4>
          </div>

          <div className="col-sm-6">
            <Form.Item
              label="Beneficiary Name"
              name={`beneficeryName`}
              valuePropName="benficeryName"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid Beneficiary Name",
                },
              ]}
            >
              <Input
                placeholder="Beneficiary Name"
                onChange={(e) => {
                  setBeneficeryName(e.target.value);
                }}
                value={beneficeryName}
              />
            </Form.Item>
            {errors.beneficeryName && (
              <div className="error">{errors.beneficeryName}</div>
            )}
          </div>

          <div className="col-sm-6">
            <Form.Item
              label="Beneficiary CNIC"
              name={`benficeryCNIC`}
              valuePropName="beneficerCNIC"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="12345-1234567-1"
                value={beneficerCNIC}
                onChange={handleCNICChange}
                maxLength={15} // 13 digits + 2 hyphens
              />
            </Form.Item>
          </div>

          <div className="col-sm-6">
            <Form.Item
              name={`dob`}
              valuePropName="dob"
              label="Beneficery Date Of Birth"
              rules={[
                {
                  required: true,
                  message: "Please enter Beneficery Date Of Birth",
                },
              ]}
            >
              <DatePicker
                value={dob}
                onChange={handleDateChange}
                placeholder="Please Enter A Date Of Birth"
              />
            </Form.Item>
          </div>

          <div className="col-sm-6">
            <Form.Item label="Age">
              <Input
                placeholder={
                  age !== null
                    ? `   Age: ${age} year${age !== 1 ? "s" : ""}`
                    : "Beneficery Age"
                }
                disabled
              />
            </Form.Item>
          </div>

          <div className="col-sm-6">
            <Form.Item
              label="Relation"
              name={`relation`}
              rules={[
                {
                  required: true,
                  message: "Please select Your Relation with beneficery",
                },
              ]}
            >
              <Select
                placeholder="Select relationship"
                onChange={(e) => {
                  setRelation(e);
                }}
              >
                {relationshipOptions.map((relationship) => (
                  <Option key={relationship} value={relationship}>
                    {relationship}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="col-sm-6">
            <Form.Item
              label="Share"
              name={`Share`}
              valuePropName="share"
              rules={[{ required: true, message: "Please select a Share" }]}
            >
              <Input
                placeholder="Share"
                value={share}
                onChange={(e) => {
                  setShare(e.target.value);
                }}
              />
            </Form.Item>
          </div>

          {age === null ? (
            <> </>
          ) : age > 18 ? (
            <></>
          ) : (
            <>
              <div className="col-sm-12 formHeader">
                <h4>Enter Guardian details</h4>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  label="Guardian Name"
                  name={`Guardian${index}`}
                  initialValue={guardianName}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Guardian Name",
                    },
                  ]}
                >
                  <Input
                    value={guardianName}
                    placeholder="Guradian Name"
                    onChange={(e) => {
                      setGuardianName(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  label="Guardian CNIC"
                  name={`Gcnic${index} `}
                  valuePropName="guardianCNIC"
                  initialValue={guardianCNIC}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Guardian CNIC number",
                    },
                  ]}
                >
                  <Input
                    placeholder="12345-1234567-1"
                    value={guardianCNIC}
                    onChange={handleCNICChange1}
                    maxLength={15} // 13 digits + 2 hyphens
                  />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item
                  name={`gdate-birth`}
                  label="Guardian Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Guardian Date Of Birth",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    onChange={handleDateChange1}
                    placeholder="Please Enter A Guardian Date Of Birth"
                  />
                </Form.Item>
              </div>

              <div className="col-sm-6">
                <Form.Item label="Guardian Age" name="Guardian Age">
                  <Input
                    placeholder={
                      Gage !== null
                        ? `   Age: ${Gage} year${Gage !== 1 ? "s" : ""}`
                        : "Guardian Age"
                    }
                    disabled
                  />
                </Form.Item>
              </div>
            </>
          )}

          <div className="col-sm-12 step6btn">
            <Button type="submit" onClick={handleUpdateBeneficery}>
              Update
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditStep10;
