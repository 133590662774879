import React from "react";
import { Fragment } from "react";

import Fade from "react-reveal/Fade";

function CodeofConduct() {
  return (
    <Fragment>
      <div id="coc" className="row whoweare codeofconduct">
        <div className="col-sm-12">
          <Fade right delay={400}>
            <h2> Code of Conduct</h2>
          </Fade>
        </div>

        <div className="container mypdfss">
          <div className="row">
            <Fade delay={500} left>
              <div className="col-sm-4 pdfs" key="1">
                <a
                  href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/antiBribary.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    // src={Anti}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/anti.jpg`}
                    alt="pdf"
                  />
                  {/* <h3> Anti-Bribery & Corruption</h3> */}
                </a>{" "}
              </div>
            </Fade>
            <Fade right delay={500}>
              <div className="col-sm-4 pdfs" key="2">
                <a
                  // href={EthnicPdf}
                  href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Ethical-Business-Practices.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    // src={Business}

                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/business.jpg`}
                    alt="pdf"
                  />
                  {/* <h3> Ethical Business Practices</h3> */}
                </a>
              </div>
            </Fade>

            <Fade left delay={400}>
              <div className="col-sm-4 pdfs" key="3">
                <a href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/whistleBlowing.pdf`} target="_blank" rel="noopener noreferrer" >
                  {" "}
                  <img src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/whistle.jpg`} alt="pdf" />
                </a>
              </div>
            </Fade>
          </div>
        </div>

        <div className="col-sm-12 shareholding myntn">
          <Fade left delay={500}>
            <h2>NTN Number and Registration Certificate</h2>
          </Fade>
        </div>

        <div className="container mypdfss">
          <div className="row">
            <Fade left delay={500}>
              <div className="col-sm-4 pdfs" key="3">
                <a href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/TaxPayer.pdf`} target="_blank" rel="noopener noreferrer" >
                  <img src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tax.png`} alt="pdf" />
                </a>
              </div>
            </Fade>

            <Fade left delay={700}>
              <div className="col-sm-4 pdfs" key="3">
                <a href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/SFTL.pdf`} target="_blank" rel="noopener noreferrer" >
                  <img src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/sftlcer.png`} alt="pdf" />
                </a>
              </div>
            </Fade>

            <Fade left delay={700}>
              <div className="col-sm-4 pdfs" key="3">
                <a href={`${process.env.REACT_APP_ASSETS_URL}Assets/img/certificatesecp.jpg`} target="_blank" rel="noopener noreferrer">
                  <img src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/secpcer.png`} alt="pdf" />
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CodeofConduct;
