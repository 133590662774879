import React, { useState, Fragment } from "react";
import { Form, Radio, Modal } from "antd";
import Flip from "react-reveal";
import First from "./FormModals/DiseaseModal/First";
import Second from "./FormModals/DiseaseModal/Second";
import Third from "./FormModals/DiseaseModal/Third";
import Fourth from "./FormModals/DiseaseModal/Fourth";
import Fifth from "./FormModals/DiseaseModal/Fifth";
import Sixth from "./FormModals/DiseaseModal/Sixth";

function Step3({ takafulContribution, setTakafulContribution }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState("no");

  const handleRadioChange = (option) => {
    setSelectedOption(option);
    setValue("yes");
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const getModalContent = () => {
    switch (selectedOption) {
      case "option2":
        return <First />;
      case "option1":
        return <Second />;
      case "option3":
        return "This is the content for Option 3.";
      case "option4":
        return <Third />;
      case "option5":
        return <Fourth />;
      case "option6":
        return <Fifth />;
      case "option7":
        return <Sixth />;

      default:
        return "";
    }
  };

  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4> Health Questions</h4>
        </div>
      </Flip>
      {/* <Flip top delay={700}>
        <div className="col-sm-6">
          <Form.Item
            label="Referral  code"
            name="Referral code"
            rules={[
              { required: true, message: "Please select a Referral code" },
            ]}
          >
            <Input placeholder="Referral code" />
          </Form.Item>
        </div>
      </Flip>

      <Flip top delay={900}>
        <div className="col-sm-6">
          <Form.Item
            label="Referral Name"
            name="Referralname"
            rules={[
              { required: true, message: "Please select a Referral Name" },
            ]}
          >
            <Input placeholder="Referral name" />
          </Form.Item>
        </div>
      </Flip> */}

      <div className="healthSection container">
        <Flip top delay={1500}>
          <div className="col-sm-12">
            <p>
              Have you ever been diagnosed with, consulted a medical
              practitioner or been given treatment for any of the following
              conditions: Morbid Obesity (BMI &#8594; 40), Heart attack, chest
              pain, high cholesterol, stroke, hypertension, diabetes, cancer,
              tumour or abnormal growth, hepatitis, HIV infection or AIDS; - Any
              disease or disorder of the heart, brain or nervous system, eyes or
              ears, thyroid, lung or respiratory system, liver, digestive system
              or gastrointestinal tract, kidney or urinary system, genital
              organs, spine or joints, immune system, blood disorder, hereditary
              disease or congenital abnormality, mental health or psychiatric
              illness, physical impairment or deformity, drug or alcohol abuse?
            </p>
            <Form.Item name="health-1" value={value} initialValue="no">
              <Radio.Group value={value}>
                <Radio
                  value="yes"
                  onChange={() => handleRadioChange("option1")}
                  onClick={() => handleRadioChange("option1")}
                >
                  Yes
                </Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Flip>
        <Flip top delay={1700}>
          <div className="col-sm-12">
            <p>
              Within the past three (3) years, have you consulted a specialist,
              been hospitalized, had surgery, had a diagnostic test with an
              abnormal result or been advised to have any of these in the future
              are you currently suffering or recovering from Covid-19 or any of
              its symptoms or are you currently on any long term (&#8594; 14
              days) medication?
            </p>
            <Form.Item name="option2" value={value} initialValue="no">
              <Radio.Group value={value}>
                <Radio
                  value="yes"
                  onChange={() => handleRadioChange("option2")}
                  onClick={() => handleRadioChange("option2")}
                >
                  Yes
                </Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Flip>
        <Flip top delay={1900}>
          <div className="col-sm-12">
            <p>
              Have you ever had an application or an insurance/takaful coverage
              declined, postponed, rated up or accepted on modified terms?
            </p>
            <Form.Item name="option3" value="option3" initialValue="no">
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Flip>
        {takafulContribution > 5000000 && (
          <>
            <Flip top delay={2100}>
              <div className="col-sm-12">
                <p>
                  Do you consume alcohol or tobacco? or addicted to any kind of
                  drugs/intoxicants? Please state what is the type and number of
                  units per day.
                </p>
                <Form.Item name="health-4" value={value} initialValue="no">
                  <Radio.Group value={value}>
                    <Radio
                      value="yes"
                      onChange={() => handleRadioChange("option4")}
                      onClick={() => handleRadioChange("option4")}
                    >
                      Yes
                    </Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Flip>
            <Flip top delay={2300}>
              <div className="col-sm-12">
                <p>
                  Have you ever suffered from any illness, injury, or operation
                  of any kind not mentioned above? OR Have you ever received or
                  do you now receive any disability benefits
                  (Insurance/Takaful)?
                </p>
                <Form.Item name="health-5" value={value} initialValue="no">
                  <Radio.Group value={value}>
                    <Radio
                      value="yes"
                      onChange={() => handleRadioChange("option5")}
                      onClick={() => handleRadioChange("option5")}
                    >
                      Yes
                    </Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Flip>
            <Flip top delay={2500}>
              <div className="col-sm-12">
                <p>
                  Have any of your natural parents, brothers, or sisters (living
                  or dead), suffered from Diabetes, Heart Disease, Stroke, High
                  Blood Pressure, Kidney Disease, Cancer, or any Hereditary
                  disorder?
                </p>
                <Form.Item name="health-6" value={value} initialValue="no">
                  <Radio.Group value={value}>
                    <Radio
                      value="yes"
                      onChange={() => handleRadioChange("option6")}
                      onClick={() => handleRadioChange("option6")}
                    >
                      Yes
                    </Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Flip>
            <Flip top delay={2700}>
              <div className="col-sm-12">
                <p>
                  Do you Participate in any dangerous sport, activity, or
                  pursuit?
                </p>
                <Form.Item name="option10" value="option7" initialValue="no">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Flip>
            <Flip top delay={2900}>
              <div className="col-sm-12">
                <p>
                  Have you ever suffered from any complications of pregnancy or
                  gynecological conditions or disease of the breast? (FOR FEMALE
                  LIFE ONLY)
                </p>
                <Form.Item name="health-8" value={value} initialValue="no">
                  <Radio.Group value={value}>
                    <Radio
                      value="yes"
                      onChange={() => handleRadioChange("option7")}
                      onClick={() => handleRadioChange("option7")}
                    >
                      Yes
                    </Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Flip>
          </>
        )}
      </div>
      <Modal
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={"60%"}
      >
        {getModalContent()}
      </Modal>
    </Fragment>
  );
}

export default Step3;
