import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Overview from "../../Assets/newoverview.jpeg";

function ProductText() {
  return (
    <Fragment>
      <div className="sub productetxt">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-8 writeus">
              <h2>An Overview of Our Group Life Takaful Plan</h2>

              <h3>REVOLUTIONIZING TAKAFUL </h3>
              <p>
                Your employees are your prized assets. For any company to secure
                high returns, it is paramount that it takes care of them –
                because they give their best day in and day out. Let their
                dreams be important for you because this will reap rich
                dividends for your organization. Give them the plan that takes
                care of all their family takaful needs, hopes and aspirations!
              </p>

              <h3>PAVING THE WAY FOR A NEW ERA </h3>
              <p>
                Salaam Family Takaful Limited is a revolutionary force in the
                world of takaful, leading the way as Pakistan's premier digital
                takaful company. Utilizing state-of-the-art technology, SFTL
                offers its customers a seamless and easy-to-use online
                experience. With a mission to bring takaful to the masses in
                Pakistan, SFTL offers an array of diverse takaful products
                tailored to meet the unique needs of each individual customer.
                Dedicated to providing unparalleled customer service and
                delivering products that offer exceptional value, Salaam Family
                Takaful Limited is truly the premier choice for all your takaful
                needs.
              </p>

              <h3>THE STORY OF SALAAM TAKAFUL LIMITED</h3>
              <p>
                Salaam Family Takaful Limited is backed by the strength and
                success of Salaam Takaful Limited, Pakistan's premier dedicated
                general takaful operator and first Islamic insurtech. Salaam
                Takaful Limited has redefined the industry with its
                revolutionary use of technology and digitization, achieving a
                staggering 82% YoY growth rate since 2018. Our digital
                ecosystem, Salaam App, is a masterpiece of cutting-edge
                technology, offering a plethora of features, and we are
                constantly pushing boundaries by participating in regulatory
                sandbox for the development of game-changing, innovative
                products. Our mission is to make takaful accessible to all, and
                we have devised many community-based products to reach those
                segments of society that normally do not enjoy the benefits of
                insurance/takaful. We are a company that truly understands the
                importance of corporate responsibility and have imbued
                Environmental, Social, and Governance (ESG) principles into the
                very DNA of our operations, resulting in a satisfied customer
                base of 200,000+ and being the premier choice for all your
                takaful needs. Salaam Family Takaful Limited embodies the same
                philosophy of success, innovation, and unwavering commitment to
                takaful and Shariah guidelines.
              </p>

              <br />

              <p className="browchure">
                <button>
                  <a
                    href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/GroupBrochure.pdf`}
                    target="_blank"
                    className="combtn"
                    rel="noreferrer"
                  >
                    Download Brochure
                  </a>
                </button>
                <button>
                  <a
                    href="http://glquotation.salaamlifetakaful.com/"
                    target="_blank"
                    className="combtn"
                    rel="noreferrer"
                  >
                    Request a Quote
                  </a>
                </button>
              </p>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div className="col-sm-4 writeus">
              <Link to="" target="_blank">
                <img src={Overview} alt="pdf" />
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default ProductText;
