import React, { Fragment, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Form, Input, Button } from "antd";
import Flip from "react-reveal/Flip";

const ForgetPassword = () => {
  const [formStep1] = Form.useForm();

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    setIsSubmitted(true);
    formStep1.validateFields().then((values) => {
      setFormData({ ...formData, ...values });
    });
    setIsLoading(false);
    setIsSubmitted(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Fragment>
        <Fade left>
          <div className="container tabss motor buynow">
            <div className="row">
              <Fade left>
                <div className="col-sm-6">
                  <img
                    // src={Buynowimage}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/buynow.png`}
                    alt="buynowimage"
                  />
                </div>
              </Fade>
              <div className="col-sm-6 homepagesec buynowpage">
                <div className=" Buynowform loginForm">
                  <Fade right>
                    <div className="">
                      <h4 className="oswald">Forget Password</h4>
                    </div>
                    <div className="innerbuyNow next">
                      <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        form={formStep1}
                      >
                        <div className="form">
                          <Flip top delay={500}>
                            <Form.Item name="Email">
                              <Input placeholder="Email" type="email" />
                            </Form.Item>
                          </Flip>
                        </div>

                        <div className="footerbottom">
                          <Flip top delay={1000}>
                            <div className="formSubmit loginButton">
                              <Button
                                htmlType="submit"
                                className="buttons"
                                loading={isLoading}
                              >
                                Submit
                              </Button>
                            </div>
                          </Flip>
                        </div>
                      </Form>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        {isSubmitted && (
          <div
            className="submit-message"
            style={{ position: "fixed", bottom: 20, left: 20 }}
          >
            <p>Form submitted successfully!</p>
          </div>
        )}
      </Fragment>
    </>
  );
};

export default ForgetPassword;
