import React, { Fragment } from "react";
import { Fade } from "react-reveal";

const PolicyContent = () => {
  return (
    <Fragment>
   
      <div className=" col-sm-12">
        <Fade left delay={800}>
          <h4>Introduction</h4>

          <p>
            Salaam Family Takaful Limited (the "Company") is committed to
            protecting the privacy of your personal information. This Privacy
            Policy explains how we collect, use, and share your personal
            information when you visit our website, use our products or
            services, or interact with us in any other way.
          </p>
        </Fade>
      </div>
    </Fragment>
  );
};

export default PolicyContent;
