import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import "swiper/css";
// import Savings from "";
import { Link } from "react-router-dom";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import productImage1 from "../../Assets/product2.jpg";

function Productmenus() {
  return (
    <Fragment>
      <div className="container productmenu homemenu">
        <div className="container savingssec">
          <div className="row">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              // pagination={{ Navigation: true }}
              navigation={{ clickable: true }}
              modules={[Navigation]}
              breakpoints={{
                "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                "@0.75": { slidesPerView: 1, spaceBetween: 30 },
                "@1.00": { slidesPerView: 1, spaceBetween: 30 },
                "@1.50": { slidesPerView: 1, spaceBetween: 30 },
              }}
              className="mySwiper prdocyMenu"
            >
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-6 explore">
                    <Fade down delay={800}>
                      <video width="100%" height="100%" controls>
                        <source
                          src={`${process.env.REACT_APP_ASSETS_URL}Assets/video/khalidmaliksalesteam.mp4`}
                          type="video/mp4"
                        />
                      </video>
                    </Fade>
                  </div>

                  <div className="col-sm-6 explore">
                    <h3 className="salamProdMenu">
                      {" "}
                      <Flip top delay={800}>
                        Life & Savings (Individual Life )
                      </Flip>
                    </h3>
                    <Flip top delay={900}>
                      <p>
                        Our cutting-edge Individual Family Takaful Plan is a comprehensive, customizable solution that offers exceptional coverage and peace of mind. It's an
                        investment in your financial future and your values, ensuring that you and your loved ones are protected no matter where life takes you.
                      </p>
                    </Flip>
                    <Flip top delay={900}>
                      <p>
                        Invest in your own growth and success, ensuring a
                        brighter future for you and your loved ones. Our plan is
                        designed to offer comprehensive coverage, including
                        death benefits, accidental death and disability, and
                        critical illness coverage, among others.
                      </p>
                    </Flip>
                    <div className="newbtn">
                      <Flip top delay={800}>
                        {" "}
                        <a
                          href="https://portals.salaamlifetakaful.com/"
                          className="blue"
                          target="_blank"
                        >
                          Buy Now
                        </a>
                      </Flip>
                      {/* <Flip top delay={800}>
                        {" "}
                        <a
                          href="http://glquotation.salaamlifetakaful.com/"
                          className="blue"
                        >
                          Request a Quote
                        </a>
                      </Flip> */}
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="/individuals"
                          className="gray"
                          onClick={() => {
                            document.documentElement.scrollTop = 0;
                            document.body.scrollTop = 0;
                          }}
                        >
                          Read More
                        </Link>
                      </Flip>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-6 explore">
                    <Fade down delay={800}>
                      <img src={productImage1} alt="" />
                    </Fade>
                  </div>

                  <div className="col-sm-6 explore">
                    <h3 className="salamProdMenu">
                      {" "}
                      <Flip top delay={800}>
                        Salaam Life & Savings (Group Life )
                      </Flip>
                    </h3>
                    <Flip top delay={900}>
                      <p>
                        The Group Family Takaful is a risk coverage plan that
                        provides protection to participant’s employees in the
                        event of Death, so that a pre-defined lump sum or a
                        multiple of that employee’s yearly salary can be paid to
                        his/her family or dependents to ease their financial
                        difficulties. This basic coverage can also be enhanced
                        by adding supplementary coverage of risks of death and
                        disabilities arising out of a Natural calamity or by
                        unpredictable Accidents.
                      </p>
                    </Flip>

                    <div className="newbtn">
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="http://glquotation.salaamlifetakaful.com/"
                          target="_blank"
                          className="blue"
                          // onClick={() => {
                          //   window.scrollTo({
                          //     top: 0,
                          //     left: 0,
                          //     behavior: "smooth",
                          //   });
                          // }}
                        >
                          Request a Quote
                        </Link>
                      </Flip>
                      {/* <Flip top delay={800}>
                        {" "}
                        <Link
                          to="/request"
                          className="gray "
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Request Call Back
                        </Link>
                      </Flip> */}
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="/group"
                          className="gray"
                          onClick={() => {
                            document.documentElement.scrollTop = 0;
                            document.body.scrollTop = 0;
                          }}
                        >
                          Read More
                        </Link>
                      </Flip>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              {/* 
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-6 explore">
                    <Fade down delay={800}>
                      <img
                        src="https://www.shutterstock.com/shutterstock/photos/579095917/display_1500/stock-photo-family-life-insurance-family-services-and-supporting-families-concepts-businessman-with-579095917.jpg"
                        alt=""
                      />
           
                    </Fade>
                  </div>

                  <div className="col-sm-6 explore">
                    <h3 className="salamProdMenu">
                      {" "}
                      <Flip top delay={800}>
                        Salaam Life & Savings (Group Savings)
                      </Flip>
                    </h3>
                    <Flip top delay={900}>
                      <p>
                        Our cutting-edge Individual Family Takaful Plan is a
                        comprehensive, customizable solution that offers
                        exceptional coverage and peace of mind. It's an
                        investment in your financial future and your values,
                        ensuring that you and your loved ones are protected no
                        matter where life takes you.
                      </p>
                    </Flip>
                    <Flip top delay={900}>
                      <p>
                        Invest in your own growth and success, ensuring a
                        brighter future for you and your loved ones. Our plan is
                        designed to offer comprehensive coverage, including
                        death benefits, accidental death and disability, and
                        critical illness coverage, among others.
                      </p>
                    </Flip>
                    <div className="newbtn">
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="https://portalsmock.salaamfamilytakaful.com/"
                          target="_blank"
                          className="gray"
                        >
                          Calculate
                        </Link>
                      </Flip>
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="/request"
                          className="blue "
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Request Call Back
                        </Link>
                      </Flip>
                      <Flip top delay={800}>
                        {" "}
                        <Link
                          to="/group-saving"
                          className="gray"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Read More
                        </Link>
                      </Flip>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Productmenus;
