import React, { Fragment } from "react";

function Shariahtext() {
  return (
    <Fragment>
      <div className="sub shariahcom">
        <p>
          Our shariah compliance function is a key element of company’s
          structure, carrying the responsibility of ensuring that all products
          and services offered by Salaam Family Takaful Limited are fully compliant
          with the principles of shariah law. The shariah compliance function
          plays a vital role in helping to develop new procedures and products
          to adapt the company to industry trends and customer expectations.
        </p>
        <p>
          The Shariah Supervisory is an independent body specializing in Fiqh al
          Muamalat. The Shariah Supervisor shall be: (Islamic commercial
          jurisprudence).Directing, Reviewing, Supervising all the activities of
          the Salaam Family Takaful Limited in order to ensure that they are in
          compliance with Islamic Shariah rules and principles
        </p>
      </div>
    </Fragment>
  );
}

export default Shariahtext;