import React from "react";
import Flip from "react-reveal/Flip";

const Livechat = () => {
  return (
    <div className="row container dashboardTabs">
      <div className="col-sm-4">
        <Flip top delay={400}>
          <div className="box">
            <img
              // src={Tab2}
              src={`../../assets/whatsapp.png`}
              alt="tab1"
            />{" "}
            <p>WhatsApp</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={600}>
          <div className="box">
            <img
              // src={Tab1}
              src={`../../assets/tollfree.png`}
              alt="tab1"
            />{" "}
            <p>Toll Free Call</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={800}>
          <div className="box">
            <img
              // src={Tab3}
              // src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab3.png`}
              src={`../../assets/live.png`}
              alt="tab1"
            />{" "}
            <p>Live Chat</p>
          </div>
        </Flip>
      </div>

      <div className="col-sm-4">
        <Flip top delay={1000}>
          <div className="box">
            <img
              // src={Tab4}
              // src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab3.png`}
              src={`../../assets/messenger.png`}
              alt="tab1"
            />{" "}
            <p>Messenger</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={1200}>
          <div className="box">
            <img
              // src={complainfeedacks}
              // src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/complainfeedacks.png`}
              src={`../../assets/emails.png`}
              alt="tab1"
            />{" "}
            <p>Emails</p>
          </div>
        </Flip>
      </div>
      <div className="col-sm-4">
        <Flip top delay={1200}>
          <div className="box">
            <img
              // src={Tab5}
              // src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab6.png`}
              src={`../../assets/others.png`}
              alt="tab1"
            />{" "}
            <p>Others</p>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default Livechat;
