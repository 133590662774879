import React, { Fragment, useState } from "react";

import { policyData } from "../../Data/Data";
import { RightCircleFilled, DownCircleFilled } from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import { Fade } from "react-reveal";

export default function PolicyAccordion() {
  const [activeKey, setActiveKey] = useState("0");

  const toggleAccordion = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <Fragment>
      <Accordion defaultActiveKey={activeKey}>
        {policyData.map((data, i) => {
          const isOpen = activeKey === data.key;
          return (
            <Fade top delay={1000}>
              <Accordion.Item eventKey={data.key} key={i}>
                <Accordion.Header onClick={() => toggleAccordion(data.key)}>
                  {isOpen ? (
                    <DownCircleFilled
                      style={{ color: "#0889e3" }}
                      onClick={() => toggleAccordion(data.key)}
                    />
                  ) : (
                    <RightCircleFilled
                      style={{ color: "#043627" }}
                      onClick={() => toggleAccordion(data.key)}
                    />
                  )}
                  {data.heading}
                </Accordion.Header>
                <Accordion.Body>
                  {data.text}

                  <ul>
                    {data?.text1?.map((elem) => (
                      <li>{elem}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Fade>
          );
        })}
      </Accordion>
    </Fragment>
  );
}
