import React from "react";
import PayPal from "./PayPal";

const PaypalParent = () => {
  return (
    <>
      {" "}
      <h4>PayPal Payment UI Widget</h4>
      <PayPal />
    </>
  );
};

export default PaypalParent;
