import React from "react";
import Profile from "./Profile";
import MainTabs from "./MainTabs";

const Dashboard = () => {
  return (
    <div className="container-fluid dashbord">
      <div className="row">
        <div className="col-sm-1"></div>

        <div className="col-sm-10">
          <Profile />
          <MainTabs />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
