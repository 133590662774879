import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { Table } from "antd";

function PastPriceTable({ fundPrices }) {
  const columns = [
    {
      title: "Fund Name",
      dataIndex: "FUND_NAME",
      key: "FUND_NAME",
    },
    {
      title: "Entry Date",
      dataIndex: "FPPNH_ENTRY_DATE",
      key: "FPPNH_ENTRY_DATE",
    },
    {
      title: "NAV Price",
      dataIndex: "NAVPRICE_COD",
      key: "NAVPRICE_COD",
    },
  ];

  const data = fundPrices.map((item, index) => ({
    key: index,
    ...item,
  }));

  return (
    <Fragment>
      <div className="sub complainforms whistleforms fundPrices">
        <div className="row">
          <div className="col-sm-12 formstyle rightform">
            <Fade down delay={500}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
              />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PastPriceTable;
