import React from "react";

import ClaimStatusHeader from "./ClaimStatusHeader";

const ClaimStatusForm = () => {
  return (
    <div className="container">
      <ClaimStatusHeader />
      {/* <div className="row withDrawl">
        <div className="col-sm-12 ">
          <div className="withdrawlLine">
            <div className="innerLine">
              <Input placeholder="CNIC" />
            </div>
          </div>
          <div className="withdrawlLine">
            <div className="innerLine">
              <Input placeholder="Intimation Name" />
            </div>
          </div>
          <div className="withdrawlLine">
            <div className="innerLine">
              <p>Intimation Relation to Policy Holder</p>
              <Checkbox>
                <h6> Nominee </h6>
              </Checkbox>
              <Checkbox>
                <h6> Other </h6>
              </Checkbox>
            </div>
          </div>
        </div>
        <div className="col-sm-12 btnSection">
          <button>Claim Status</button>
        </div>
      </div> */}
    </div>
  );
};

export default ClaimStatusForm;
