import React, { Fragment } from "react";
import Whistlebanner from "../Components/Whistle/Whistlebanner";

function WhistleBlowing() {
  return (
    <Fragment>
      <div className="container-fluid faderight">
        <div className="row">
          <Whistlebanner />
        </div>
      </div>
    </Fragment>
  );
}

export default WhistleBlowing;
