import React, { Fragment } from "react";
import { Form, Modal, Radio, Tooltip } from "antd";
import Flip from "react-reveal";
import { useState } from "react";
import Terms from "./FormModals/DiseaseModal/TermsModal/Terms";
import Political from "./FormModals/DiseaseModal/TermsModal/Political";
import MoneyLoundering from "./FormModals/DiseaseModal/TermsModal/MoneyLoundering";
import { QuestionCircleOutlined } from "@ant-design/icons";

function Step11() {
  const [selectedOption, setSelectedOption] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);

  const handleRadioChange = (option) => {
    setSelectedOption(option);
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const getModalContent = () => {
    switch (selectedOption) {
      case "option3":
        return <Terms />;
      case "option1":
        return <Political />;
      case "option2":
        return <MoneyLoundering />;
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Answer Regulatory Question</h4>
        </div>
      </Flip>

      <Flip top delay={900}>
        <div className="col-sm-6 radios">
          <Form.Item
            name="pol"
            label={
              <span>
                Politically Exposed Person&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="An individual who holds, or has held, a prominent public position or office"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please Answer",
              },
            ]}
          >
            <Radio.Group value="yes">
              <Radio value="yes" onChange={() => handleRadioChange("option1")}>
                Yes
              </Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1100}>
        <div className="col-sm-6 radios">
          <Form.Item
            name="money-loundering"
            label={
              <span>
                Anti Money Laundering &nbsp;
                <Tooltip
                  color="#0889e3"
                  title="Procedures and regulations designed to prevent and detect the illegal practice of disguising the origins of illicitly obtained money through financial transactions"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Radio.Group
              value="yes"
              rules={[
                {
                  required: true,
                  message: "Please Answer",
                },
              ]}
            >
              <Radio value="yes" onClick={() => handleRadioChange("option2")}>
                Yes
              </Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1300}>
        <div className="col-sm-6 radios">
          <Form.Item
            name="terms"
            label="Terms and conditions"
            rules={[
              {
                required: true,
                message: "Please Answer",
              },
            ]}
          >
            <Radio.Group value="yes">
              <Radio value="yes" onClick={() => handleRadioChange("option3")}>
                Yes
              </Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>
      <Modal
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={"60%"}
      >
        {getModalContent()}
      </Modal>
    </Fragment>
  );
}

export default Step11;
