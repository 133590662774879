import React from "react";
import Flip from "react-reveal/Flip";


import { Link } from "react-router-dom";

const MainTabs = () => {
  return (
    <div className="row dashboardTabs">
      <Flip top delay={400}>
        <div className="col-sm-3">
          <div className="box">
            <Link to="/dashboard/service">
              <img
                // src={Tab1}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab1.png`}
                alt="tab1"
              />{" "}
              <p>Service Corner</p>
            </Link>
          </div>
        </div>
      </Flip>
      <Flip top delay={600}>
        <div className="col-sm-3">
          <div className="box">
            <Link to="/dashboard/withdrawl-partial">
              <img
                // src={Tab2}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/Tab2.png`}
                alt="tab1"
              />{" "}
              <p>Withdrawl Request</p>
            </Link>
          </div>
        </div>
      </Flip>
      <Flip top delay={800}>
        <div className="col-sm-3">
          <div className="box">
            <Link to="/dashboard/payment">
              <img
                //  src={Tab3}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab3.png`}
                alt="tab1"
              />{" "}
              <p>Contribution Payment</p>
            </Link>
          </div>
        </div>
      </Flip>
      <div className="col-sm-3 tabLastSection">
        <div className="lastBox">
          <Flip top delay={1000}>
            <div className="lastBoxSection">
              <img
                //  src={time}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/time 1.png`}
                alt="tab1"
              />{" "}
              <Link to="/dashboard/livechat">
                {" "}
                <p>Doctor Live Chat</p>
              </Link>
            </div>
          </Flip>
          <Flip top delay={1200}>
            <div className="lastBoxSection">
              <img
                // src={time1}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/time 1 (3).png`}
                alt="tab2"
              />

              <Link to="/dashboard/livechat">
                {" "}
                <p>Doctor Live Chat</p>
              </Link>
            </div>
          </Flip>
          <Flip top delay={1400}>
            {" "}
            <div className="lastBoxSection">
              <img
                // src={time2}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/time 1 (4).png`}
                alt="tab3"
              />
              <Link to="/dashboard/livechat">
                <p>Doctor Live Chat</p>
              </Link>
            </div>
          </Flip>
        </div>
      </div>
      <Flip top delay={1600}>
        <div className="col-sm-3">
          <div className="box">
            <Link to="/dashboard/claim-tab">
              <img
                //  src={Tab4}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab7.png`}
                alt="tab1"
              />{" "}
              <p>Claim Intimation</p>
            </Link>
          </div>
        </div>
      </Flip>
      <Flip top delay={1800}>
        <div className="col-sm-3">
          <div className="box">
            <img
              // src={complainfeedacks}
              src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/complainfeedacks.png`}
              alt="tab1"
            />
            <p>Complaint & Feedback</p>
          </div>
        </div>
      </Flip>
      <Flip top delay={2000}>
        <div className="col-sm-3">
          <Link to={"/dashboard/service"}>
            <div className="box">
              <img
                // src={Tab5}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/tab6.png`}
                alt="tab1"
              />{" "}
              <p>Service Corner</p>
            </div>
          </Link>
        </div>
      </Flip>
      <div className="col-sm-3 "></div>
    </div>
  );
};

export default MainTabs;
