import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import ProductText from "./ProductText";
import UniqueProducts from "./UniqueProducts";
import Suplimentaryriders from "./Suplimentaryriders";
import Widrawl from "./Widrawl";
import Surrender from "./Surrender";
import Deduction from "./Deduction";

function ProductBanner() {
  return (
    <Fragment>
      <div className="col-sm-12 heads govbanner">
        <Fade up delay={500}>
          <h2 className="oswald">INDIVIDUAL LIFE TAKAFUL PLAN</h2>
        </Fade>
      </div>

      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads text mymaxheight po">
            <ProductText />
          </div>

          {/* <div className="col-sm-12 heads text mymaxheight widraw po">
            <Widrawl />
            <Surrender />
            <Deduction />
          </div> */}

          <div className="col-sm-12 heads text mymaxheight po">
            <UniqueProducts />
          </div>

          <div className="col-sm-12 heads text mymaxheight cta po">
            <Suplimentaryriders />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProductBanner;
