import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";
import Step12 from "./Step12";
import { useNavigate } from "react-router-dom";
import { buyNowImageData } from "../../Data/Data";

function Multi() {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  const [form] = Form.useForm();
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [takafulContribution, setTakafulContribution] = useState();

  const steps = [
    {
      title: "Enter Basic Information",
      content: (
        <div className="row">
          <Step1
            setTakafulContribution={setTakafulContribution}
            takafulContribution={takafulContribution}
          />
        </div>
      ),
    },

    // {
    //   title: "Contribution Mode",
    //   content: (
    //     <div className="row steptext">
    //       <Step2
    //         setTakafulContribution={setTakafulContribution}
    //         takafulContribution={takafulContribution}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: "Referal Code",
      content: (
        <div className="row steptext">
          <Step3
            setTakafulContribution={setTakafulContribution}
            takafulContribution={takafulContribution}
          />
        </div>
      ),
    },
    {
      title: "Need Analysis",
      content: (
        <div className="row steptext">
          <Step4 />
        </div>
      ),
    },

    {
      title: "T & C",
      content: (
        <div className="row ratingSection">
          <Step5 />
        </div>
      ),
    },
    {
      title: "Save",
      content: (
        <div className="row steptext illustrationPage">
          <Step6 />
        </div>
      ),
    },
    {
      title: "Save",
      content: (
        <div className="row steptext">
          <Step7 />
        </div>
      ),
    },
    {
      title: "Personal",
      content: (
        <div className="row steptext">
          <Step8 />
        </div>
      ),
    },
    {
      title: "picture",
      content: (
        <div className="row steptext">
          <Step9 />
        </div>
      ),
    },
    {
      title: "Beneficery",
      content: (
        <div className="row steptext">
          <Step10 />
        </div>
      ),
    },
    {
      title: "Term2",
      content: (
        <div className="row steptext">
          <Step11 />
        </div>
      ),
    },
    {
      title: "Payment",
      content: (
        <div className="row steptext">
          <Step12 />
        </div>
      ),
    },
    // Add more steps as needed
  ];

  const handleNext = () => {
    form
      .validateFields()
      .then(() => {
        if (currentStep === 0) {
          setShowSecondStep(true);
        }
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        console.error("Validation error:", error);
      });
    window.scrollTo(0, 0);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };


  const onFinish = (values) => {
    // Handle form submission here
    if (currentStep < steps.length - 1) {
      form.resetFields();
      setCurrentStep(currentStep + 1);
    } else {
      console.log("Form submitted:", values);
      navigate("/dashboard/policy");
    }
    // You can send the data to your server or perform other actions here
  };

  const backgroundImageUrl = buyNowImageData[currentStep].image;
  console.log(buyNowImageData[currentStep].image);

  const bannerStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-fluid stepsform">
        <div className="row">
          <div className="col-sm-4 steping" style={bannerStyle}>
            {/* <h3>Onboarding Journey</h3>
            <Steps current={currentStep}>
              {steps.map((step, index) => (
                <Step key={index} title={step.title} />
              ))}
            </Steps> */}
          </div>

          <div className="col-sm-8 stepforms step steps">
            <Form form={form} onFinish={onFinish} layout="vertical">
              {steps[currentStep] && steps[currentStep].content}

              <div className="mynutonsstep">
                {currentStep > 0 && (
                  <Button style={{ marginRight: 8 }} onClick={handlePrev}>
                    {" "}
                    Previous{" "}
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button type="primary" onClick={handleNext}>
                    {" "}
                    Next{" "}
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button type="primary" htmlType="submit">
                    {" "}
                    Finish
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Multi;
