import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { savingsprod } from "../../Data/Data";


function UniqueProducts() {
  return (
    <Fragment>
      <div className="sub productetxt uniqueprods">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-12 writeus">
              <h2>Distinctive Product Feature</h2>
            </div>
          </Fade>
        </div>

        <div className="row prodbox">
          {savingsprod.map((item) => {
            return (
              <div
                className="col-sm-4"
                key={item.key}
                data-aos={item.anim}
                data-aos-duration="4000"
              >
                <h3>{item.mainheading}</h3>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Fade up delay={500}>
        <div className="row claimprocedure">
          <div className="col-sm-12">
            <Fade left delay={700}>
              <h2 className="addistionslas"> Additional Features</h2>
            </Fade>

            <div className="row addsa featureRow">
              <div className="col-sm-3">
                <img
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-01.png`}
                  alt="Check"
                />{" "}
                <p>
                  End to end digital and seamless customer onboarding journey
                </p>
              </div>

              <div className="col-sm-3">
                <img
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-02.png`}
                  alt="Check"
                />{" "}
                <p>
                  Digital servicing for easy Contribution, withdrawal,
                  alterations and claims intimation facility through customer
                  portal/app
                </p>
              </div>

              <div className="col-sm-3">
                <img
                  //  src={Icon3}
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-03.png`}
                  alt="Check"
                />{" "}
                <p> Add-ons offering digital discounts</p>
              </div>

              <div className="col-sm-3">
                <img
                  //  src={Icon4}
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-04.png`}
                  alt="Check"
                />{" "}
                <p>
                  Online virtual clinic offering, free OPD consultations and
                  advice
                </p>
              </div>
            </div>

            {/* <ul>
              <Fade up delay={800}>
                <li>
                  <img
                    //  src={Icon1}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-01.png`}
                    alt="Check"
                  />{" "}
                  End to end digital and seamless customer onboarding journey
                </li>
              </Fade>
              <Fade up delay={900}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-02.png`}
                    alt="Check"
                  />{" "}
                  Digital servicing for easy contribution, withdrawal, alterations
                  and claims intimation facility through customer portal/app
                </li>
              </Fade>
              <Fade up delay={1000}>
                <li>
                  <img
                    //  src={Icon3}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-03.png`}
                    alt="Check"
                  />{" "}
                  Add-ons offering digital discounts
                </li>
              </Fade>
              <Fade up delay={1100}>
                <li>
                  <img
                    //  src={Icon4}
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/icons-04.png`}
                    alt="Check"
                  />{" "}
                  Online virtual clinic offering, free OPD consultations and
                  advice
                </li>
              </Fade>
            </ul> */}
          </div>
        </div>
      </Fade>
    </Fragment>
  );
}

export default UniqueProducts;
