import { React, Fragment } from "react";
import ForgetPasswordForm from "../Components/ForgetPassword/ForgetPassword";

function ForgetPassword() {
  return (
    <Fragment>
      <div className="container-fluid faderight">
        <div className="row">
          <ForgetPasswordForm />
        </div>
      </div>
    </Fragment>
  );
}

export default ForgetPassword;
