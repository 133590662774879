import { React, Fragment } from "react";
import Multi from "../Components/Buynow/Multi";

function BuyNow() {
  return (
    <Fragment>
      <div className="container-fluid faderight">
        <div className="row">
          {/* <Buynow/>  */}
          <Multi />
        </div>
      </div>
    </Fragment>
  );
}

export default BuyNow;
