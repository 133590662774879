import React, { useState, Fragment } from "react";
import { Form, Modal, Radio, Tooltip } from "antd";
import Flip from "react-reveal/Flip";
import CreditCard from "./FormModals/Payement/CreditCard";
import DebitCard from "./FormModals/Payement/DebitCard";

import PaypalParent from "./FormModals/Payement/PaypalParent";
import { QuestionCircleOutlined } from "@ant-design/icons";
import BankTransfer from "./FormModals/Payement/BankTransfer";

function Step12() {
  const paymentOptions = [
    "Credit Card",
    "Debit Card",
    "PayPal",
    "Bank Transfer",
    "Third Party",
    "Other",
  ];

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handlePaymentChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
    setSelectedOption(e.target.value); // Update selected option
    setModalVisible(true);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOk = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedOption(null);
  };

  const getModalContent = () => {
    switch (selectedOption) {
      case "Credit Card":
        return <CreditCard />;
      case "Debit Card":
        return <DebitCard />;
      case "PayPal":
        // return <PayPalModalComponent />;
        // Implement the PayPal modal component
        return <PaypalParent />;
      case "Bank Transfer":
        // return <BankTransferModalComponent />;
        // Implement the Bank Transfer modal component
        return <BankTransfer />;
      case "Other":
        // return <OtherPaymentModalComponent />;
        // Implement the Other Payment modal component
        return null;
      default:
        return null; // No modal to display
    }
  };

  return (
    <Fragment>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Select Payment Method</h4>
        </div>
      </Flip>

      <Flip top delay={700}>
        <div className="col-sm-12">
          <Form.Item
            name="payment-method"
            label={
              <span>
                Payment Method&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="Your preferred way of making a payment, such as credit card, bank transfer, or online wallet"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              { required: true, message: "Please select a payment method" },
            ]}
          >
            <Radio.Group
              onChange={handlePaymentChange}
              value={selectedPaymentMethod}
            >
              {paymentOptions.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </Flip>

      <Modal
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={"60%"}
      >
        {getModalContent()}
      </Modal>
    </Fragment>
  );
}

export default Step12;
