import React from "react";
import Flip from "react-reveal/Flip";
import ContriTable from "./ContriTable";

const Contribution = () => {
  return (
    <div className="container">
      <div className="row withDrawl">
        <Flip top delay={400}>
          <div className="col-sm-12">
            <ContriTable />
          </div>
          <div className="col-sm-12 btnSection">
            <Flip top delay={800}>
              <button>Proceed</button>
            </Flip>
          </div>
        </Flip>
      </div>
    </div>
  );
};

export default Contribution;
