import React, { Fragment } from "react";

import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <Fragment>
      <div className="row profile">
        <div className="col-sm-6 ">
          <h2>Mr. Jameel Ahmed Siddiqui</h2>
          <h6>
            Total Contribution Paid
            <span>
              100,000.00{" "}
              <img
                // src={View}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/View.png`}
                alt="ICON"
              />
            </span>
          </h6>
          <h6>
            Current Cash Value
            <span>
              100,000.00
              <Link to={"/dashboard/cash-value"}>
                <img
                  // src={View}
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/View.png`}
                  alt="ICON"
                />
              </Link>
            </span>
          </h6>
        </div>
        <div className="col-sm-6 " style={{ textAlign: "end" }}>
          <h2>
            Policy # UL23434325235
            <span>
              <Link to={"/dashboard/policy"}>
                <img
                  // src={View}
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/View.png`}
                  alt="ICON"
                />
              </Link>
            </span>
          </h2>
          <p>VALID FROM 01/01/2023</p>
          <p>VALID THRU 31/012/2023</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
