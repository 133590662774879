import React, { useState, Fragment } from "react";
import { Form, Input, DatePicker, Select, Button, Tooltip } from "antd";
import Flip from "react-reveal";
import { toast } from "react-toastify";
import { QuestionCircleOutlined } from "@ant-design/icons";

const SubStep10 = ({
  index,
  onSubmit,
  shareCount,

  setDisableBtn,

  setDisableAdd,
}) => {
  const [beneficeryName, setBeneficeryName] = useState("");

  const [relation, setRelation] = useState("");
  const [share, setShare] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianCNIC, setGuardianCNIC] = useState("");
  const [beneficerCNIC, setBeneficerCNIC] = useState();
  const [dob, setDob] = useState();

  const [errors, setErrors] = useState({});

  const [age, setAge] = useState(null);
  const [Gage, setGAge] = useState(null);

  const relationshipOptions = [
    "Parent",
    "Sibling",
    "Spouse",
    "Child",
    "Friend",
  ];
  const { Option } = Select;

  const disabledDate = (current) => {
    return current && current > new Date("2005-01-01");
  };

  const handleValidateBenneficery = () => {
    // Perform custom validation
    const errors = {};

    if (!beneficeryName) {
      return toast.error("Beneficiary Name is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (!beneficerCNIC) {
      return toast.error("Beneficiary CNIC is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (age === null) {
      return toast.error("Beneficiary Age is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!relation) {
      return toast.error("Relation is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!share) {
      return toast.error("Share is required", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const shareValue = parseFloat(share);
      if (
        isNaN(shareValue) ||
        shareValue < 0 ||
        shareValue + shareCount > 100
      ) {
        return toast.error("Share value limit exceed", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    if (age !== null && age <= 18) {
      if (!guardianName) {
        return toast.error("Guardian Name is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (!guardianCNIC) {
        return toast.error("Guardian CNIC is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (Gage === null) {
        return toast.error("Guardian Age is required", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    // If there are errors, set them and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);

      return;
    }
    const values = {
      beneficeryName,
      beneficerCNIC,
      relation,
      share,
      guardianName,
      guardianCNIC,
      age,
      index,
      shareCount,
      dob,
      Gage,
    };
    console.log(values, "dob");
    setDisableAdd(false);
    // Call the onSubmit function from the parent component
    onSubmit(values);
    window.scrollTo(0, 0);
  };

  const handleDateChange1 = (date) => {
    if (date) {
      // Calculate age based on the selected date
      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMilliseconds = currentDate - birthDate;

      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

      const age = Math.floor(ageInYears);

      // Set the calculated age in your state
      setGAge(age);
    } else {
      setGAge(null);
    }
  };

  const handleCNICChange = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setBeneficerCNIC(inputValue);
  };

  const handleCNICChange1 = (e) => {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, "");

    // Insert a hyphen after the first 5 characters
    if (inputValue.length > 5) {
      inputValue = inputValue.slice(0, 5) + "-" + inputValue.slice(5);
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 13) + "-" + inputValue.slice(13);
    }

    // Set the formatted CNIC in the state
    setGuardianCNIC(inputValue);
  };

  const handleDateChange = (date, dateString) => {
    setDob(dateString);
    if (date) {
      // Calculate age based on the selected date

      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMilliseconds = currentDate - birthDate;

      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

      const age = Math.floor(ageInYears);

      setAge(age);
    } else {
      setAge(null);
    }
  };
  const validateShare = (rule, value, callback) => {
    const shareValue = parseFloat(value);

    if (isNaN(shareValue) || shareValue < 0 || shareValue + shareCount > 100) {
      setDisableBtn();
      setDisableBtn(true);
      callback("You can only share up to 100%.");
    } else if (shareValue + shareCount === 100) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
      callback();
    }
  };
  const tomorrow = new Date();

  return (
    <>
      <Flip top delay={500}>
        <div className="col-sm-12 formHeader">
          <h4>Enter beneficiary details</h4>
        </div>
      </Flip>{" "}
      <Flip top delay={700}>
        <div className="col-sm-6">
          <Form.Item
            label={
              <span>
                Beneficiary Name&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="The full name of the person or entity who will receive the benefits or proceeds in the event of the claim"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name={`benficery${index} `}
            rules={[
              {
                required: true,
                message: "Please enter a valid Beneficiary Name",
              },
            ]}
          >
            <Input
              placeholder="Beneficiary Name"
              value={beneficeryName}
              onChange={(e) => {
                setBeneficeryName(e.target.value);
              }}
            />
          </Form.Item>
          {errors.beneficeryName && (
            <div className="error">{errors.beneficeryName}</div>
          )}
        </div>
      </Flip>
      <Flip top delay={900}>
        <div className="col-sm-6">
          <Form.Item
            label="Beneficiary CNIC"
            name={`benficeryCNIC${index} `}
            valuePropName="beneficerCNIC"
            initialValue={beneficerCNIC}
            rules={[{ required: true }]}
          >
            <Input
              placeholder="12345-1234567-1"
              value={beneficerCNIC}
              onChange={handleCNICChange}
              maxLength={15} // 13 digits + 2 hyphens
            />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1300}>
        <div className="col-sm-6">
          <Form.Item
            name={`benficerydob${index} `}
            label="Beneficery Date Of Birth"
            rules={[
              {
                required: true,
                message: "Please enter Beneficery Date Of Birth",
              },
            ]}
          >
            <DatePicker
              onChange={handleDateChange}
              format="MM-DD-YYYY"
              disabledDate={(current) => current && current > tomorrow}
              placeholder="Please Enter A Date Of Birth"
            />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1400}>
        <div className="col-sm-6">
          <Form.Item label="Age">
            <Input
              placeholder={
                age !== null
                  ? `   Age: ${age} year${age !== 1 ? "s" : ""}`
                  : "Beneficery Age"
              }
              disabled
            />
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1500}>
        <div className="col-sm-6">
          <Form.Item
            label="Relation"
            name={`relation${index} `}
            rules={[
              {
                required: true,
                message: "Please select Your Relation with beneficery",
              },
            ]}
          >
            <Select
              placeholder="Select relationship"
              onChange={(e) => {
                setRelation(e);
              }}
            >
              {relationshipOptions.map((relationship) => (
                <Option key={relationship} value={relationship}>
                  {relationship}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Flip>
      <Flip top delay={1700}>
        <div className="col-sm-6">
          <Form.Item
            label={
              <span>
                Share&nbsp;
                <Tooltip
                  color="#0889e3"
                  title="The portion or percentage of a claim amount that you desire you give to your beneficiary"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            name={`Share${index} `}
            rules={[
              { required: true, message: "Please select a Share" },
              { validator: validateShare },
              {
                max: 3,
                message: "Shares should be in under 100% characters",
              },
            ]}
          >
            <Input
              placeholder="Share"
              onChange={(e) => {
                setShare(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </Flip>
      {age === null ? (
        <> </>
      ) : age > 18 ? (
        <></>
      ) : (
        <>
          <Flip top delay={500}>
            <div className="col-sm-12 formHeader">
              <h4>Enter Guardian details</h4>
            </div>
          </Flip>
          <Flip top delay={2100}>
            <div className="col-sm-6">
              <Form.Item
                label={
                  <span>
                    Guardian Name&nbsp;
                    <Tooltip
                      color="#0889e3"
                      title="The full name of the legal guardian or custodian responsible for an individual, typically a minor or incapacitated person"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name={`Guardian${index} `}
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Guardian Name",
                  },
                ]}
              >
                <Input
                  placeholder="Guradian Name"
                  onChange={(e) => {
                    setGuardianName(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2300}>
            <div className="col-sm-6">
              <Form.Item
                label="Guardian CNIC"
                name={`Gcnic${index} `}
                valuePropName="guardianCNIC"
                initialValue={guardianCNIC}
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Guardian CNIC number",
                  },
                ]}
              >
                <Input
                  placeholder="12345-1234567-1"
                  value={guardianCNIC}
                  onChange={handleCNICChange1}
                  maxLength={15} // 13 digits + 2 hyphens
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2500}>
            <div className="col-sm-6">
              <Form.Item
                name={`gdate-birth"${index} `}
                label="Guardian Date Of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please enter Guardian Date Of Birth",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={handleDateChange1}
                  format="MM-DD-YYYY"
                  placeholder="Please Enter A Guardian Date Of Birth"
                />
              </Form.Item>
            </div>
          </Flip>
          <Flip top delay={2700}>
            <div className="col-sm-6">
              <Form.Item label="Guardian Age" name="Guardian Age">
                <Input
                  placeholder={
                    Gage !== null
                      ? `   Age: ${Gage} year${Gage !== 1 ? "s" : ""}`
                      : "Guardian Age"
                  }
                  disabled
                />
              </Form.Item>
            </div>
          </Flip>
        </>
      )}
      <Flip top delay={400}>
        <div className="col-sm-6 step6btn" style={{ margin: "20px 0px" }}>
          <Button onClick={handleValidateBenneficery}>Save</Button>
        </div>
      </Flip>
    </>
  );
};

export default SubStep10;
