import React, { useState } from "react";
import { Fragment } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Col,
  Checkbox,
  Row,
} from "antd";
import Fade from "react-reveal/Fade";

function WhistleForm() {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values) => {
    setSubmitting(true);
    setTimeout(() => {
      console.log(values); // You can handle the form submission here

      // Display success message
      message.success("Form submitted successfully.");

      // Clear form values
      form.resetFields();

      // Show the success message at the bottom
      setIsSuccessMessageVisible(true);

      setSubmitting(false);
    }, 1000); // Simulating an asynchronous operation with setTimeout
  };

  return (
    <Fragment>
      <div className="sub complainforms whistleforms">
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className="row whistleFormRow">
            <div className="col-sm-6 leftform formstyle">
              <Fade down delay={500}>
                {" "}
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Fade>

              <Fade down delay={700}>
                <Form.Item
                  name="email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Fade>

              <Fade down delay={900}>
                <Form.Item name="phone" rules={[{ type: "number", min: 0 }]}>
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Fade>

              <Fade down delay={1100}>
                <Form.Item name="issue">
                  <Input.TextArea
                    placeholder="Please Let Us Know Your Issue"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Fade>
            </div>

            <div className="col-sm-6 formstyle rightform">
              <Fade down delay={500}>
                <h4>What do you want to report? * (select all that apply)</h4>

                <Form.Item name="checkbox-group">
                  <Checkbox.Group>
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          value="Equipments"
                          style={{ lineHeight: "32px" }}
                        >
                          Equipments
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Unethical Activity"
                          style={{ lineHeight: "32px" }}
                        >
                          Unethical Activity
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Forgery"
                          style={{ lineHeight: "32px" }}
                        >
                          Forgery
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Records"
                          style={{ lineHeight: "32px" }}
                        >
                          Records
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Misconduct"
                          style={{ lineHeight: "32px" }}
                        >
                          Misconduct
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Concealment"
                          style={{ lineHeight: "32px" }}
                        >
                          Concealment
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Unauthorized Gifts"
                          style={{ lineHeight: "32px" }}
                        >
                          Unauthorized Gifts
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Harassment"
                          style={{ lineHeight: "32px" }}
                        >
                          Harassment
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Unauthorised Act"
                          style={{ lineHeight: "32px" }}
                        >
                          Unauthorised Act
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Bribery"
                          style={{ lineHeight: "32px" }}
                        >
                          Bribery
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox value="Others" style={{ lineHeight: "32px" }}>
                          Others
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Misuse of Takaful Property"
                          style={{ lineHeight: "32px" }}
                        >
                          Misuse of Takaful Property
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Fake/Unauthorized Expenses"
                          style={{ lineHeight: "32px" }}
                        >
                          Fake/Unauthorized Expenses
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Fade>
            </div>
          </div>

          <div className="col-sm-12">
            <Fade down delay={1300}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
              </Form.Item>
            </Fade>
          </div>
        </Form>

        {/* Display success message at the bottom */}
        {isSuccessMessageVisible && (
          <Fade down delay={300}>
            {" "}
            <div style={{ marginTop: "10px" }}>
              <p className="message">Form submitted successfully.</p>
            </div>
          </Fade>
        )}
      </div>
    </Fragment>
  );
}

export default WhistleForm;
