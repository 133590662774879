import React from "react";
import { Fragment } from "react";
import { Fade } from "react-reveal";
function Capitals() {
  return (
    <Fragment>
      <div className="container-fluid shares marbot CAPPITAAAL">
        <div className="row">
          <Fade left delay={300}>
            <div className="col-sm-5 imageshare">
              <img
                // src={Share}
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/finan.png`}
                alt="shareholding"
              />
            </div>
          </Fade>
          <Fade right delay={600}>
            <div className="col-sm-7 audp lleeegalAdvisdor" id="auditor">
              <p>Name of Auditor</p>
              <h2>KPMG Taseer Hadi & Co.</h2>
              <ul></ul>
              <p id="legal">Legal Advisor</p>
              <h2>AHM & Co</h2>
              <ul>
                {/* <li><b>Mr. Salim Habib Godil</b></li>
                <li><b>Syed Rizwan Hussain</b></li>
                <li><b>Mr. Shahzad Salim Godil</b></li>
                <li><b>AHM & Co </b></li> */}
              </ul>

              <div className="patern">
                <p>Pattern of Shareholding</p>
                <ul>
                  <li>
                    <b>Salaam Takaful Limited</b> <span>99.99%</span>
                  </li>
                  <li>
                    <b>Others Shareholders</b> <span>0.01%</span>
                  </li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default Capitals;
