import React from "react";
import { Fragment } from "react";
import { Fade } from "react-reveal";

function CeoMessages() {
  return (
    <Fragment>
      <div className="container-fluid shares marbot">
        <div className="row">
          <div className="col-sm-5 imageshare">
            <Fade left delay={500}>
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/ceo.png`}
                alt="shareholding"
              />
            </Fade>
          </div>
          <Fade right delay={500}>
            <div className="col-sm-7 audp aboutContent">
              <h2>CEO Message</h2>
              <p>
                I welcome you all to Salaam Life and Savings. Experience the
                first ever digital Islamic insurance provider across the globe.
                Here we are committed to reshaping the landscape of takaful with
                cutting-edge technology and digital innovations. We are proudly
                dedicated to delivering an effortlessly accessible online
                experience to our valued customers. With the brand of Salaam
                Life and Savings, a new day has dawned on the insurance and
                takaful industry of Pakistan. A totally unique offering where
                your hard-earned money is safe, providing you with a brilliant
                avenue for alternate revenue and savings, coupled with exquisite
                life takaful benefits. We understand the importance of
                transparency and that is why every information that you can
                think of is readily available through our portals and app. 100
                percent of your funds will always be ready for withdrawal at
                your discretion from day one, an offering which is not available
                anywhere. We have always worked towards going beyond the
                ordinary and Salaam Life and Savings is also one such
                gamechanger. Together, we will continue to drive innovation,
                advance sustainability, push for social transformation, and
                bolster community support. As we embark on our journey of
                expansion and value delivery, we remain committed to serving you
                and meeting your unique needs.
                <br/>
                <br/>
                <b>RIZWAN HUSSAIN </b>
                <br/><b > Managing Director & CEO </b>
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default CeoMessages;
