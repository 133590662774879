import React, { useState } from "react";
import { Button, Checkbox, Form, Image, Input, Tag, Upload } from "antd";

import { DeleteFilled } from "@ant-design/icons";
import { checkboxOptions4 } from "../../../../Data/Data.js";
import { UploadOutlined } from "@ant-design/icons";
function Fourth() {
  const [selectedValues, setSelectedValues] = useState([]);
  const [yourPictures, setYourPictures] = useState([]);

  const handleYourPictureChange = (file, index) => {
    const updatedPictures = [...yourPictures];
    updatedPictures[index] = file;
    setYourPictures(updatedPictures);
  };
  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleDelete = (value) => {
    setSelectedValues(selectedValues.filter((item) => item !== value));
  };

  return (
    <div className="modalForm container">
      <div className="col-sm-12">
        <h4>Please select your disease(s)</h4>
      </div>

      <div className="row">
        {checkboxOptions4.map((option) => (
          <div className="col-sm-4 leftform formstyle modalSelect">
            {" "}
            <Form.Item name="name">
              <Checkbox
                key={option}
                checked={selectedValues.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              >
                <p> {option}</p>
              </Checkbox>
            </Form.Item>
          </div>
        ))}

        {selectedValues.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Conditions</th>
                <th> Details (If Any)</th>
                <th>Upload Document</th>

                <th>Delete </th>
              </tr>
            </thead>
            <tbody>
              {selectedValues.map((option, index) => (
                <tr key={option}>
                  <td>
                    <Tag color="blue">{option}</Tag>
                  </td>
                  <td>
                    <Input placeholder="Details" />
                  </td>

                  <td>
                    <Form.Item name={`mypic-${index}`}>
                      <Upload
                        name={`imageUpload${index}`}
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={(file) => {
                          handleYourPictureChange(file, index);
                          return false;
                        }}
                      >
                        <Button icon={<UploadOutlined />}>
                          Upload Document
                        </Button>
                      </Upload>
                      {yourPictures[index] && (
                        <Image
                          style={{ width: "50px" }}
                          src={URL.createObjectURL(yourPictures[index])}
                          alt="Your Picture"
                        />
                      )}
                    </Form.Item>
                  </td>
                  <td>
                    <DeleteFilled
                      onClick={() => handleDelete(option)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Fourth;
