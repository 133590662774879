import React, { Fragment } from "react";
// import Companybanner from "../Components/Companyprofile/Companybanner";
import Shariahbanner from "../Components/Shariah/Shariahbanner";
import { Helmet } from "react-helmet";

function ShariahAdvisor() {
  return (
    <Fragment>
      <Helmet>
        <title>
          Shariah | Salaam Life and Savings | Islamic Life Insurance
        </title>
        <meta
          name="description"
          content="Our shariah compliance function is a key element of company’s structure, carrying the responsibility of ensuring that all products and services offered by Salaam Family Takaful Limited are fully compliant with the principles of shariah law. "
        />
        <meta
          name="keywords"
          content="shariah advisor,islamic Life Insurance,Shariah Board"
        />
      </Helmet>
      <div className="container-fluid faderight">
        <div className="row">
          <Shariahbanner />
        </div>
      </div>
    </Fragment>
  );
}

export default ShariahAdvisor;
