import React from "react";
import AgentProfile from "./AgentProfile";

const AgentDetail = () => {
  return (
    <div className="container">
      <AgentProfile />
    </div>
  );
};

export default AgentDetail;
